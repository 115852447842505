.mainContainer {
    font-family: "Inter";
    background-color: #f2f2f2;
    min-height: 100vh;
    padding: 20px;

    .mainHeading {
        font-weight: 600;
        margin-bottom: 20px;
    }

    p {
        margin: 0;
        line-height: unset;
    }

    .orderWrapper {
        --bs-gutter-x: 0;
        background-color: #FFFFFF;
        padding: 20px;
        box-shadow: 0 1px 15px 0px #0000001A;
        margin-bottom: 1.25rem;
    }

    .filterTableWrapper {
        --bs-gutter-x: 0;
        background-color: #FFFFFF;
        box-shadow: 0px 0px 17.9px 0px #00000017;
        margin-bottom: 1.25rem;

        .squareFilterBarWrapper {
            margin-bottom: 0.625rem;
        }
    }
}

.text {
    color: #008CD6 !important
}

// from here 
.msgInfo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.msgInfoText {
    background-color: #FFFC4899 !important;
    border-radius: 8px;
    padding: 10px;
}

.msgInfoText2 {
    background-color: #C9FFC1AB !important;
    border-radius: 8px;
    padding: 5px;
    // margin-right: 10%;
    width: 10rem;
    margin-bottom: 10px;
    margin-top: 10px;
}

.msgInfoText3 {
    background-color: #C9FFC1AB !important;
    border-radius: 8px;
    padding: 10px;
    margin-right: 3.5rem;
    width: 7rem;
}

.cont {
    display: flex;
    flex-direction: row;
    // gap: 15px;
    margin-left: 10px;
    margin-top: 5px;
}
.container {
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin-left: 10px;
    margin-top: 5px;
}
.cont2 {
    width: 10rem;
}
.cont3 {
    width: 13rem;
}

.text1 {
    width: 10rem;
}

.text2 {
    width: 14rem;
}

.text3 {
    width: 7rem;
    color:#5D5D5D;
}

.redText {
    color: #FF0000 !important;
}

.blueText {
    color: #41B8E4 !important;
    }

.greenText {
    color: #0DBC00 !important;
}

.yellowText {
    color: #FBBD34;
    width: 10rem;
    margin-top: 3rem;
}

.msg {
    border: 1px solid #7C7C7C;
    border-radius: 8px;
    padding: 10px;
}

.bgCont {
    background-color: #FBBD345E;
    padding: 10px;
    border-radius: 8px;
    margin-top: 10px;
}
.border{
    border: 1px solid #696969;
    border-radius: 8px;
    padding: 15px;
    margin-top: 1rem;
}
.yellowBorder{
    border: 1px solid #FBBD34;
    border-radius: 8px;
    padding: 10px;
    margin-top: 10px;
}
.blueBg{
    background-color: #41B8E4AD;
    padding: 10px;
    border-radius: 8px;
    margin-top: 10px;
}
.grayBg{
    background-color: #D9D9D9;
    border-radius: 20px;
    color:#5D5D5D;
    margin-top: 10px;
    padding: 5px;
}
.btnContainer{
    display: flex;
    flex-direction: column;
    width: 1rem;
    margin-left: 28%;
}
.btn{
   margin-top: 1rem;

}
.input{
    width: 150%;
    margin-bottom: 10px;
}
.input2{
    width: 52%;
}