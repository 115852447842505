.statsCard {
    background-color: #F5F5F5;
    border-radius: 20px;
    height: 100%;

    .cardBody {
        display: flex;
        flex-direction: column;
        padding: 20px;
        gap: 15px;

        .cardHeader {
            display: flex;
            align-items: center;
            gap: 10px;

            img {
                max-width: 100%;
                height: auto;
            }

            .cardTitle {
                font-weight: 600;
                margin: 0;
            }
        }

        .cardContent {
            font-weight: 500;

            .contentValue {
                color: #EC7426;
                font-size: 20px;
                font-weight: 600;
            }
        }

        .circularProgressBarRow {
            --bs-gutter-x: 0;
            display: flex;
            align-items: center;
            text-align: center;
            max-width: 400px;

            .percentText {
                font-weight: 500;
                margin: 0;
            }
        }
    }
}
