.btn {
    background: #41B8E4 !important;
    border: 1px solid #41B8E4 !important;
    color: #FFFFFF !important;
    font-size: 14px;
    font-weight: 500;
    padding: .2rem .7rem;
    border-radius: 8px;

    &:hover {
        box-shadow: 0 8px 25px -8px #41B8E4;
    }

    &:focus {
        background: #36a1cb !important;
    }
}