.outer-wrapper {
  background-color: #FFFFFF;
  margin-top: 1rem;
  }
  
  .button {
    background-color: transparent;
    border: none
  }
  
  .star {
    color: #FBBD34;
  }
  
  .product-image-container {
    background-color: #F2F2F2;
    border-radius: .5rem;
    padding: 0.188rem
  }