.customPageContainer {
    padding: 0 20px 20px;

    .saveButton {
        background-color: #41B8E4 !important;
        border: none;
        color: #FFFFFF !important;

        &:hover {
            box-shadow: 0 8px 25px -8px #41B8E4 !important;
        }
    }
}
