$yellow-bg: #FBBD34;
$blue-bg: #41B8E4;
$default-bg: #ccc;
$toggle-size: 28px;
$toggle-width: 45px;
$toggle-height: 24px;

.toggleSwitch {
  position: relative;
  display: inline-block;
  width: $toggle-width;
  height: $toggle-height;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    inset: 0;
    background-color: $default-bg;
    transition: 0.3s;
    border-radius: 34px;

    &:before {
      content: "";
      position: absolute;
      width: $toggle-size;
      height: $toggle-size;
      left: -6%;
      top: -6%;
      background-image: url("../../../../assets/images/AdminPortal/Toggle/blue-toggle.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 50%;
      transition: 0.3s;
    }
  }

  input:checked+.slider {
    background-color: $blue-bg;

    &:before {
      transform: translateX(80%);
      left: -10%;
    }
  }

  &.yellow {
    input:checked+.slider {
      background-color: $yellow-bg;

      &:before {
        left: -12%;
      }
    }

    .slider:before {
      width: 30px;
      height: 30px;
      top: -8%;
      background-image: url("../../../../assets/images/AdminPortal/Toggle/yellow-toggle.png");
    }
  }
}
