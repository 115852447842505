.mainContainer {
    font-family: "Inter";
    background-color: #f2f2f2;
    min-height: 100vh;
    padding: 20px;

    .mainHeading {
        font-weight: 600;
        margin-bottom: 20px;
    }

    p {
        margin: 0;
        line-height: unset;
    }

    .orderWrapper {
        --bs-gutter-x: 0;
        background-color: #FFFFFF;
        padding: 20px;
        box-shadow: 0 1px 15px 0px #0000001A;
        margin-bottom: 1.25rem;
    }

    .filterTableWrapper {
        --bs-gutter-x: 0;
        background-color: #FFFFFF;
        box-shadow: 0px 0px 17.9px 0px #00000017;
        margin-bottom: 1.25rem;

        .squareFilterBarWrapper {
            margin-bottom: 0.625rem;
        }
    }
}
