.paymentDetailsContainer {
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .info {
    background-color: #FBBD34;
    padding: .5rem 1rem;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    display: inline-block;
  }
}
