.sellerFinancingContainer {
    background: #f2f2f2;
    min-height: 100dvh;
    padding: 20px;

    .mainHeading {
        font-weight: 600;
        margin-bottom: 10px;
    }
}
