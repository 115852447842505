.totalCampaignBtnsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  row-gap: 10px;

  .total-campaigns {
    padding-inline: 1rem;
    padding-block: .5rem;
    background-color: #FBBD34;
    color: white;
  }

  .dropdown-btns {
    display: flex;
    gap: 10px;
  }

  .button {
    border: 1px solid #000;
    border-radius: .3rem;
    padding-inline: .3rem;
    padding-block: .1rem;
  }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 33px;
  height: 11px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #D9D9D9;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 20px;
  left: 0px;
  bottom: -5px;
  background-color: #FBBD34;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider:before {
  -webkit-transform: translateX(75%);
  -ms-transform: translateX(75%);
  transform: translateX(75%);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@media (max-width: 991px) {
  .totalCampaignBtnsRow {
    flex-direction: column;
    align-items: flex-start;

    .dropdown-btns {
      align-self: flex-end;
    }
  }
}
