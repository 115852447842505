$radius-value: 12px;

.text-light-blue {
  color: #41B8E4;
}

.text-blue {
  color: #1E00FF;
}

.green-border {
  border: 1px solid #05D029;
  border-radius: $radius-value;
}

.green-bg {
  background-color: #9CFFAEBD;
}

.text-orange {
  color: #EC7426;
  font-weight: 500;
}

.bg-yellow {
  background-color: #FBBD3499;
}