.feeDetailsCard {
  border: 1px solid #ced4da;
  background-color: #f8f9fa;
  width: 100%;
  margin: .5rem auto;

  @media (min-width: 576px) {
    width: 100%;
  }

  @media (min-width: 768px) {
    width: 100%;
  }

  @media (min-width: 992px) {
    width: 100%;
  }
}

.feeCategory {
  font-weight: 500;
  margin-bottom: 0rem;
}

.feeDetail {
  font-weight: 500;
  margin-left: 0;
  margin-bottom: .3rem;
}


.wrapper {
  background-color: #D9D9D9;
  border: 1px solid black;
}