.headerTopBar {
  background-color: #f1f1f1;
  font-size: 12px;
  color: #333;

  .welcomeTagline {
    text-align: center;
  }

  .dropdownBtns {
    display: flex;
    justify-content: center;

    .customDropdown {
      font-size: 14px !important;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:focus,
      &:hover {
        text-decoration: none;
        background: none;
      }
    }

    .dropdownItem {
      display: flex;
      align-items: center;
      gap: 5px;

      &:hover {
        background-color: #F2F2F2;
        color: #1A2425;
      }
    }

    .flagIcon {
      width: 20px;
      height: 15px;
      margin-right: 5px;
      object-fit: cover;
      border-radius: 2px;
    }
  }
}

.headerNavbar {
  background-color: #1A2425;
  padding: 10px 0;
  margin-bottom: 5px;

  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-logo {
      width: 100px;
    }

    .navbar-brand {
      img {
        max-width: 100%;
      }
    }

    .navbarToggler {
      border: none;
      padding: 4px 8px;
      background-color: #FFFFFF;

      span {
        background-color: #FFFFFF;
        border-radius: 0.275rem;
      }
    }

    .navbarCollapse {
      .navbarDropdowns {
        margin-right: auto;

        .customDropdown {
          font-size: 14px !important;
          color: #fff;

          &::after {
            margin-left: 5px;
          }
        }

        .customDropdownMenu {

          a:hover,
          a:active,
          a:focus {
            background-color: #FBBD34;
            color: #FFFFFF;
          }
        }
      }
    }

    .nav-link {
      color: #fff;

      &:hover {
        color: lighten(#fff, 20%);
      }
    }

    .form-control {
      border-radius: 4px;
    }
  }

  .headerIcons {
    display: flex;
    align-items: center;

    .headerNavLinks {
      padding: 0;
      border-right: 1px solid #ffffff;
      color: #ffffff;

      i {
        margin-right: 8px;
      }

      &:hover {
        color: #ffffffbf;
      }
    }

    .headerNavLinkLast {
      padding: 0;
      border-right: 0;
      color: #ffffff;

      i {
        margin-right: 8px;
      }

      &:hover {
        color: #ffffffbf;
      }
    }
  }
}

.headerCategories {
  background-color: #FBBD34;

  .categoryBtn {
    background-color: #1A2425 !important;
    margin-right: 10px;
    border-radius: 0;
    color: #fff;

    i {
      display: inline;
      margin-right: 10px;
    }
  }

  .categoriesBtnMenu {
    position: relative;

    .categoryBtn {
      display: flex;
      align-items: center;
      padding: 0.5rem 1rem;
      background-color: black;
      color: white;
      border: none;
      cursor: pointer;

      &:hover {
        background-color: #333;
      }
    }

    .menu {
      display: flex;
      position: absolute;
      top: 100%;
      left: 0;
      background-color: white;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      z-index: 1000;
      min-width: 12.5rem;
      color: #1A2425;
      border-radius: 10px;
      z-index: 999999;

      .mainMenu {
        width: 400px;
        border-right: 1px solid #eee;

        ul {
          padding: 0;
          margin: 0;
          list-style: none;

          .menuItem {
            padding: 0.5rem 1rem;
            cursor: pointer;

            &:hover {
              background-color: #f8f9fa;
              color: #EC7426;
            }

            .menuIcon {
              margin-right: 10px;
              height: 24px;
              width: 24px;
            }
          }
        }
      }
    }

    .subMenu {
      // width: 400px;
      width: 25rem;
      // display: flex;
      flex-wrap: wrap;
      // max-height: 300px;
      max-height: fit-content;
      // overflow-y: auto;
      overflow: hidden;
      padding: .3rem;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          padding: 0.5rem 1rem;
          cursor: pointer;
        }
      }

      .noSubMenu {
        padding: 1rem;
        color: #888;
      }

      .categoryName {
        color: #EC7426;
        font: bold;
      }
    }
  }

  .inputGrp {
    width: 65%;
    margin-right: 16px;
  }

  .searchField {
    border: 0;
    border-radius: 0;
    height: 30px;
    align-self: center;
  }

  .searchBtn {
    background-color: #1A2425 !important;
    border-radius: 0;
    height: 30px;
    align-self: center;
    color: #fff;
  }

  .form-control {
    margin-left: 10px;
  }

  .btn-secondary {
    background-color: darken(#232f3e, 10%);
  }

  .categoriesBarIcons {
    display: flex;
    align-items: center;

    i {
      margin-right: 8px;
    }

    .cartLink {
      display: flex;
      align-items: center;
      color: #ffffff;
      padding: 0px 12px;

      .cartIconWrapper {
        position: relative;
        display: inline-block;

        .cartAmount {
          position: absolute;
          top: 50%;
          left: 20%;
          transform: translate(-50%, -50%);
          font-size: 12px;
          font-weight: bold;
          color: white;
          pointer-events: none;
        }
      }

      .cardText {
        margin: 0;
        margin-left: 2px;
        font-weight: 500;
      }
    }
  }
}


// Responsive Behaviour - Media Queries
@media (max-width: 1220px) {
  .headerNavbar {
    .headerContainer {
      .navbarCollapse {
        .navbarDropdowns {
          .customDropdown {
            font-size: 13px !important;
          }
        }
      }
    }

    .headerIcons {
      font-size: 13px;
    }
  }

  .headerCategories {
    .inputGrp {
      width: 58%;
    }
  }
}

@media (max-width: 1080px) {
  .headerNavbar {
    .headerContainer {
      display: contents;

      .navbarCollapse {
        .navbarDropdowns {
          .customDropdown {
            font-size: 12px !important;
          }
        }
      }
    }

    .headerIcons {
      font-size: 12px;

      .headerNavLinks,
      .headerNavLinkLast {
        i {
          margin-right: 4px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .headerTopBar {
    .dropdownBtns {
      .customDropdown {
        font-size: 12px !important;
      }
    }
  }

  .headerNavbar {
    .headerContainer {
      img {
        width: 60px;
        height: auto;
      }

      .navbarCollapse {
        .navbarDropdowns {
          margin: 10px 0 20px;

          .dropdownNav {
            display: flex;

            .customDropdown {
              font-size: 14px !important;
              padding-left: 10px;
              min-width: 20%;
              border-right: 1px dashed #9B9B9B;

              &::after {
                transform: rotate(270deg);
              }
            }

            .customDropdownMenu {
              width: 100%;
              margin-left: 10px;
            }
          }
        }
      }
    }

    .headerIcons {
      font-size: 14px;
      flex-direction: row;
      justify-content: center;
      gap: 10px;

      .headerNavLinks,
      .headerNavLinkLast {
        padding: 10px;
        border-right: 0;

        i {
          margin-right: 8px;
        }
      }
    }
  }

  .headerCategories {
    .inputGrp {
      width: 48%;
    }

    .categoriesBarIcons {
      i {
        margin-right: 0;
      }
    }
  }
}

@media (max-width: 760px) {
  .headerCategories {
    .categoryBtn {
      i {
        margin-right: 0;
      }

      span {
        display: none;
      }
    }

    .inputGrp {
      width: 65%;
    }

    .categoriesBarIcons {
      justify-content: flex-end;

      i {
        display: none;
      }
    }
  }
}

@media (max-width: 550px) {
  .headerNavbar {
    .headerContainer {
      .navbarCollapse {
        .navbarDropdowns {
          .dropdownNav {
            .customDropdown {
              min-width: 32%;
            }
          }
        }
      }
    }

    .headerIcons {
      flex-direction: column;
      gap: unset;
    }
  }
}
