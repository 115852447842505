.heading {
  font-weight: 600;
  padding: 1rem;
  background-color: #FBBD34;
  font-size: 1.25rem;
  color: #ffffff;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  background-color: #ffffff;
  padding: 1rem;
  border: 1px solid #D9D9D9;

  .bidStrategyContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 10px auto;
    width: 75%;
  }
}

@media (max-width: 991px) {
  .wrapper {
    .bidStrategyContainer {
      width: 100%;
    }
  }
}
