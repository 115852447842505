.modalConatiner {
    font-family: "Inter";

    .modalHeader {
        font-size: 24px;
        font-weight: 600;
        margin: 0;
        line-height: unset;
    }

    .searchContainer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 10px;

        .searchInput {
            width: 30%;
            border: 1px solid #D9D9D9;
            border-radius: 10px;
        }
    }

    .filterContainer {
        border-bottom: 1px solid #D9D9D9;
    }

    .productsContainer {
        margin-top: 20px;

        .totalBanners {
            padding: 10px 0;
            margin-bottom: 10px;

            span {
                background: #FBBD34;
                color: #FFFFFF;
                font-weight: 500;
                font-size: 16px;
                padding: 10px 20px;
            }
        }

        .productsTable {
            padding: 0;

            .productInfo {
                display: flex;
                align-items: center;
                gap: 10px;

                .productImage {
                    width: 60px;
                    height: auto;
                    border-radius: 5px;
                }

                .productName {
                    font-size: 16px;
                    font-weight: 500;
                }

                .productStock {
                    font-size: 11px;
                    font-weight: 500;

                    span {
                        font-weight: 400;
                    }
                }
            }
        }
    }

    .modalFooter {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        margin-top: 20px;

        button {
            text-decoration: none;
            font-size: 14px;
            font-weight: 500;
        }

        .cancelButton {
            background: none;
            border: 1px solid #1A2425;
            color: #1A2425;
            border-radius: 8px;
            padding: 8px 20px;

            &:hover {
                box-shadow: 0 4px 15px -8px #1A2425;
            }
        }

        .saveButton {
            background: #41B8E4 !important;
            border: 1px solid #41B8E4 !important;
            color: #FFFFFF !important;
            border-radius: 8px;
            padding: 8px 24px;

            &:hover {
                box-shadow: 0 8px 25px -8px #41B8E4;
            }
        }
    }
}
