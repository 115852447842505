.heading {
  font-weight: 600;
  padding: 1rem;
  background-color: #FBBD34;
  font-size: 1.25rem;
  color: #ffffff;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  background-color: #ffffff;
  padding: 1rem;
  border: 1px solid #D9D9D9;

  .bidTargetContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 10px auto;
    width: 75%;
  }

  .suggestionText {
    font-size: 12px;
    color: #EC7426;
    font-weight: 400;
    margin-left: 10px;

    .pandaImg {
      margin: 0 6px 1px 0;
    }
  }
}

@media (max-width: 991px) {
  .wrapper {
    .bidTargetContainer {
      width: 100%;
    }
  }
}
