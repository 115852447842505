.mainContainer {
    font-family: "Inter";
    background-color: #f2f2f2;
    min-height: 100vh;
    padding: 20px;

    .mainHeading {
        font-weight: 600;
        margin-bottom: 20px;
    }

    p {
        margin: 0;
        line-height: unset;
    }

    .orderWrapper {
        --bs-gutter-x: 0;
        background-color: #FFFFFF;
        padding: 20px;
        box-shadow: 0 1px 15px 0px #0000001A;
        margin-bottom: 1.25rem;
    }

    .filterTableWrapper {
        --bs-gutter-x: 0;
        background-color: #FFFFFF;
        box-shadow: 0px 0px 17.9px 0px #00000017;
        margin-bottom: 1.25rem;

        .squareFilterBarWrapper {
            margin-bottom: 0.625rem;
        }
    }
}

.text {
    color: #41B8E4 !important
}

.topView {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
}

.transText {
    color: #41B8E4 !important;
}

.insightContainer {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.img {
    height: 1rem;
}

.cards {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.detailsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;

    .details-card {
        flex: 1 1 calc(33.33% - 20px);
        max-width: calc(33.33% - 20px);

        @media (max-width: 992px) {
            flex: 1 1 100%;
            max-width: 100%;
        }
        @media (max-width: 1440px) {
            flex: 1 1 50%;
            max-width: 100%;
        }
    }
}

.cont {
    display: flex;
    flex-direction: row;
    // gap: 15px;
    margin-left: 10px;
    margin-top: 5px;
}

.text1 {
    width: 10rem;
}

.redText {
    color: #FF0000 !important;
}

.blueText {
    color: #1300A5 !important;
    width: 10rem;
}

.greenText {
    color: #0DBC00 !important;
}

.border {
    border: 2px solid #41B8E4 !important;
    border-radius: 8px;
    padding: 5px;
}

.underlineText {
    color: #5D5D5D !important;
    text-decoration: underline;
    font-size: large;
    font-weight: 500;
    margin-top: 15px;
    cursor: pointer;

}

.dropdownContainer{
    display: flex;
    gap: 20px;
    width: 100%;
    margin-top: 30px;
}
.dropdown{
    width: 100%;
}