.search-bar-container {
  position: relative;
}

.search-input {
  border-radius: 17px;
  padding: .5rem;
  padding-right: 80px;
}

.search-button {
  all: unset;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #EC7426;
  padding-inline: 1rem;
  color: #fff;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  transition: all .3s ease-in;

  &:hover, &:focus {
    background-color: #ff8a3c;
    box-shadow: 0px 0px 8px 2px rgba(236, 116, 38, 0.6);
  }
}