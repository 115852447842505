.image {
  width: 325px;
  height: 217px;
}

@media (max-width: 1440px) {
  .image {
    width: 100%;
  }
}
