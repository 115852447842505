.searchInput {
    color: #9b9b9b;

    .inputElement {
        padding-block: 0.5rem;
        padding-inline-start: 2rem;
    }

    .searchIcon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 6px;
        color: #000;
    }
}