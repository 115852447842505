.mainManageProductsContainer {
    background: #F2F2F2;
    padding: 20px;
    font-family: Inter;
    min-height: 100vh;

    .sectionHeading {
        font-weight: 600;
        margin-bottom: 10px;
    }

    .manageProducts {
        padding: 20px;
        background: #FFFFFF;
        border-radius: 10px;
    }
}
