.infoCard {
    background: #FFFFFF;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 0;
    margin: 0;
    height: 100%;

    .cardHeader {
        font-weight: 600;
        font-size: 16px;
        color: #ffffff;
        text-align: center;
        padding: 10px;
    }

    .cardBody {
        font-size: 14px;
        font-weight: 400;
        padding: 16px;

        .infoList {
            --bs-gutter-x: 0;
            margin-bottom: 6px;

            .infoLabel {
                font-weight: 600;
                padding-right: 10px;
            }
        }
    }

    .cardFooter {
        padding-inline: 16px;
        padding-bottom: 16px;
    }
}
