.heading {
  font-weight: 600;
  padding: 1rem;
  background-color: #FBBD34;
  font-size: 1.25rem;
  color: #ffffff;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  background-color: #ffffff;
  padding: 1rem;
  border: 1px solid #D9D9D9;
}

.no-profile-pic {
  position: relative;
  width: fit-content;

  .plus {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.label {
  color: #9B9B9B;
  font-weight: 600;
  font-style: 12px;
}