.mainContainer {
    font-family: "Inter";
    background-color: #f2f2f2;
    min-height: 100vh;
    padding: 20px;

    .mainHeading {
        font-weight: 600;
        margin-bottom: 20px;
    }

    p {
        margin: 0;
        line-height: unset;
    }

    .orderWrapper {
        --bs-gutter-x: 0;
        background-color: #FFFFFF;
        padding: 20px;
        box-shadow: 0 1px 15px 0px #0000001A;
        margin-bottom: 1.25rem;
    }

    .filterTableWrapper {
        --bs-gutter-x: 0;
        background-color: #FFFFFF;
        box-shadow: 0px 0px 17.9px 0px #00000017;
        margin-bottom: 1.25rem;

        .squareFilterBarWrapper {
            margin-bottom: 0.625rem;
        }
    }
}

.text {
    color: #008CD6 !important
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    // width: 100%;
    // height: 100%;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background: white;
    padding: 20px;
    border-radius: 10px;
    // width: 350px;
    text-align: center;
}

.title {
    font-size: 20px;
    font-weight: bold;
    color: #e2702f;
    margin-bottom: 15px;
}

.input {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 5px;
    outline: none;
}

.controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}
