.header {
  background-color: #EC7426;
  padding: 1rem;
  color: #fff;
  margin-bottom: 1rem;
  border-radius: 0.625rem;
  font-weight: 500;
}

@media (max-width: 576px) {
  .header{font-weight: 400}
}