.order-container {
  display: flex;
  gap: 3rem;
  padding: 1rem;
  overflow-x: auto;
  background-color: #F2F2F2;
  white-space: nowrap;

  .order-item {
    font-size: 0.875rem;
    font-weight: 500;
    flex-shrink: 0;
  }

  .order-info {
    text-align: center;
    flex-shrink: 0;

    .info-title {
      font-size: 0.75rem;
      font-weight: 500;
    }

    .info-content {
      font-size: 0.75rem;
    }
  }

  @media (max-width: 1024px) {
    gap: 1.5rem;

    .order-item {
      font-size: 0.75rem;
    }

    .order-info {
      .info-title {
        font-size: 0.7rem;
      }

      .info-content {
        font-size: 0.75rem;
      }
    }
  }
}

.buy-again-button {
  background-color: #41B8E4;
  border-radius: 1.25rem;
  color: #fff;
  padding: .3rem .6rem;
  cursor: pointer;
  font-weight: 600;
}

.view-product-button {
  background-color: #FBBD34;
  border-radius: 1.25rem;
  color: #fff;
  padding: .3rem .6rem;
  cursor: pointer;
  font-weight: 600;
}

.font-sm {
  font-size: 0.75rem;
}

.action-buttons {
  height: 100%;
  flex-direction: column;
  display: flex;
  gap: 0.3rem;

  .action-button {
    border-radius: 1.25rem;
    border: 1px solid black;
    text-align: center;
    padding: .2rem .4rem;
  }
}