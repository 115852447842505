.productListingDescContainer {
    padding-top: 40px;
    padding-right: 20px;

    .filterTabBar {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid #D9D9D9;
        width: 100%;
        overflow-x: auto;
        gap: 60px;
    }

    .reviewsContainer {
        padding-inline: 40px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .ratingsContainer {
            display: flex;
            align-self: center;
            width: 50%;

            .ratingBar {
                flex: 1;
                max-width: 50%;
            }
        }
    }

    .reviewsSectionHeader {
        background: #f2f2f2;
        padding: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
    }

    .reviewsList {
        .reviewDetails {
            display: grid;
            padding: 10px;
            gap: 10px;
            margin-bottom: 20px;

            .reviewerInfo {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .reviewerReview {
                text-align: justify;
            }

            .reviewerImgReactions {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }

    .questionAskedContainer {
        padding: 15px;
        font-family: Inter;

        .questionHeadingWrapper {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
        }

        .percentageBarsWrapper {
            display: flex;
            align-items: center;
            gap: 20px;
        }
    }
}

@media (max-width: 1199px) {
    .productListingDescContainer {
        padding-top: 20px;
    }
}

@media (max-width: 991px) {
    .productListingDescContainer {
        padding-right: 0;
    }
}

@media (max-width: 768px) {
    .productListingDescContainer {
        .filterTabBar {
            gap: 40px;
        }

        .reviewsContainer {
            padding: 0;

            .ratingsContainer {
                width: 100%;

                .ratingBar {
                    max-width: 100%;
                }
            }
        }

        .reviewsSectionHeader {
            flex-direction: column;
            gap: 10px;
        }

        .questionAskedContainer {
            .percentageBarsWrapper {
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;
            }
        }
    }
}

@media (max-width: 480px) {
    .productListingDescContainer {
        .reviewsList {
            .reviewDetails {
                .reviewerInfo {
                    flex-direction: column;
                    gap: 5px;
                }

                .reviewerImgReactions {
                    flex-direction: column-reverse;
                    gap: 10px;
                }
            }
        }

        .questionAskedContainer {
            .questionHeadingWrapper {
                flex-direction: column;
                gap: 10px;
            }
        }
    }
}
