.promotionalLinksContainer {
    .description {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 20px;
    }

    .linksTableContainer {
        margin-bottom: 40px;
        overflow-x: auto;

        .table {
            width: 100%;
            border: 1px solid #D9D9D9;
            margin: 0;

            td,
            th {
                padding: 10px 40px;
                color: #1A2425;
                align-content: center;
            }

            th {
                background-color: #F5F5F5;
                font-weight: 500;
            }

            .copyBtn {
                text-decoration: none;
                padding: 0;
                border: none;
                color: #1A2425;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .urlGeneratorTable {
        .table {
            width: 100%;
            border: 1px solid #D9D9D9;

            td,
            th {
                background-color: #F5F5F5;
                color: #1A2425;
                padding: 10px 40px;
                align-content: end;
            }

            th {
                font-weight: 500;
            }

            .generateUrlText {
                margin-bottom: 5px;
            }

            .inputField {
                background-color: #FFFFFF;
                border: none;
                border-radius: 0;
                width: 100%;
            }

            .generateBtn {
                text-decoration: none;
                padding: 0;
                border: none;
                color: #1A2425;
                margin-bottom: 6px;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
