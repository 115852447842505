.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: .5rem;
}

@media (max-width: 1024px) {
  .wrapper {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .wrapper {
    grid-template-columns: 1fr;
  }
}