.combinationContainer {
    margin-top: 40px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;

    .combinationTitle {
        font-weight: 600;
        margin-bottom: 15px;
    }

    .variationsDataRow {
        span {
            font-weight: 700;
            text-transform: capitalize;
        }
    }

    input {
        border: 1px solid #1A2425;
        border-radius: 5px;

        &::placeholder {
            color: #D9D9D9;
        }
    }

    .suggestionText {
        font-size: 12px;
        color: #EC7426;
        font-weight: 400;
        margin-left: 5px;
        padding: 0;
        width: 80%;

        .pandaImg {
            margin: 0 4px 1px 0;
        }
    }

    .pricesRow {
        display: flex;
        align-items: center;
        border: 1px solid #1A2425;
        border-radius: 5px;

        .priceInput:nth-child(1) {
            border: none;
            border-right: 1px solid #1A2425;
            border-radius: 5px 0 0 5px;
        }

        .priceInput:nth-child(2) {
            border: none;
            border-right: 1px solid #1A2425;
            border-radius: 0;
        }

        .priceInput:nth-child(3) {
            border: none;
            border-radius: 0 5px 5px 0;
        }
    }

    .bulkRangePriceRow {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        gap: 5px;

        .rangeInputRow {
            display: flex;
            gap: 5px;
        }

        .rangeBtn {
            background: #41B8E4 !important;
            border: 1px solid #41B8E4 !important;
            border-radius: 6px;
            color: #FFFFFF !important;
            font-size: 14px;
            font-weight: 400;
            padding: 6px 10px;

            &:hover {
                box-shadow: 0 8px 25px -8px #41B8E4;
            }
        }
    }
}
