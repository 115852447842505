.topCont {
    background: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 15px;
  }
  

.title {
    color: #EC7426 !important;
    font-size: larger;
    font-weight: bold;
}
.title2 {
    color: #5D5D5D !important;
    font-size: larger;
    font-weight: bold;
}
.cont{
    display: flex;
    flex-direction: row;
    // gap: 15px;
    margin-left: 10px;
    margin-top: 5px;
}
.text{
    width: 10rem;
}

@media (max-width: 769px) {
    .topCont {
        padding: 20px;
    }
}

@media (min-width: 991px) {
    .topCont {
        padding: 1.25rem;
    }
}