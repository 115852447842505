.text-yellow {
  color: #FBBD34;
}

.select-amount {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  @media (max-width: 425px) {
    grid-template-columns: 1fr;
  }
}

.amount {
  border: 1px solid #9B9B9B;
  border-radius: 0.625rem;
  text-align: center;
  font-size: 1.25rem;
  cursor: pointer;
}