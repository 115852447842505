.value {
  color: #EC7426;
  font-weight: 500;
}

.header {
  background-color: #D9D9D9;
  color: black;
  padding-inline: 1rem;
  padding-block: .5rem;
  font-weight: 500;
  font-style: 15px;
}

.row:not(:last-child) {
  border-bottom: 1px solid #D9D9D9;
}

.row {
  background-color: white;
  padding-inline: 1rem;
  padding-block: .5rem;
}