.mainScheduleDropOffContainer {
    background: #f2f2f2;
    padding: 20px;
    font-family: Inter;
    min-height: 100vh;

    .scheduleDropOffWrapper {
        padding: 0;

        .scheduleDropOffRow {
            --bs-gutter-x: 0;
            margin-bottom: 20px;
        }
    }
}
