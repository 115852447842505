.imgMagnifierContainer {
    display: flex;
    justify-content: center;
    position: relative;
    height: 517px;
    max-height: 517px;

    .image {
        height: 517px;
        max-width: 100%;
        object-fit: cover;
    }
}
