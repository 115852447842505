.label {
  font-weight: 500;
  font-size: 13px;
}

.para {
  font-size: 13px;
}

.forgetPassword {
  font-weight: 500;
  font-size: 13px;
}