.dynamicCard {
    border: 1px solid #C3C3C34F;
    border-radius: 10px;
    box-shadow: 0 4px 4px #0000001A;
    transition: all 0.3s ease;
    margin: 0;
    height: 100%;

    &:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

    .cardBody {
        font-size: 0.9375rem;
        font-weight: 500;
        padding: 1rem;

        .title {
            margin-bottom: 0.5rem;
        }

        .content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 0.625rem;

            .value {
                font-size: 1.5rem;
            }

            .muted-value {
                font-size: 0.875rem;
                font-weight: 400;
            }

            .icon {
                margin-right: 1.25rem;
            }

            .valueBtn {
                text-decoration: none;
                color: #FBBD34;
                padding: 10px 0 0;
                font-size: 20px;
                font-weight: 500;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

@media (max-width: 769px) {
    .dynamicCard {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        .cardBody {
            font-size: 0.875rem;
            padding: 0.75rem;

            .content {
                flex-direction: column;
                align-items: flex-start;
                gap: 0.5rem;

                .value {
                    font-size: 1.25rem;
                }

                .muted-value {
                    font-size: 0.8125rem;
                }

                .icon {
                    margin-right: 0;
                }
            }
        }
    }
}
