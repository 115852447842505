.logout {
  display: flex;
  color: white;
  padding: 10px 15px 10px 15px;
  margin-inline-start: 0.8rem;
  gap: 1.5rem;
  cursor: pointer;

  &:hover {
    .icon {
      transition: all 0.3s ease-in-out;
      transform: translateX(5px);
    }
  }
}

.settings {
  margin-top: 1rem;
  margin-inline-start: 1.7rem;

  .switch-to {
    border: 1px solid white;
    padding: 0.3rem 1rem;
  }

  .dropdownMenu {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    // padding: 0.5rem;
    color: #000;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }

  ul > li {

    cursor: pointer;
    padding-inline: .5rem;
    transition: background-color .3s ease-in-out;
    &:hover {
      background-color: #d9d9d9;
    }
    
    &:not(:last-child) {
      border-bottom: 1px solid #d9d9d9;
      padding-block: .1rem;
    }
  }
}

.settings-active {
  color: #fbbd34;
}
