.maelanoCampaignsContainer {
    .mainHeading {
        font-weight: 600;
        margin-bottom: 20px;
    }

    .orderWrapper {
        --bs-gutter-x: 0;
        background-color: #FFFFFF;
        padding: 20px;
        box-shadow: 0 1px 15px 0px #0000001A;
        margin-bottom: 20px;
    }

    .filterTableWrapper {
        --bs-gutter-x: 0;
        background-color: #FFFFFF;
        box-shadow: 0px 0px 17.9px 0px #00000017;
        margin-bottom: 20px;

        .headingBtnWrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px;

            .sectionHeading {
                font-weight: 600;
                margin: 0;
                color: #008CD6;
            }
        }

        .tableWrapper {
            padding: 20px;
        }
    }
}
