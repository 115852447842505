.pageCardBtn {
  all: unset;
  background-color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid #6c757d;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #e9ecef;
  }
}