.mainContainer {
    background: #F2F2F2;
    padding: 20px;
    font-family: Inter;
    min-height: 100vh;

    .sectionHeading {
        font-weight: 600;
        margin-bottom: 10px;
    }

    .filterContainer {
        border-bottom: 1px solid #D9D9D9;
        margin-bottom: 20px;
    }
}
