.managementMainContainer {
    background: #f2f2f2;
    min-height: 100dvh;
    padding: 20px;

    .campaignWrapper {
        background-color: #ffffff;
        padding: 30px;
        border-radius: 10px;
    }

    .filterTabs {
        display: flex;
        overflow-x: auto;
        flex-wrap: nowrap;
        border-bottom: 1px solid #D9D9D9;
        gap: 30px;
        margin-bottom: 20px;
    }
}
