.mainContainer {
    .sectionHeading {
        font-weight: 600;
        margin-bottom: 10px;
    }

    .scheduleContainer {
        background: #ffffff;
        font-size: 15px;
        padding: 10px;
        margin-bottom: 20px;

        .scheduleRow {
            --bs-gutter-x: 0;
            padding: 20px;
            border: 1px solid #d9d9d9;

            ul {
                padding-left: 30px;
                font-size: 18px;
                line-height: 30px;
                margin: 0;
            }
        }
    }
}

@media (max-width: 991px) {
    .mainContainer {
        .sectionHeading {
            font-size: 20px;
        }

        .scheduleContainer {
            .scheduleRow {
                ul {
                    font-size: 16px;
                }
            }
        }
    }
}
