.dropdown {
    .dropdownToggle {
        background-color: transparent !important;
        color: #1A2425 !important;
        border: 1px solid #1A2425;
        border-radius: 5px;
        padding: 6px 14px;
        display: flex;
        align-items: center;
        gap: 5px;

        &:hover {
            box-shadow: 0 8px 15px -8px #82868b !important;
        }

        &.caretUp::after {
            transform: rotate(180deg); // Rotate caret upwards
        }

        &.caretDown::after {
            transform: rotate(0deg); // Default caret pointing downwards
        }

        &::after {
            content: "";
            display: inline-block;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #000;
            transition: transform 0.3s ease;
        }
    }

    .dropdownMenu {
        border-radius: 6px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }

    .dropdownItem {
        padding: 8px 16px;
        font-size: 14px;

        &:hover {
            background-color: #f1f1f1;
        }
    }
}
