.addProductBtnContainer {
    margin-top: 1rem;
    display: flex;
    justify-content: end;
    gap: 1rem;

    .add-product-btn {
        padding: .5rem 1rem;
        border: 1px solid black;
        border-radius: .5rem;
        background-color: #ffffff;

        &:hover {
            transition: background-color .3s ease-in;
            background-color: rgba($color: #d9d9d9, $alpha: 0.5);
        }
    }

    .productBtn {
        background: #41B8E4 !important;
        border: 1px solid #41B8E4 !important;
        color: #FFFFFF !important;
        font-size: 14px;
        font-weight: 400;
        padding: 10px 20px;

        &:hover {
            box-shadow: 0 8px 25px -8px #41B8E4;
        }
    }
}