.header {
  background-color: #FBBD34;
  padding: 1rem;
}

.tab {
  &:not(:last-child) {
    border-right: 2px solid #F2F2F2;
  }

  .category-label {
    @media (max-width: 768px) {
      font-size: 0.75rem;
    }
  }
}

.questions {
  border: 2px solid #F2F2F2;
}

.listItems {
  padding: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s all ease-in-out;

  &:not(:last-child) {
    border-bottom: 2px solid #f2f2f2;
  }

  &:hover {
    transform: scale(1.005);
  }
}

.receiver {
  max-width: 47.3331rem;

  @media (max-width: 768px) {
    font-size: 0.75rem;
  }
}

.sender {
  max-width: 21.3125rem;
  background-color: #FAC02E;
  padding: 1rem;
  border-radius: 0.625rem;
  color: #fff;
  margin-left: auto;
  margin-top: 1rem;
  font-size: 1.25rem;

  @media (max-width: 768px) {
    font-size: 0.75rem;
    max-width: 75%;
  }
}

.button {
  all: unset;
  flex: 1;
  border: 2px solid #FBBD34;
  text-align: center;
  padding: .5rem 2rem;
  font-size: 1.25rem;
  transition: all .3s ease-in;
  &:hover {
    color: white;
    background-color: #FBBD34;
  }

  @media (max-width: 768px) {
    padding: .2rem .6rem;
    font-size: 1rem;
  }
}

.pills-wrapper {
  padding: .3rem 1rem;
  background-color: #F2F2F2;
  margin-top: 1rem;
  overflow-x: auto;
}

.pill {
  border: 2px solid #D9D9D9;
  border-radius: 2.6669rem;
  color: #9B9B9B;
  font-size: 1rem;
  padding: .1rem 1rem;
  background-color: #fff;

  @media (max-width: 768px) {
    font-size: 12px;
    padding: .2rem .6rem;
  }
}



.input-wrapper { 
  position: relative;
}

.input {
  padding-block: 1rem;

  @media (max-width: 768px) {
    padding-block: .5rem;
  }
}

.icons {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  display: flex;
  gap: 1rem;
  button {
    all: unset;
    cursor: pointer;
  }
}