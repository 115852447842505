.content {
  background-color: #fff;
  border-top-right-radius: 0.625rem;
  border-top-left-radius: 0.625rem;
  margin-top: 1rem;

  .info {
    display: flex;
    padding: 1rem;
    justify-content: space-between;
    align-items: center;
  }
}
