.mainContainer {
    font-family: "Inter";
    background-color: #f2f2f2;
    min-height: 100vh;
    padding: 20px;

    .mainHeading {
        font-weight: 600;
        margin-bottom: 20px;
    }

    p {
        margin: 0;
        line-height: unset;
    }

    .orderWrapper {
        --bs-gutter-x: 0;
        background-color: #FFFFFF;
        padding: 20px;
        box-shadow: 0 1px 15px 0px #0000001A;
        margin-bottom: 1.25rem;
    }

    .filterTableWrapper {
        --bs-gutter-x: 0;
        background-color: #FFFFFF;
        box-shadow: 0px 0px 17.9px 0px #00000017;
        margin-bottom: 1.25rem;

        .squareFilterBarWrapper {
            margin-bottom: 0.625rem;
        }
    }
}

.text {
    color: #008CD6 !important
}

// from here 
.msgInfo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.msgInfoText {
    background-color: #FFFC4899 !important;
    border-radius: 8px;
    padding: 10px;
}

.msgInfoText2 {
    background-color: #C9FFC1AB !important;
    border-radius: 8px;
    padding: 5px;
    // margin-right: 10%;
    width: 7rem;
    margin-bottom: 10px;
    margin-top: 10px;
}

.msgInfoText3 {
    background-color: #C9FFC1AB !important;
    border-radius: 8px;
    padding: 10px;
    margin-right: 3.5rem;
    width: 7rem;
}

.cont {
    display: flex;
    flex-direction: row;
    // gap: 15px;
    margin-left: 10px;
    margin-top: 5px;
}
.cont2 {
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin-left: 10px;
    margin-top: 5px;
}

.text1 {
    width: 7rem;
}

.text2 {
    width: 14rem;
}

.redText {
    color: #FF0000 !important;
}

.blueText {
    color: #41B8E4 !important;
    // width: 7rem;
}

.greenText {
    color: #0DBC00 !important;
}

.yellowText {
    color: #FBBD34;
    width: 7rem;
}

.msg {
    border: 1px solid #7C7C7C;
    border-radius: 8px;
    padding: 10px;
}

.border {
    border: 2px solid #727272 !important;
    border-radius: 8px;
    padding: 5px;
    margin-left: -2px;
}

.underlineText {
    color: #5D5D5D !important;
    text-decoration: underline;
    font-size: large;
    font-weight: 500;
    margin-top: 15px;
    cursor: pointer;

}