.useThemeButton {
  background-color: #41B8E4 !important;
  border: none;
  color: #FFFFFF !important;

  &:hover {
    box-shadow: 0 8px 25px -8px #41B8E4 !important;
  }
}

.header-connecting-line {
  position: absolute;
  left: 50%;
  height: 1px;
  background-color: black;

  top: 200px;
  width: 30%;
}

.header-label {
  position: absolute;
  left: 80%;
  top: 180px;
}

.promotions-connecting-line {
  position: absolute;
  left: 50%;
  height: 1px;
  background-color: black;

  top: 500px;
  width: 30%;
}

.promotions-label {
  position: absolute;
  left: 80%;
  top: 480px;
}

.promotion-connecting-line {
  position: absolute;
  left: 50%;
  height: 1px;
  background-color: black;

  top: 900px;
  width: 30%;
}

.promotion-label {
  position: absolute;
  left: 80%;
  top: 880px;
}

.product-connecting-line {
  position: absolute;
  left: 50%;
  height: 1px;
  background-color: black;

  top: 1400px;
  width: 30%;
}

.product-label {
  position: absolute;
  left: 80%;
  top: 1380px;
}

.hot-deals-promotion-connecting-line {
  position: absolute;
  left: 50%;
  height: 1px;
  background-color: black;

  top: 1800px;
  width: 30%;
}

.hot-deals-promotion-connecting-label {
  position: absolute;
  left: 80%;
  top: 1780px;
}
