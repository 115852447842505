.colored-bg {
  background-color: #FFD5D5;

  ul {
    li {
      color: #5D5D5D;
    }
  }
}

.btn {
  all: unset;
  color: red;
  transition: filter 0.3s ease-in;
  &:hover {
    filter: drop-shadow(0px 0px 8px rgba(255, 0, 0, 0.5));
  }
}