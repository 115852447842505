.dropdown-container {
    position: relative;
    border: 1px solid #D9D9D9;
    border-radius: .3rem;
    padding-block: .4rem;
}

.dropdown-toggle {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    cursor: pointer;
    justify-content: space-between;
    padding-inline: .8rem;
}

.icon-wrapper {
    display: flex;
    align-items: center;
}

.dropdown-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 120%;
    right: -3px;
    background-color: #fff;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 200px;
    z-index: 99999;
    font-size: 0.75rem;
    border-radius: 0.25rem;
    height: auto;
    max-height: 119px;
    overflow-y: auto;
}

.dropdown-item {
    padding: 0.25rem 0.5rem;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #f2f2f2;
    border-radius: 0.25rem;

    &:hover {
        background-color: #f2f2f2;
    }
}

.dropdown-item-no-options {
    padding: 0.25rem 0.5rem;
    background-color: #fff;
    border-bottom: 1px solid #f2f2f2;
    cursor: default;
    color: #888;
    border-radius: 0.25rem;
}

.dropdown-item label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.dropdown-item input[type="checkbox"] {
    margin-right: 0.5rem;
}