.underApprovalProductsContainer {

    .totalProducts {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 992px) {
            flex-direction: column;
            align-items: start;
            gap: 12px;
            margin-bottom: 16px;
        }

        >span {
            background: #FBBD34;
            color: #FFFFFF;
            font-weight: 500;
            font-size: 16px;
            padding: 10px 20px;
            display: inline-block;
        }
    }

    .underApprovalText {
        padding: 0;
        margin-bottom: 10px;

        p {
            background: #F2F2F2;
            border: 1px solid #D9D9D9;
            padding: 10px;
        }
    }

    .productImg {
        width: auto;
        max-width: 100%;
        height: 40px;
    }

    .priceContainer {
        font-size: 14px;
        padding: 10px 0;

        .priceShippingRow {
            padding: 6px 0;
            margin-bottom: 5px;

            .price {
                border: 1px solid #D9D9D9;
                border-radius: 5px;
                padding: 5px;
                margin-right: 6px;
            }
        }

        .priceDetails {
            font-size: 12px;
        }
    }

    .actionDropdown {
        .dropdownButton {
            background-color: transparent !important;
            color: #1A2425 !important;
            border: none;
            padding: 0;
            font-size: 13px;

            &:hover {
                box-shadow: none !important;
            }
        }
    }
}
