.menu {
  background: "#1A2425";
  // min-height: 100%;
  padding: 0;
}

.leftCol {
  @media (min-width: 768px) {
    min-height: 100vh;
  }
}

.btn {
  border-radius: 10px;
  padding: 6px 10px !important;
  margin: 8px;
}
