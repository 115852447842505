.text-yellow {
  color: #FBBD34;
  font-size: 0.75rem;
  padding: 1rem;

  .text-orange {
    color: #EC7426;
    text-transform: uppercase;
  }
}

label {
  font-weight: 500;
}

.wrapper {

  display: grid;
  justify-content: center;

  .tick {
    display: grid;
    place-items: center;
    max-width: fit-content;
    margin-inline: auto;
    margin-block-start: 2rem;
    margin-bottom: 2rem;
  }
  
  .tick > * {
    grid-area: 1/1;
  }
}


.input[type="radio"] {
  appearance: none;
  width: 1rem;
  height: 1rem;
  border: 1px solid #D9D9D9;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  position: relative;

  &:checked::before {
    content: "";
    display: block;
    width: 80%;
    height: 80%;
    background-color: #FBBD34;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba(251, 189, 52, 0.5);
  }
}