.mainContainer {
    background: #F2F2F2;
    padding: 20px;
    font-family: Inter;
    min-height: 100vh;

    .sectionHeading {
        font-weight: 600;
        margin-bottom: 20px;
    }

    .financialReportRow {
        --bs-gutter-x: 0;
        margin-bottom: 20px;
    }

    .downloadContainer {
        display: flex;
        align-items: center;

        .downloadButton {
            background: #41B8E4 !important;
            border: 1px solid #41B8E4 !important;
            color: #FFFFFF !important;
            border-radius: 8px;
            font-size: 14px;
            font-weight: 500;
            padding: 8px 20px;

            &:hover {
                box-shadow: 0 8px 25px -8px #41B8E4;
            }
        }
    }
}
