.heading {
    font-size: x-large;
    font-weight: 600;
    margin-top: 2rem;
}
.heading2 {
    font-size: x-large;
    font-weight: 600;
}

.card {
    background-color: #F2F2F2;
    border-radius: 8px;
    padding: 20px;
    // text-align: center;
    margin-top: 10px;
}
.goalCard {
    background-color: #F2F2F2;
    border-radius: 8px;
    padding: 20px;
    text-align: start;
    margin-top: 10px;
}

.card2 {
    background-color: #FBBD34;
    border-radius: 8px;
    padding: 20px;
    // text-align: center;
    margin-top: 10px;
}
.cardHeadingText{
    font-size: larger;
}
.smallText{
    font-size: small;
    margin-top: 5px;
}
.cardText {
    font-weight: 500;
    font-size: x-large;
}

.cardText2 {
    color: white;
    font-size: larger;
}

.cardText3 {
    color: white;
    font-size: x-large;
    font-weight: 500;

}

.cardText4 {
    color: white;
    font-size: small;
    margin-top: 5px;
}

.picCard {
    background-color: #F2F2F2;
    border-radius: 8px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 10%;
    padding-left: 10%;
    text-align: center;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.picCardText{
    text-align: start;
    margin-top: 10px;
}
.img{
    height: 3rem;
    width: 3rem;
    border-radius: 9999;
}
.imgCont{
    background-color: #41B8E4;
    margin-top: 20px;
}
.shoutImg{
    width: 100%;
}
.shoutCard {
    background-color: #F2F2F2;
    padding: 10px;
    text-align: center;
}
.circularProgress{
    width: 30%;
}
.circularProgressText{
    color: #EC7426;
    font-weight: 600;
}