.heading {
  background-color: #F2F2F2;
  font-weight: 500;
  padding-inline: 1rem;
}

.name {
  font-size: 15px;
}

.subcontent {
  font-size: 11px;
}