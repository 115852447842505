.filterTabs {
    .filtersRow {
        --bs-gutter-x: 0;
        width: 100%;
        background: #ffffff;
        flex-wrap: nowrap;
        white-space: nowrap;
        overflow-x: auto;

        .filterTabColumn {
            align-content: center;
            border-right: 1px solid #D9D9D9;

            .navItem {
                text-align: center;

                .navLink {
                    text-decoration: none;
                    font-size: 18px;
                    font-weight: 600;

                    &:hover {
                        color: #EC7426;
                    }
                }

                .activeTab {
                    border-top: 3px solid #FBBD34;
                    border-bottom: 3px solid #FBBD34;
                }
            }

            &:last-child {
                border: none;
            }
        }
    }
}
