.mainContainer {
    background: #F2F2F2;
    padding: 20px;
    font-family: Inter;
    min-height: 100vh;

    .sectionHeading {
        font-weight: 600;
        margin-bottom: 15px;
    }

    .filterContainer {
        margin-bottom: 20px;
        background: #FFFFFF;
        border-radius: 10px;
        padding: 5px;
    }

    .scheduleContainer {
        background: #ffffff;
        font-size: 15px;
        padding: 10px;
        margin-bottom: 20px;

        .table {
            padding: 20px;
            border: 1px solid #d9d9d9;
        }
    }
}
