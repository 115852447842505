.colorPickerContainer {
    .colorPickerRow {
        --bs-gutter-x: 0;
        row-gap: 5px;

        label {
            font-size: 15px;
            font-weight: 400;
            color: #1A2425;
            margin: 0;
            padding: 7px 5px 0 0;
        }

        .colorInputRow {
            --bs-gutter-x: 0;

            .colorInput {
                max-width: 100%;
                width: auto !important;
            }

            .addButton {
                background: none !important;
                border: none !important;
                color: #1A2425 !important;
                font-size: 16px;
                font-weight: 400;
                margin-left: 10px;

                &:hover {
                    background: #41B8E4 !important;
                    color: #ffffff !important;
                    box-shadow: 0 8px 25px -8px #41B8E4 !important;
                }
            }
        }

        .colorList {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .colorItem {
                display: flex;
                align-items: center;

                .removeColorButton {
                    border-radius: 20px;
                    padding: 0 4px 4px;
                    margin-right: 8px;
                    background-color: transparent !important;
                    color: #e70000 !important;
                    border: none;

                    &:hover {
                        background-color: #e70000 !important;
                        color: #ffffff !important;
                        box-shadow: none;
                    }
                }

                .colorBox {
                    width: 55px;
                    height: 30px;
                    border-radius: 6px;
                    margin-right: 10px;
                }

                .colorName {
                    border: 1px solid #1A2425;
                    border-radius: 6px;
                    background-color: #ffffff !important;
                    padding: 6px 12px;
                    max-width: 100%;
                    width: 70%;
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .colorPickerContainer {
        .colorPickerRow {
            label {
                padding: 0;
            }

            .colorList {
                padding-top: 20px;
            }
        }
    }
}
