.addProductBtnContainer {
  margin-top: 1rem;

  .productBtn {
      background: #41B8E4 !important;
      border: 1px solid #41B8E4 !important;
      color: #FFFFFF !important;
      font-size: 14px;
      font-weight: 400;
      padding: 10px 20px;

      &:hover {
          box-shadow: 0 8px 25px -8px #41B8E4;
      }
  }
}

.wrapper {
  background-color: #FBBD34;
  color: white;
  padding: .5rem;
  margin-top: 1rem;

  .heading {
    font-weight: 600;
    font-size: 1.25rem;
  }

}

.table-wrapper {
  padding: 1rem;
  background-color: #FFFFFF;
}