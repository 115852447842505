.fileUploaderContainer {
    .imageFileUploader {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        .uploadLabel {
            width: 80px;
            height: 100px;
            margin: 5px 10px;
            background-color: #F2F2F2;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .inputfile {
            display: none;
        }

        .uploadedMedia {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;

            .mediaContainer {
                width: 80px;
                height: 100px;
                margin: 5px 10px;
                position: relative;

                .videoPreview,
                .imagePreview {
                    width: 100%;
                    height: 100%;
                    border-radius: 5px;
                    object-fit: cover;
                }

                .removeButton {
                    border: none !important;
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }
    }

    .urlInputSection {
        padding-top: 10px;

        .urlInputContainer {
            display: flex;
            align-items: center;
            margin-top: 5px;

            .urlInput {
                width: 70%;
                margin-right: 20px;
                font-size: 14px;
                border: 1px solid #1A2425;
                color: #1A2425;

                &::placeholder {
                    color: #D9D9D9;
                }
            }

            .urlAddButton {
                background: none !important;
                color: #1A2425 !important;
                font-size: 14px;
                font-weight: 500;
                border: 1px solid #1A2425;
                border-radius: 10px;
                padding: 6px 15px;
            }
        }
    }

    .errorText {
        color: red;
        font-size: 12px;
        margin: 10px;
    }
}
