.filterBar {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    white-space: nowrap;

    // Default Filter Bar Styles
    &-default {
        gap: 20px;
        font-weight: 500;
    }

    &-orange-sub-bar {
        gap: 20px;
        font-weight: 500;
    }

    &-top-bar {
        justify-content: space-around;
        font-size: 1.25rem;
        font-weight: 600;
        gap: 20px;
        padding-inline: 20px;
    }

    &-yellow-without-bg {
        justify-content: space-around;
        font-size: 1.25rem;
        font-weight: 600;
        gap: 20px;
        padding-inline: 20px;
    }

    &-red-bar {
        gap: 50px;
        font-size: 20px;
        font-weight: 500;
    }

    &-dark-borderless {
        gap: 40px;
        font-weight: 500;
    }

    .filterTab {
        cursor: pointer;

        // Default Filter Bar Styles
        &-default {
            padding: 6px 14px;
            border-bottom: 2px solid transparent;

            &:hover {
                color: #ec7426;
            }

            &.active {
                color: #ec7426;
                border-color: #ec7426;
            }
        }

        &-top-bar {
            &:hover {
                color: #ec7426;
            }

            &.active {
                color: #ec7426;
            }
        }

        &-orange-sub-bar {
            padding: 6px 14px;

            &:hover {
                color: #fbbd34;
            }

            &.active {
                color: #fbbd34;
            }
        }

        &-yellow-without-bg {
            transition: all .3s ease-in;
            padding: 6px 14px;

            &:hover {
                color: #fbbd34;
            }

            &.active {
                color: #fbbd34;
            }
        }

        &-red-bar {
            padding-bottom: 6px;
            border-bottom: 2px solid transparent;
            color: #1A2425;

            &:hover {
                color: #E74C3C;
            }

            &.active {
                color: #1A2425;
                border-color: #E74C3C;
                font-weight: 700;
            }
        }

        &-dark-borderless {
            color: #1A2425;

            &.active {
                color: #1A2425;
                font-weight: 700;
            }
        }
    }
}
