.actionDropdown {
  .dropdownButton {
    background-color: transparent !important;
    color: #1a2425 !important;
    border: none;
    padding: 0;

    &:hover {
      box-shadow: none !important;
    }
  }

  .dropDownMenu {
    border-radius: 10px;
    border: none;
    box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.0705882353);
    font-size: 15px;
    font-weight: 400;

    .dropdownItem {

      &:hover,
      &:active,
      &:focus {
        background-color: #fabc42;
        color: #FFFFFF;
      }
    }
  }
}

.actionBtn {
  // background-color: #dceff9 !important;
  // color: #4DAFE2 !important;
  font-weight: bold;
  border-radius: 0.5rem;
  font-size: 0.65rem;
  padding: 0.5rem;
  width: 5rem;
  text-align: center;
  cursor: pointer;

  &-assign {
    background-color: #dceff9;
    color: #4DAFE2;
  }

  &-view {
    background-color: #e7dbfe;
    color: #884DFF;
  }
  &-resolve{
    color: #D456FD;
    background-color: #f0d8f8;
  }

}
.profit{
  background-color: #A5FF87C4;
  padding: 6px;
  border-radius: 8px;
  color: #555F7E;
}