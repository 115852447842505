.actionDropdown {
  .dropdownButton {
    background-color: #fff !important;
    color: #1a2425 !important;
    border: 1px solid #8f91a899 !important;

    &::after {
      transform: rotate(0deg);
      transition: transform 0.3s ease-in-out;
    }

    &.open::after {
      transform: rotate(180deg);
    }

    &:hover {
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15) !important;
    }
  }

  .dropDownMenu {
    border-radius: 10px;
    border: none;
    box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.0705882353);
    font-size: 14px;
    font-weight: 400;
    border: 1px solid #e2e2e2;
    min-width: fit-content;

    .dropdownItem {
      &:not(:last-child) {
        border-bottom: 1px solid #f2f2f2;
      }

      &:hover,
      &:active,
      &:focus {
        background-color: #fabc42;
        color: #ffffff;
      }

      &.disabled {
        pointer-events: none;
        background-color: #f2f2f2 !important;
        color: #a0a0a0 !important;
        cursor: not-allowed;
      }
    }
  }
}
