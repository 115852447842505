.mainContainer {
    p {
        margin: 0;
        line-height: unset;
    }

    .sectionTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fbbd34;
        color: #ffffff;
        padding: 6px 10px;

        .sectionSubHeading {
            font-weight: 600;
            margin: 0;
        }
    }

    .newCategorySection {
        background: #ffffff;
        font-size: 15px;
        padding: 0;
        margin-bottom: 30px;

        .newCategoryFormRow {
            --bs-gutter-x: 0;
            padding: 20px;
            border: 1px solid #d9d9d9;
            margin: 10px;

            .formSection {
                .formGroupRow {
                    --bs-gutter-x: 0;
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                    row-gap: 5px;

                    label {
                        font-size: 15px;
                        font-weight: 400;
                        color: #1A2425;
                        margin: 0;
                    }

                    input,
                    select {
                        border: 1px solid #1A2425;
                        border-radius: 5px;

                        &::placeholder {
                            color: #D9D9D9;
                        }
                    }

                    textarea {
                        min-height: 80px;
                        border: 1px solid #1A2425;
                        border-radius: 5px;

                        &::placeholder {
                            color: #D9D9D9;
                        }
                    }
                }
            }
        }

        .productSelectionRow {
            --bs-gutter-x: 0;
            padding: 20px;

            .addProductContainer {
                display: flex;
                align-items: center;
                margin-bottom: 15px;

                .addProductButton {
                    background: #41B8E4 !important;
                    border: 1px solid #41B8E4 !important;
                    color: #FFFFFF !important;
                    border-radius: 8px;
                    font-size: 14px;
                    font-weight: 400;
                    padding: 8px 20px;

                    &:hover {
                        box-shadow: 0 8px 25px -8px #41B8E4;
                    }
                }
            }

            .totalSelectedProducts {
                font-size: 15px;
                font-weight: 500;
                margin-bottom: 15px;

                span {
                    color: #EC7426;
                }
            }

            .tableContainer {
                overflow-x: auto;

                .productTable {
                    text-align: center;
                    vertical-align: middle;

                    tr {
                        vertical-align: middle;
                        white-space: nowrap;
                    }

                    th {
                        background-color: #F2F2F2;
                        font-weight: 400;
                    }

                    .productRow {
                        text-align: left;
                        --bs-gutter-x: 0;
                        display: flex;
                        align-items: center;
                        min-width: max-content;

                        .productImageContainer {
                            padding-right: 10px;

                            img {
                                max-width: 100%;
                                height: auto;
                            }
                        }

                        .productDetailsContainer {
                            font-weight: 500;

                            .productName {
                                font-size: 16px;
                            }

                            .productId,
                            .productStock {
                                font-size: 11px;

                                span {
                                    font-weight: 400;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .createBtnContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        .createBtn {
            background: #41B8E4 !important;
            border: 1px solid #41B8E4 !important;
            color: #FFFFFF !important;
            font-size: 14px;
            font-weight: 400;
            padding: 10px 20px;

            &:hover {
                box-shadow: 0 8px 25px -8px #41B8E4;
            }
        }
    }
}
