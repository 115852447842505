.wrapper {
  border-bottom: 2px solid #d9d9d9;
  margin-block: 1rem;

  .tabs-wrapper {
    display: flex;
    gap: 2rem;
    overflow-x: auto;
    scroll-snap-type: x mandatory;

    .tab {
      padding-block-end: 0.1rem;
      font-size: 1.25rem;
      font-weight: 500;
      cursor: pointer;
      transition: border-bottom 0.3s, color 0.3s;
      scroll-snap-align: start;

      &:not(.tab-active):hover {
        border-bottom: 3px solid #f5a066;
      }
    }

    .tab-active {
      border-bottom: 3px solid #ec7426;
      font-weight: 700;
    }
  }
}

@media (max-width: 1440px) {
  .wrapper {
    .tabs-wrapper {
      gap: 1.5rem;

      .tab {
        font-size: 1rem;
        text-wrap: nowrap;
      }

      .tab-active {
        border-bottom: 2px solid #ec7426;
      }
    }
  }
}