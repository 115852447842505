.container {
  position: relative;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.backgroundCircle {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #eaeaea;
}

.centerText {
  position: absolute;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}
