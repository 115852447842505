.sidebar {
    padding: 20px 0;
    border-right: 1px solid #D9D9D9;
    height: 100%;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li {
        padding: 10px;
        cursor: pointer;
        font-weight: 500;
    }

    .active {
        font-weight: 700;
    }
}

@media (max-width: 1199px) {
    .sidebar {
        border: 0;
        border-bottom: 1px solid #D9D9D9;
        padding: 0 8px 10px;

        li {
            padding: 10px 0
        }
    }
}
