.dropDown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #ffffff;
    color: #1A2425;
    padding: 0.5rem;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    text-align: left;
    font-size: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1;

    .dropDownItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.3rem;
        padding: 0.5rem;
        cursor: pointer;
        transition: background-color 0.2s ease;

        &:hover {
            background-color: #f5f5f5;
        }

        p {
            font-size: 14px;
            flex: 1;
        }

        .arrowIcon {
            color: #999;
            font-size: 12px;
            transition: transform 0.2s ease, color 0.2s ease;
            cursor: pointer;

            &:hover {
                color: #1A2425;
            }
        }
    }

    .nestedDropDown {
        position: absolute;
        top: 84%;
        left: 101%;
        background-color: #ffffff;
        color: #1A2425;
        padding: 0.5rem;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        text-align: left;
        font-size: 14px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        z-index: 2;
        width: 100%;
    }
}
