.guidelineSupportContainer {
    font-family: Inter;
    margin: 10px 0;

    .mainHeading {
        font-weight: 600;
        margin-bottom: 20px;
    }

    .sectionGuidelineHeading {
        font-weight: 600;
        margin-bottom: 10px;
    }

    .sectionHeading {
        font-weight: 600;
        margin-bottom: 20px;
    }

    p {
        margin: 0;
        line-height: unset;
    }

    .guidelineRow {
        --bs-gutter-x: 0;
        background-color: #ffffff;
        padding: 20px;
        border-radius: 10px;
        text-align: justify;
        margin-bottom: 20px;
        row-gap: 10px;

        .createBrandColumn {
            padding-right: 10px;
        }

        .documentationApprovalColumn {
            padding-left: 10px;

            .documentationApprovalRow {
                --bs-gutter-x: 0;
                gap: 10px;
            }
        }

        .box {
            border: 1px solid #D9D9D9;
            border-radius: 10px;
            padding: 20px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            height: 100%;

            .subSectionHeading {
                font-weight: 600;
                margin: 0;
            }

            ul,
            ol {
                padding-left: 20px;
                margin: 0;
            }

            .stepList {
                .subStepsList {
                    list-style-type: disc;
                }
            }
        }
    }

    .faqDataContainer {
        background: #ffffff;
        padding: 5px 20px;
        border-radius: 10px;
        margin-bottom: 20px;
    }

    .contactSupportContainer {
        background: #ffffff;
        padding: 20px;
        border-radius: 10px;

        .contactSupportRow {
            --bs-gutter-x: 0;
            row-gap: 20px;

            .contactSupportColumn {
                padding-right: 20px;

                .contactDetailContainer {
                    border: 1px solid #D9D9D9;
                    border-radius: 10px;
                    padding: 10px;
                    font-size: 16px;
                    font-weight: 500;
                    height: 100%;

                    .contactTitle {
                        color: #EC7426;
                    }

                    .contactDetails {
                        padding: 10px;

                        .contactContentRow {
                            --bs-gutter-x: 0;
                            margin-bottom: 5px;
                            gap: 5px;

                            .contactContentValue {
                                font-weight: 400;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1399px) {
    .guidelineSupportContainer {
        .contactSupportContainer {
            .contactSupportRow {
                .contactSupportColumn {
                    padding: 0;
                }
            }
        }
    }
}

@media (max-width: 1199px) {
    .guidelineSupportContainer {
        .guidelineRow {
            .createBrandColumn {
                padding: 0;
            }
            .documentationApprovalColumn {
                padding: 0;
            }
        }
    }
}
