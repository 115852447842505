.faqsContainer {
    margin: 20px 0;

    .faqItem {
        .accordionHeader {
            padding: 10px;

            &:hover {
                cursor: pointer;
                background-color: #e9ecef;
            }
        }
        .accordionBody {
            padding: 5px;
            border-top: 1px solid #ddd;
        }
    }
}
