.mainContainer {
    font-family: "Inter";
    background-color: #f2f2f2;
    min-height: 100vh;
    padding: 20px;

    p {
        margin: 0;
        line-height: unset;
    }

    .overviewWrapper {
        --bs-gutter-x: 0;
        background-color: #FFFFFF;
        padding: 20px;
        box-shadow: 0 1px 15px 0px #0000001A;
        margin-bottom: 20px;

        .mainHeadingBtnWrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-right: 20px;
            margin-bottom: 15px;

            .mainHeading {
                font-weight: 600;
                margin: 0;
            }

            .performanceBtn {
                text-decoration: none;
                color: #41B8E4;
                font-weight: 600;
                font-size: 16px;
                display: flex;
                align-items: center;

                .trackingImg {
                    margin-left: 10px;
                }

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .filterTableWrapper {
        --bs-gutter-x: 0;
        background-color: #FFFFFF;
        box-shadow: 0px 0px 17.9px 0px #00000017;
        margin-bottom: 20px;

        .headingBtnWrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px;

            .sectionHeading {
                font-weight: 600;
                margin: 0;
                color: #008CD6;
            }
        }

        .tableWrapper {
            padding: 20px;
        }
    }
}

@media (max-width: 992px) {
    .mainContainer {
        .overviewWrapper {
            .mainHeadingBtnWrapper {
                flex-direction: column;
                align-items: normal;

                .performanceBtn {
                    justify-content: flex-end;
                }
            }
        }
    }
}
