.customModal {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    border-radius: 8px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%) !important;
    position: absolute;

    .modalHeader {
        background-color: #F2F2F2;
        padding: 16px 20px;
    }

    .modalBody {
        padding: 24px 20px;
        font-size: 16px;
        text-align: justify;

        p {
            margin: 0;
            line-height: unset;
        }
    }

    .modalFooter {
        display: flex;
        justify-content: space-between;
        padding: 16px;
        border-top: 1px solid #c6c8cb;
    }
}
