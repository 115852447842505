.heading {
    font-weight: 600;
    padding: 1rem;
    background-color: #FBBD34;
    font-size: 1.25rem;
    color: #ffffff;
}

.wrapper {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    background-color: #ffffff;
    padding: 1rem;
    border: 1px solid #D9D9D9;
}

.addProductBtnContainer {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    .add-product-btn {
        padding: .5rem 1rem;
        border: 1px solid black;
        border-radius: .5rem;
        background-color: #ffffff;
    }

    .productBtn {
        background: #41B8E4 !important;
        border: 1px solid #41B8E4 !important;
        color: #FFFFFF !important;
        font-size: 14px;
        font-weight: 400;
        padding: 10px 20px;

        &:hover {
            box-shadow: 0 8px 25px -8px #41B8E4;
        }
    }
}