.banner-container {
  display: grid;
  align-items: center;

  img {
    grid-area: 1/1;
  }

  .content {
    grid-area: 1/1;
    color: #fff;
    padding: 1rem 15rem;

    .banner-heading {
      font-size: 2.25rem;
      font-weight: 600;
      margin-bottom: 3rem;
    }

    .para {
      font-size: 2rem;
      font-weight: 500;
    }
  }
}

.wrapper {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: .3rem;
  transition: background-color .3s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: #F2F2F2;
  }

  .label {
    width: 2.4375rem;
    height: 3.6875rem;
    display: grid;
    place-items: center;
    font-size: 1.5rem;
    padding: .3rem;
    color: #fff;
  }

  .question {
    background-color: #1A2425;
  }

  .answer {
    background-color: #EC7426;
  }
}

.likes-dislikes {
  background-color: #F2F2F2;
  display: flex;
  align-items: center;
  gap: 1rem;
  display: flex;
  justify-content: center;
  padding-block: .3rem;
}

.text-orange {
  color: #EC7426;
}

.related-questions {
  border: 2px solid #f2f2f2;
}

.related-question {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  transition: 0.3s all ease-in-out;
  &:hover {
    transform: scale(1.005);
  }

  &:not(:last-child) {
    border-bottom: 2px solid #f2f2f2;
  }
}

.customer-service-button {
  all: unset;
  border: 1.41px solid #ec7426;
  border-radius: 0.44rem;
  color: #ec7426;
  font-weight: 500;
  font-size: 1.2325rem;
  padding: 0.3rem 1.5rem;
  transition: 0.3s color ease-in-out;

  &:hover {
    color: #fff;
    background-color: #ec7426;
  }

  &:focus {
    outline: 3px solid #ffd85e;
    outline-offset: 2px;
  }
}


@media (max-width: 1024px) {
  .banner-container {
    .content {
      padding: 1rem 1rem;
      
      .banner-heading {
        font-size: 1.5rem;
        font-weight: 500;
        margin-bottom: 3rem;
      }

      .para {
        font-size: 1.25rem;
        font-weight: 400;
      }
    }
  }
}

@media (max-width: 768px) {
  .banner-container {
    .content {
      padding: 1rem 1rem;
      
      .banner-heading {
        font-size: 1.5rem;
        font-weight: 500;
        margin-bottom: 1rem;
      }

      .para {
        font-size: 1.25rem;
        font-weight: 400;
      }
    }
  }
}

@media (max-width: 425px) {
  .banner-container {
    .content {
      padding: 1rem 1rem;
      
      .banner-heading {
        font-size: 1.25rem;
        font-weight: 500;
        margin-bottom: 0rem;
      }

      .para {
        font-size: 1rem;
        font-weight: 400;
      }
    }
  }
  .customer-service-button {
    all: unset;
    border: 1.41px solid #ec7426;
    border-radius: 0.44rem;
    color: #ec7426;
    font-weight: 500;
    font-size: 1rem;
    padding: 0.3rem 1.5rem;
    transition: 0.3s color ease-in-out;
  
    &:hover {
      color: #fff;
      background-color: #ec7426;
    }
  
    &:focus {
      outline: 3px solid #ffd85e;
      outline-offset: 2px;
    }
  }
}


@media (max-width: 320px) {
  .banner-container {
    .content {
      padding: 1rem;
      
      .banner-heading {
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 0rem;
      }

      .para {
        font-size: 0.75rem;
        font-weight: 400;
      }
    }
  }
}
