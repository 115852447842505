.mainContainer {
    font-family: "Inter";
    background-color: #f2f2f2;
    min-height: 100vh;
    padding: 20px;

    p {
        margin: 0;
        line-height: unset;
    }

    .squareFilterBarWrapper {
        margin-bottom: 20px;
    }
}