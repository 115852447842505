.postComposer {
    --bs-gutter-x: 0;
    background: #FFFFFF;
    box-shadow: 0px 0px 17.9px 0px #00000017;
    color: #5D5D5D;
    font-weight: 500;

    .title {
        display: flex;
        align-items: center;
        gap: 8px;
        font-weight: 600;
        padding: 20px;
        box-shadow: 0px 5px 9.5px 0px #0000001A;
    }

    .sectionHeading {
        font-weight: 500;
        margin-bottom: 10px;
    }

    .composerRow {
        --bs-gutter-x: 0;
        padding: 20px;
        border-bottom: 1px solid #D5D5D5;
        row-gap: 20px;

        .sidebarColumn {
            padding-right: 15px;

            .sidebar {
                background: rgba(240, 241, 255, 0.5803921569);
                padding: 15px;
                border-radius: 15px;
                height: 100%;
            }
        }

        .editorColumn {
            padding: 0 15px;

            .textEditor {
                margin-bottom: 60px;
                height: 180px;
            }

            .btnContainer {
                margin-bottom: 40px;

                .uploadedMediaContainer {
                    margin-top: 10px;
                }
            }

            .periodHeading {
                font-weight: 500;
                margin: 20px 0 10px;
            }
        }

        .previewColumn {
            padding-left: 15px;

            .previewSection {
                border: 3px solid #F3F3F3;
                padding: 15px;
                border-radius: 15px;
                height: 100%;

                .previewHeading {
                    font-weight: 500;
                    margin: 10px 0 20px;
                    text-align: center;
                }

                .platformTabs {
                    --bs-gutter-x: 0;
                    margin-bottom: 30px;
                    row-gap: 10px;

                    .platformTabColumn {
                        padding: 0 5px;

                        .platformBtn {
                            width: 100%;
                            border: 2px solid #F3F3F3 !important;
                        }
                    }
                }

                .previewBox {
                    width: 100%;
                    height: 160px;
                    background-color: #F0F1FF94;
                    border-radius: 10px;
                }
            }
        }
    }

    .footerActions {
        display: flex;
        padding: 20px;
        gap: 10px;

        .cancelBtn {
            padding: 6px 50px;
            border: 1px solid #7B7B7BCC !important;
            color: #464E5F;

            &:hover {
                background-color: rgba(130, 134, 139, 0.2);
                color: #464E5F;
            }
        }
    }
}

@media (max-width: 1199px) {
    .postComposer {
        .composerRow {
            .sidebarColumn {
                padding: 0;
            }

            .editorColumn {
                padding: 0;
            }

            .previewColumn {
                padding: 0;
            }
        }
    }
}
