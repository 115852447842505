.mainBrandDetailContainer {
    .backButton {
        margin-bottom: 15px;

        button {
            font-size: 16px;
            color: #D9D9D9;
            background: none;
            border: none;
            padding: 0;
            cursor: pointer;
            text-decoration: none;

            &:hover {
                color: #838383;
            }

            &:active {
                color: #838383 !important;
            }

            span {
                margin-left: 5px;
            }
        }
    }

    .sectionHeading {
        font-weight: 600;
        margin-bottom: 15px;
    }

    .brandDetailsContainer {
        padding: 20px;

        .brandDetailRow {
            --bs-gutter-x: 0;
            margin-bottom: 30px;

            .brandDetailColumn {
                padding-right: 15px;

                .detailBox {
                    background-color: #FFFFFF;
                    border-radius: 10px;
                    padding: 30px;
                    height: 100%;

                    .columnDataRow {
                        --bs-gutter-x: 0;
                        margin-bottom: 6px;
                        font-size: 16px;
                        font-weight: 400;

                        .columnName {
                            font-weight: 500;
                        }
                    }
                }
            }

            .rejectionReasonColumn {
                padding-left: 15px;

                .rejectionReasonBox {
                    background-color: #FFFFFF;
                    border-radius: 10px;
                    padding: 30px;
                    height: 100%;
                    font-size: 15px;
                    font-weight: 400;

                    .rejectionHeading {
                        font-weight: 600;
                        margin-bottom: 30px;
                    }

                    p {
                        margin: 0;
                        line-height: unset;
                        text-align: justify;
                    }

                    .rejectionReasonRow {
                        --bs-gutter-x: 0;
                        padding: 0 20px;

                        .rejectionIssueColumn {
                            padding-right: 10px;
                        }

                        .rejectionSolutionColumn {
                            padding-left: 10px;
                        }

                        .rejectionColumnHeading {
                            font-size: 16px;
                            font-weight: 600;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }

        .attachedDocumentsRow {
            --bs-gutter-x: 0;
            background-color: #FFFFFF;
            border-radius: 10px;
            padding: 30px;
            height: 100%;
            font-size: 15px;
            font-weight: 400;
            margin-bottom: 30px;

            .attachedDocumentsColumn {
                .attachedDocumentHeading {
                    font-weight: 600;
                    margin-bottom: 10px;
                }

                .documentList {
                    .documentItem {
                        --bs-gutter-x: 0;
                        display: flex;
                        align-items: center;
                        margin-bottom: 10px;

                        label {
                            font-size: 15px;
                            font-weight: 400;
                            color: #1A2425;
                            margin: 0;
                        }

                        .uploadDocumentContainer {
                            display: flex;
                            align-items: center;

                            .uploadDocumentButton {
                                background: #FBBD34 !important;
                                border: 1px solid #FBBD34 !important;
                                color: #FFFFFF !important;
                                border-radius: 8px;
                                font-size: 14px;
                                font-weight: 400;
                                padding: 8px 20px;

                                &:hover {
                                    box-shadow: 0 1px 2px 0 #FBBD34;
                                }
                            }

                            .fileNameDisplay {
                                font-size: 15px;
                                font-weight: 400;
                                margin-left: 15px;
                            }
                        }
                    }
                }
            }
        }

        .actionButtonsContainer {
            display: flex;
            justify-content: center;
            align-items: center;

            button {
                background: #41B8E4 !important;
                border: 1px solid #41B8E4 !important;
                color: #FFFFFF !important;
                font-size: 14px;
                font-weight: 400;
                padding: 8px 20px;
                margin-right: 10px;

                &:hover {
                    box-shadow: 0 8px 25px -8px #41B8E4;
                }

                &:last-child {
                    margin: 0;
                }
            }
        }
    }
}
