.feeDetailsCard {
  background-color: #D9D9D9;
  border: 1px solid #1A2425;
  width: 100%;
  margin: 0;

  .feeCategory {
    font-weight: 500;
    margin-bottom: 0rem;
  }

  .feeDetail {
    font-weight: 500;
    margin-left: 0;
    margin-bottom: .3rem;
  }
}
