.headOrange {
  background-color: #ec7426;
  color: #fff;
  font-weight: 500;
  padding: 0.5rem 0.3rem;
}

.row {
  background-color: #fff;
  padding-inline: 0.3rem;
  font-weight: 500;

  &:not(:last-child) {
    border-bottom: 1px solid #d9d9d9;
  }

  .column {
    padding-block: 0.5rem;
  }

  .column:not(:last-child) {
    border-right: 1px solid #d9d9d9;
  }
}
