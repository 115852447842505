.mainFinalizeContainer {
    font-weight: 500;

    p {
        margin: 0;
        line-height: unset;
    }

    .checkDetailsText {
        margin-bottom: 20px;
    }

    table {
        margin-bottom: 20px;

        tr {
            font-size: 20px;
            border: none;

            td {
                border: none;
                padding: 10px 20px;
                width: 50%;
                word-wrap: break-word;
            }

            .valueData {
                font-weight: 300;
            }
        }
    }

    .editBtn {
        font-size: 18px;
        text-decoration: none;
        color: #1A2425;
        padding: 0;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    .placeOrderBtnContainer {
        display: grid;
        place-items: end;

        .orderPlaceBtn {
            background-color: #41B8E4 !important;
            border: 1px solid #41B8E4;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;
            font-weight: 500;
            padding: 10px 20px;
            margin-top: 20px;

            &:hover {
                box-shadow: 0 8px 25px -8px #41B8E4;
            }
        }
    }
}

// Media Queries for responsive behavior
@media (max-width: 768px) {
    .mainFinalizeContainer {
        .checkDetailsText {
            font-size: 14px;
        }

        table {
            tr {
                font-size: 16px;

                td {
                    width: 60%;
                }
            }
        }

        .editBtn {
            font-size: 16px;
        }

        .placeOrderBtnContainer {
            .orderPlaceBtn {
                font-size: 14px;
            }
        }
    }
}
