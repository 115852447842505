.container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  gap: 30px;
  // border-bottom: 2px solid #D9D9D9;
}

.tab {
  font-weight: 600;
  cursor: pointer;
  transition: color 0.3s ease, border 0.3s ease;
  font-size: 1.25rem;
  padding: 0.5rem 0;
  color: inherit;
  border: none;

  &.bottom-bordered {
    border-bottom: 2px solid transparent;

    &.active {
      color: #EC7426;
      border-bottom: 2px solid black;
    }
  }

  &.borderless {
    &.active {
      color: #EC7426;
    }
  }
}

@media (max-width: 576px) {
  .tab {
    font-size: 1rem;
  }
}