.mainContainer {
    font-family: "Inter";
    margin-bottom: 20px;

    .mainHeading {
        font-weight: 600;
        margin-bottom: 30px;
        text-align: center;
    }

    p {
        margin: 0;
        line-height: unset;
    }

    .orderDetailWrapper {
        --bs-gutter-x: 0;
        background-color: #FFFFFF;
        box-shadow: 0px 0px 17.9px 0px #00000017;
        padding: 30px;

        .infoCardRow {
            --bs-gutter-x: 0;
            gap: 40px;
            margin-bottom: 40px;

            .InfoCardsConatainer {
                display: flex;
                gap: 20px;
                flex-direction: column;
                padding: 0 60px;
            }

            .actionBtnsContainer {
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding: 50px 180px 0 300px;

                .sectionHeading {
                    font-weight: 600;
                    margin-bottom: 20px;
                    color: #5D5D5D;
                }
            }
        }

        .dataTableRow {
            --bs-gutter-x: 0;
            margin-bottom: 30px;
            row-gap: 30px;

            .dataTableWrapper {
                background-color: #FFFFFF;
                box-shadow: 0px 2px 30px 0px #00000012;
            }

            .btnsWrapperColumn {
                display: flex;
                align-items: center;
                justify-content: center;

                .btnsContainer {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }
            }
        }
    }
}

@media (max-width: 1399px) {
    .mainContainer {
        .orderDetailWrapper {
            .infoCardRow {
                flex-direction: column;
            }
        }
    }
}

@media (min-width: 499px) and (max-width: 1199px) {
    .mainContainer {
        .orderDetailWrapper {
            .dataTableRow {
                .btnsWrapperColumn {
                    .btnsContainer {
                        flex-direction: row;
                    }
                }
            }
        }
    }
}
