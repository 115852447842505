.mainContainer {
    background: #F2F2F2;
    padding: 20px;
    font-family: Inter;
    min-height: 100vh;

    .sectionHeading {
        font-weight: 600;
        margin-bottom: 10px;
    }

    .receivedInventoryContainer {
        padding: 20px;
        background: #FFFFFF;

        .inventoryTable {
            margin-top: 20px;

            // Status colors
            .statusInProcessing {
                color: #FBBD34;
            }

            .statusReceived {
                color: #41B8E4;
            }
        }
    }
}
