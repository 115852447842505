.mainContainer {
    .sectionHeading {
        font-weight: 600;
        margin-bottom: 10px;
    }

    .sectionTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fbbd34;
        color: #ffffff;
        padding: 6px 10px;

        .sectionSubHeading {
            font-weight: 600;
            margin: 0;
        }
    }

    p {
        margin: 0;
        line-height: unset;
    }

    .tableSection {
        background: #ffffff;
        font-size: 15px;
        padding: 0;
        margin-bottom: 20px;

        .tableDataWrapper {
            --bs-gutter-x: 0;
            padding: 10px;

            .tableDataRow {
                --bs-gutter-x: 0;
                padding: 20px;
                border: 1px solid #d9d9d9;
            }
        }
    }

    .graphSection {
        background: #FFFFFF;
        padding: 20px;
        height: 300px;
    }
}
