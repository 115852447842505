.financeOverview {
  background-color: #f2f2f2;
  min-height: 100dvh;
  padding: 20px;

  .table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    margin: 0 auto;
    border-radius: 10px;
    overflow-x: auto;
    overflow: hidden;
    white-space: nowrap;

    th,
    td {
      padding: 12px 16px;
      text-align: left;
      font-weight: 500;
      border: none;
      border-right: 1px solid #D9D9D9;

      &:last-child {
        border: none;
      }
    }

    th {
      font-weight: 600;
    }

    thead {
      &.headOrange {
        th {
          background-color: #EC7426;
          color: #FFFFFF;
        }
      }

      &.headGray {
        th {
          background-color: #D9D9D9;
          color: #1A2425;
        }
      }
    }
  }
}
