.addEditBrandContainer {
    background: #f2f2f2;
    font-family: Inter;
    min-height: 100vh;
    padding: 20px;

    .sectionHeading {
        font-weight: 600;
        margin-bottom: 15px;
    }

    .sectionTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fbbd34;
        color: #ffffff;
        padding: 6px 10px;

        .sectionSubHeading {
            font-weight: 600;
            margin: 0;
        }
    }

    p {
        margin: 0;
        line-height: unset;
    }

    .addEditBrandSection {
        background: #ffffff;
        font-size: 15px;
        padding: 0;
        margin-bottom: 30px;

        .brandFormRow {
            --bs-gutter-x: 0;
            padding: 20px;
            border: 1px solid #d9d9d9;
            margin: 10px;

            .formSection {
                .formGroupRow {
                    --bs-gutter-x: 0;
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;

                    label {
                        font-size: 15px;
                        font-weight: 400;
                        color: #1A2425;
                        margin: 0;
                    }

                    input,
                    select {
                        // width: 40%;
                        border: 1px solid #1A2425;
                        border-radius: 5px;

                        &::placeholder {
                            color: #D9D9D9;
                        }
                    }

                    .fullTextInput {
                        width: 100%;
                    }

                    textarea {
                        min-height: 80px;
                        border: 1px solid #1A2425;
                        border-radius: 5px;

                        &::placeholder {
                            color: #D9D9D9;
                        }
                    }
                }
            }
        }

        .categoryProductShipment {
            --bs-gutter-x: 0;
            padding: 20px;

            .addProductContainer {
                display: flex;
                align-items: center;
                margin-bottom: 20px;

                .addProductButton {
                    background: #41B8E4 !important;
                    border: 1px solid #41B8E4 !important;
                    color: #FFFFFF !important;
                    border-radius: 8px;
                    font-size: 14px;
                    font-weight: 400;
                    padding: 8px 20px;

                    &:hover {
                        box-shadow: 0 8px 25px -8px #41B8E4;
                    }
                }
            }

            .productTable {
                th {
                    background-color: #F2F2F2;
                    font-weight: 400;
                }

                td {
                    padding: 10px 20px;
                }
            }

            .productInfo {
                display: flex;
                align-items: center;
                gap: 20px;

                .productImage {
                    width: 60px;
                    height: auto;
                    border-radius: 5px;
                }

                .productName {
                    font-size: 16px;
                    font-weight: 500;
                }

                .productSKU {
                    font-size: 11px;
                    font-weight: 500;

                    span {
                        font-weight: 400;
                    }
                }
            }
        }
    }

    .addProductBtnContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        .productBtn {
            background: #41B8E4 !important;
            border: 1px solid #41B8E4 !important;
            color: #FFFFFF !important;
            font-size: 14px;
            font-weight: 400;
            padding: 10px 20px;

            &:hover {
                box-shadow: 0 8px 25px -8px #41B8E4;
            }
        }
    }
}
