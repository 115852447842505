.mainDashboardContainer {
    background: #F2F2F2;
    padding: 20px;
    font-family: Inter;

    p {
        margin: 0;
        line-height: unset;
    }

    .header {
        padding: 0;
        margin-bottom: 30px;

        .headerRow {
            --bs-gutter-x: 0;
            row-gap: 10px;

            .storeColumn {
                padding-right: 10px;

                .storeInfo {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background: #FFFFFF;
                    padding: 10px 20px;
                    border-radius: 10px;

                    .storeDetails {
                        display: flex;
                        align-items: center;

                        .storeLogo {
                            width: 95px;
                            height: auto;
                            margin-right: 20px;
                        }

                        .storeName {
                            font-size: 20px;
                            font-weight: 700;
                        }

                        .location {
                            font-size: 15px;
                            font-weight: 500;
                            margin-bottom: 10px;
                        }

                        .followers {
                            font-size: 15px;
                            font-weight: 500;
                            margin-bottom: 10px;
                        }
                    }

                    .editBtn {
                        font-size: 14px;
                        font-weight: 500;
                        align-self: flex-start;
                        color: #1A2425;
                        text-decoration: none;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            .announcements {
                background: #FFFFFF;
                padding: 14px 20px;
                border-radius: 10px;

                h4 {
                    font-weight: 600;
                }

                p {
                    font-size: 15px;
                    font-weight: 500;
                }
            }
        }
    }

    .sectionHeading {
        font-weight: 600;
        margin-bottom: 10px;
    }

    .overviewPanel {
        padding: 0;
        background: #FFFFFF;
        border-radius: 10px;
        margin-bottom: 30px;

        .subSectionHeading {
            font-weight: 600;
            margin-bottom: 15px;
        }

        .overviewPanelRow {
            --bs-gutter-x: 0;
            border-bottom: 1px solid #D9D9D9;

            .salesSummary,
            .performanceMetrics {
                padding: 20px;

                .card {
                    border: 1px solid #D9D9D9;
                    padding: 10px;
                    border-radius: 10px;
                    text-align: center;
                    font-size: 15px;
                    font-weight: 500;

                    span {
                        display: block;
                        font-weight: 600;
                        color: #EC7426;
                    }
                }

                .imgContainer {
                    max-width: 100%;
                    height: 100%;
                    padding-left: 10px;
                    text-align: center;

                    .graphImg {
                        max-width: 100%;
                        width: auto;
                        height: 100%;
                    }
                }
            }
        }

        .recentOrdersRow {
            --bs-gutter-x: 0;
            padding: 20px;
            row-gap: 15px;

            .bulkOrderHeading {
                font-weight: 600;
                margin-bottom: 20px;
            }

            .subSectionHeading {
                font-weight: 600;
                margin-bottom: 10px;
            }

            .sidePanel {
                padding: 0;

                .fulfillmentContentRow {
                    margin-bottom: 5px;
                }
            }
        }
    }

    .insightsContainer {
        background: #FFFFFF;
        border-radius: 10px;
        padding: 0;
        margin-bottom: 30px;
        font-size: 15px;
        font-weight: 500;

        .insightSubHeading {
            font-weight: 600;
            margin-bottom: 5px;
        }

        .campaignRow {
            --bs-gutter-x: 0;
            padding: 20px;
            border-bottom: 1px solid #D9D9D9;
            row-gap: 15px;

            .campaignColumn {
                padding-right: 10px;

                .campaignStatsColumn {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 4px;
                    margin-bottom: 5px;
                    gap: 10px;

                    .campaignStatsText {
                        span {
                            color: #EC7426;
                            font-weight: 600;
                            margin-left: 4px;
                        }
                    }
                }
            }
        }

        .eventRow {
            --bs-gutter-x: 0;
            padding: 20px;
            border-bottom: 1px solid #D9D9D9;
            row-gap: 15px;

            .eventColumn {
                padding-right: 10px;

                .eventCardRow {
                    --bs-gutter-x: 0;

                    .eventImgColumn {
                        max-width: 100%;
                        height: auto;
                        padding-right: 10px;

                        .eventImg {
                            max-width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .eventContentColumn {
                        padding-left: 10px;

                        h5 {
                            margin-bottom: 5px;
                        }

                        .eventDuration {
                            font-size: 12px;
                            font-weight: 500;
                            margin-bottom: 10px;
                        }

                        .participateBtn {
                            background-color: #1A2425;
                            color: #FFFFFF;
                            padding: 5px 12px 5px 16px;
                            border: 1px solid #1A2425;
                            border-radius: 14px;
                            cursor: pointer;

                            &:hover {
                                background-color: #333;
                            }

                            .doubleArrowIcon {
                                margin-left: 6px;
                            }
                        }
                    }
                }
            }
        }

        .discountRow {
            --bs-gutter-x: 0;
            padding: 20px;
            row-gap: 15px;

            .discountColumn {
                padding-right: 10px;

                .discountInfoCard {
                    border: 1px solid #D9D9D9;
                    padding: 10px;
                    border-radius: 10px;
                    text-align: center;
                    font-size: 15px;
                    font-weight: 500;

                    span {
                        display: block;
                        font-weight: 600;
                        color: #EC7426;
                    }
                }
            }

            .promotionContainer {
                display: flex;
                flex-wrap: nowrap;
                overflow-x: auto;
                scroll-behavior: smooth;

                &.showAll {
                    justify-content: flex-start;
                }

                .discountColumn {
                    flex: 0 0 auto;
                }

                .discountButtonColumn {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .viewAllButton {
                        padding: 0;
                        background: none;
                        border: none;
                        cursor: pointer;
                        font-size: 14px;
                        font-weight: 500;
                    }
                }
            }
        }
    }

    .customerEngagementContainer {
        background: #FFFFFF;
        border-radius: 10px;
        padding: 0;
        margin-bottom: 30px;
        font-size: 15px;
        font-weight: 500;

        .customerEngagementSubHeading {
            font-weight: 600;
            margin-bottom: 15px;
        }

        .recentReviewHeading {
            font-weight: 600;
            margin-bottom: 5px;
        }

        .customerEngagementRow {
            --bs-gutter-x: 0;
            padding: 20px;
            border-bottom: 1px solid #D9D9D9;
            row-gap: 20px;

            .reviewColumn {
                .progressContainer {
                    margin-right: 20px;
                }
            }

            .recentReviewColumn {
                .reviewBoxRow {
                    --bs-gutter-x: 0;
                    gap: 10px;

                    .reviewContentColumn {
                        border: 1px solid #D9D9D9;
                        border-radius: 10px;
                        padding: 15px;

                        .recentReviewHeader {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-bottom: 20px;

                            .reviewerName {
                                font-weight: 600;

                                span {
                                    color: #D9D9D9;
                                    font-size: 12px;
                                    font-weight: 500;
                                    margin-left: 4px;
                                }
                            }

                            .reviewerRating {
                                display: flex;
                                align-items: center;

                                span {
                                    font-size: 16px;
                                    font-weight: 500;
                                    margin-left: 5px;
                                }
                            }
                        }

                        .reviewText {
                            font-size: 14px;
                            font-weight: 400;
                        }

                        .replyBtnContainer {
                            display: flex;
                            justify-content: flex-end;

                            .replyBtn {
                                font-size: 14px;
                                font-weight: 500;
                                align-self: flex-start;
                                color: #1A2425;
                                text-decoration: none;
                                padding: 0;

                                &:hover {
                                    text-decoration: underline;
                                }

                                .replyBackArrow {
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .loyaltyProgramRow {
            --bs-gutter-x: 0;
            padding: 20px;
            border-bottom: 1px solid #D9D9D9;

            .loyaltyContentRow {
                display: flex;
                flex-wrap: nowrap;
                overflow-x: auto;
                scroll-behavior: smooth;

                .loyaltyColumn {
                    padding-right: 10px;

                    .loyaltyInfoCard {
                        border: 1px solid #D9D9D9;
                        padding: 10px;
                        border-radius: 10px;
                        text-align: center;
                        font-size: 15px;
                        font-weight: 500;

                        span {
                            display: block;
                            font-weight: 600;
                            color: #EC7426;
                        }
                    }
                }
            }

        }

        .socialMediaRow {
            --bs-gutter-x: 0;
            padding: 20px;

            .socialContentRow {
                display: flex;
                flex-wrap: nowrap;
                overflow-x: auto;
                scroll-behavior: smooth;
            }

            .socialMediaColumn {
                padding-right: 10px;
                text-align: center;

                .mediaImagesRow {
                    --bs-gutter-x: 0;
                    margin-bottom: 10px;

                    .platformImgColumn {
                        padding-right: 10px;

                        .platformImgContainer {
                            max-width: 100%;
                            height: 100%;

                            .platformImg {
                                max-width: 100%;
                                width: auto;
                                height: 100%;
                            }
                        }
                    }

                    .likesCommentsColumn {
                        padding-left: 10px;

                        .likesCommentsRow {
                            --bs-gutter-x: 0;
                            border: 1px solid #D9D9D9;
                            border-radius: 10px;

                            .iconsContainer {
                                color: #FFFFFF;
                                background: #FBBD34;
                                border-radius: 8px 0 0 8px;
                                padding: 5px;
                            }

                            .iconsValueContainer {
                                padding: 5px;
                            }
                        }
                    }
                }

                .followersRow {
                    --bs-gutter-x: 0;

                    .loyaltyColumn {
                        .loyaltyInfoCard {
                            border: 1px solid #D9D9D9;
                            padding: 10px;
                            border-radius: 10px;
                            text-align: center;
                            font-size: 15px;
                            font-weight: 500;

                            span {
                                display: block;
                                font-weight: 600;
                                color: #EC7426;
                            }
                        }
                    }
                }
            }
        }
    }

    .productPerformanceContainer {
        background: #FFFFFF;
        border-radius: 10px;
        padding: 0;
        margin-bottom: 30px;
        font-size: 15px;
        font-weight: 500;

        .productStatusSubHeading {
            font-weight: 600;
            margin-bottom: 15px;
        }

        .productStatusRow {
            --bs-gutter-x: 0;
            padding: 20px;
            border-bottom: 1px solid #D9D9D9;

            .productStatusColumn {
                padding-right: 10px;

                .statusInfoCard {
                    border: 1px solid #D9D9D9;
                    padding: 10px;
                    border-radius: 10px;
                    text-align: center;
                    font-size: 15px;
                    font-weight: 500;

                    span {
                        display: block;
                        font-weight: 600;
                        color: #EC7426;
                    }
                }
            }

            .productStatusContainer {
                display: flex;
                flex-wrap: nowrap;
                overflow-x: auto;
                scroll-behavior: smooth;

                &.showAllProduct {
                    justify-content: flex-start;
                }

                .productStatusColumn {
                    flex: 0 0 auto;
                }

                .productStatusButtonColumn {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .viewAllProductButton {
                        padding: 0;
                        background: none;
                        border: none;
                        cursor: pointer;
                        font-size: 14px;
                        font-weight: 500;
                    }
                }
            }
        }

        .topSellingRow {
            --bs-gutter-x: 0;
            padding: 20px;

            .topSellingColumn {
                padding-right: 10px;

                .sellingProductsRow {
                    --bs-gutter-x: 0;
                    margin-bottom: 10px;

                    .sellingProductFirstColumn {
                        .productImgContainer {
                            max-width: 100%;
                            height: 100%;
                            text-align: center;

                            .productImg {
                                max-width: 100%;
                                width: auto;
                                height: 100%;
                            }
                        }
                    }

                    .sellingProductSecondColumn {
                        align-content: center;

                        .productAmount {
                            color: #EC7426;
                        }

                        .productQuantity {
                            font-size: 12px;
                        }
                    }

                    .sellingProductThirdColumn {
                        text-align: center;
                        align-content: center;

                        .productLabelRow {
                            --bs-gutter-x: 0;
                            background: #FBBD34;
                            color: #ffffff;
                            font-weight: 400;
                            border: 1px solid #D9D9D9;
                            border-bottom: none;
                        }

                        .productValueRow {
                            --bs-gutter-x: 0;
                            border: 1px solid #D9D9D9;
                            border-top: none;
                        }
                    }

                    .sellingProductFourthColumn {
                        padding-left: 10px;
                        align-content: center;
                    }
                }
            }
        }
    }

    .financialOverviewContainer {
        background: #FFFFFF;
        border-radius: 10px;
        padding: 20px;
        margin-bottom: 30px;
        font-size: 15px;
        font-weight: 500;

        .financialRow {
            --bs-gutter-x: 0;
            row-gap: 15px;

            .financialColumn {
                padding-right: 10px;

                .financialHeading {
                    margin: 0;
                    margin-bottom: 5px;
                    line-height: unset;
                    font-size: 15px;
                    font-weight: 600;
                }

                .financialStatsRow {
                    --bs-gutter-x: 0;
                    gap: 10px;

                    .financialInfoCard {
                        border: 1px solid #D9D9D9;
                        padding: 10px;
                        border-radius: 10px;
                        text-align: center;
                        font-size: 15px;
                        font-weight: 500;
                        position: relative;

                        span {
                            display: block;
                            font-weight: 600;
                            color: #EC7426;
                        }

                        .cardPercentage {
                            position: absolute;
                            margin: 0;
                            line-height: unset;
                            top: 4px;
                            right: 6px;
                            color: #41B8E4;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }

    .resourcesContainer {
        background: #FFFFFF;
        border-radius: 10px;
        padding: 20px;
        margin-bottom: 10px;
        font-size: 15px;
        font-weight: 500;

        .resourcesRow {
            margin: 0;
            row-gap: 20px;
            margin-bottom: 20px;
            flex-wrap: nowrap;
            overflow-x: auto;
            scroll-behavior: smooth;

            .resourcesColumns {
                display: flex;
                justify-content: center;
                padding: 0;
                padding-right: 10px;

                .resourcesWrapper {
                    border: 1px solid #D9D9D9;
                    width: 100%;
                    text-align: center;
                    border-radius: 10px;

                    .imageContainer {
                        width: 100%;
                        max-width: 100%;
                        height: 180px;
                        overflow: hidden;

                        .resourceImg {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            max-height: 100%;
                            border-radius: 10px 10px 0 0;
                        }
                    }

                    .resourceText {
                        color: #1A2425;
                        font-size: 15px;
                        font-weight: 600;
                        margin: 0;
                        padding: 15px;
                    }
                }
            }
        }

        .maelanoUniHeading {
            background: #FBBD34;
            color: #FFFFFF;
            padding: 10px 20px;
            font-weight: 600;
            margin-bottom: 20px;
        }

        .maelanoUniRow {
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
            overflow-x: auto;
            scroll-behavior: smooth;
            margin: 0;
            gap: 40px;

            .maelanoUniCard {
                padding: 0;

                .cardContainer {
                    background-color: #FFFFFF;
                    box-shadow: 3px 3px 6px #00000040;
                    height: 100%;
                    min-width: 400px;

                    img {
                        width: 100%;
                        max-width: 100%;
                        height: auto;
                    }

                    .cardContent {
                        padding: 10px;

                        .cardHeading {
                            font-weight: 600;
                        }

                        .cardTitle {
                            font-weight: 600;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}

// Responsive Behavior - Media Queries
@media (max-width: 991px) {
    .mainDashboardContainer {
        .header {
            .headerRow {
                .storeColumn {
                    padding: 0;
                }
            }
        }

        .overviewPanel {
            .overviewPanelRow {
                .performanceMetrics {
                    .imgContainer {
                        padding: 0;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .mainDashboardContainer {
        .insightsContainer {
            .eventRow {
                .eventColumn {
                    .eventCardRow {
                        .eventImgColumn {
                            padding: 0;
                        }

                        .eventContentColumn {
                            padding: 0;
                            padding-top: 10px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 575px) {
    .mainDashboardContainer {
        .resourcesContainer {
            .maelanoUniRow {
                .maelanoUniCard {
                    .cardContainer {
                        min-width: 100%;
                    }
                }
            }
        }
    }
}
