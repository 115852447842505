.heading {
  background: #fbbd34;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  padding: 10px 20px;
}

@media (max-width: 1024px) {
  .heading {
    font-weight: 300;
    font-size: 14px;
    padding: 10px 4px;
  }
}
