.pageCard {
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: #F2F2F2;
  border: 1px solid;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.borderDanger {
  border-color: red !important;
}

.borderGray {
  border-color: #D9D9D9 !important;
}

.pageCardBtn {
  all: unset;
  background-color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid #6c757d;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #e9ecef;
  }
}

.online {
  color: #41B8E4;
}

.offline {
  color: #EC7426;
}
