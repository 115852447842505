.mainContainer {
    background: #f2f2f2;
    padding: 20px;
    font-family: Inter;
    min-height: 100vh;
    font-size: 15px;
    font-weight: 500;

    .mainHeading {
        font-weight: 600;
        margin-bottom: 20px;
    }

    p {
        margin: 0;
        line-height: unset;
    }

    .sectionHeading {
        font-weight: 600;
        margin-bottom: 10px;
    }

    .headerText {
        background: #EC7426;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 500;
        border-radius: 10px;
        padding: 10px 20px;
        margin-bottom: 20px;
    }

    .contentRow {
        --bs-gutter-x: 0;
        gap: 40px;
        margin-bottom: 20px;

        .contentColumn {
            display: flex;
            flex-direction: column;

            .contentDataRow {
                --bs-gutter-x: 0;
                background: #FFFFFF;
                padding: 20px;
                border-radius: 10px;
                row-gap: 10px;
                flex: 1;

                .personalizedContainer {
                    padding: 10px;
                    margin-right: 10px;
                    cursor: pointer;

                    &:hover {
                        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
                        border-radius: 10px;
                    }

                    .personalizedValue {
                        color: #EC7426;
                        font-weight: 600;
                    }
                }

                .advisoryContainer {
                    padding-right: 10px;

                    .personalizedValue {
                        color: #EC7426;
                        font-weight: 600;
                    }

                    .trendContent {
                        font-weight: 400;
                    }
                }

                .competitorSectionHeading {
                    font-weight: 600;
                    margin: 0;
                }

                .competitorSectionSecondHeading {
                    font-weight: 600;
                    margin: 0;
                    margin-top: 10px;
                }

                .pricingContainer {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;

                    h5 {
                        margin: 0;
                        font-weight: 600;
                    }

                    .pricingHeadingGuide {
                        color: #EC7426;
                        font-weight: 400;
                        margin-left: 40px;
                    }
                }
            }
        }
    }
}
