.footer-wrapper {
  background-color: #1a2425;
  padding: 4.9rem;
  padding-bottom: 1rem;
  color: white;
}

.ul > li {
  font-size: 0.875rem;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  &:hover {
    color: #d9d9d9;
    transform: scale(1.005);
  }
}

.action-buttons-wrapper {
  max-width: 151px;
}

.icon {
  aspect-ratio: 1/1;
  width: 28px;
}

.download-action-button {
  border: 1px solid #d9d9d9;
  border-radius: 2.5rem;
  padding: 0.3rem 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.text-xs {
  font-size: 0.625rem;
  color: #f2f2f2;
}

.text-sm {
  font-size: 0.875rem;
  font-weight: 600;
}

.rights-reserved {
  background-color: #0f1617;
  color: #fff;
  padding: 1rem 4.9rem;
}

@media (max-width: 768px) {
  .footer-wrapper {
    padding: 1rem;
    padding-top: 3rem;
  }

  .rights-reserved {
    padding: 1rem;
  }
}
