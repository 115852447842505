.chart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1600px; // Prevents excessive stretching on large screens
    margin: auto;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

    .recharts-cartesian-grid {
        stroke: #ccc;
        stroke-dasharray: 4 4; // Dotted horizontal lines only
    }
}

// Responsive adjustments
@media (max-width: 768px) {
    .chart-container {
        padding: 10px;
    }
}

// .donut-container {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     width: 100%;
//     max-width: 400px;
//     margin: auto;

//     svg {
//         border-radius: 50%;
//     }
// }

.donutContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; 
    max-width: 400px; // Prevents it from becoming too large
    height: auto;
  }
  
  @media (max-width: 768px) {
    .donutContainer {
      max-width: 300px; // Smaller screens get a smaller chart
    }
  }
  
  @media (max-width: 480px) {
    .donutContainer {
      max-width: 200px; // Further reduced size for mobile
    }
  }


.statCard {
    background: #fff;
    border-radius: 12px;
    padding: 15px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08); // Soft shadow
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 180px;
    min-height: 100px;

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.12); // More prominent shadow on hover
    }
}

.selected {
    border: 2px solid #007bff; // Blue border for selected card
    box-shadow: 0px 6px 15px rgba(0, 123, 255, 0.2); // Highlighted shadow for selection
}

.header {
    font-size: 14px;
    color: #8E95A9; // Gray color
}

.content {
    display: flex;
    align-items: center;
    gap: 25%;
}

.value {
    font-size: 24px;
    font-weight: bold;
    color: #0d1b2a;
}

.chart {
    width: 50px;
    height: 40px;
}

.titleValue {
    font-size: 12px;
    font-weight: 700;
    color: #279F51;
}
.titleValue1 {
    font-size: 12px;
    font-weight: 700;
    color: #EC7426;
}
.img{
    margin-right: 100px;
}

.progress-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  svg {
    filter: drop-shadow(0px 6px 8px rgba(0, 0, 0, 0.1)); // Soft glow effect
  }
  
  .percentage-text {
    font-size: 24px; // Increased size
    font-weight: bold;
    fill: #0d1b2a;
  }
  
  