.mainContainer {
    background: #f2f2f2;
    padding: 20px;
    font-family: Inter;
    min-height: 100vh;
    font-size: 15px;

    .mainHeading {
        font-weight: 600;
        margin-bottom: 20px;
    }

    p {
        margin: 0;
        line-height: unset;
    }

    .sectionHeading {
        font-weight: 600;
        margin-bottom: 10px;
    }

    .headerText {
        background: #EC7426;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 500;
        border-radius: 10px;
        padding: 10px 20px;
        margin-bottom: 20px;
    }

    .scoreIndicatorRow {
        --bs-gutter-x: 0;
        margin-bottom: 20px;

        .table {
            padding-right: 10px;

            @media (max-width: 992px) {
                padding-right: 0px;
            }

            .status {
                display: inline-block;
                width: 16px;
                height: 16px;
                border-radius: 50%;
            }
        }

        .progressWrapper {
            margin-left: 10px;
            background: #FFFFFF;
            padding: 30px;
            border: 1px solid #1A2425;
            border-radius: 10px;
            height: 100%;

            @media (max-width: 992px) {
            margin-left: 0px;
                margin-top: 8px;
            }

            .progressBar {
                position: relative;
                background-color: #F2F2F2;
                width: 96%;
                height: 10px;
                border-radius: 5px;
                margin: 10px auto;

                .progress {
                    height: 100%;
                    transition: width 1s ease;
                    border-radius: 5px;

                    &.good {
                        background-color: #54D85A;
                    }

                    &.atRisk {
                        background-color: #FBBD34;
                    }

                    &.suspend {
                        background-color: #F90000;
                    }
                }


                .statusPoints {
                    .point {
                        position: absolute;
                        top: -4px;
                        width: 18px;
                        height: 18px;
                        border-radius: 50%;
                        transition: transform 0.3s;

                        &.goodPoint {
                            background-color: #54D85A;
                        }

                        &.atRiskPoint {
                            background-color: #FBBD34;
                        }

                        &.suspendPoint {
                            background-color: #F90000;
                        }
                    }
                }
            }

            .legend {
                display: flex;
                justify-content: space-between;

                .label {
                    color: #ccc;
                    font-weight: 500;

                    &.active {
                        color: #1A2425;
                    }
                }
            }

            .message {
                font-size: 15px;
                font-weight: 500;
                margin-top: 40px;

                .emoji {
                    font-size: 30px;
                    margin-top: 5px;
                }
            }
        }
    }

    // Different Status Stylings
    .statusGood {
        background-color: #54D85A;
    }

    .statusAtRisk {
        background-color: #FBBD34;
    }

    .statusBad {
        background-color: #F90000;
    }

    .statusPending {
        color: #EC7426;
    }

    .statusResolved {
        color: #41B8E4;
    }

    .mainTableContainer {
        background: #ffffff;
        padding: 10px;
        margin-bottom: 20px;

        .dataTableContainer {
            border: 1px solid #D9D9D9;
            padding: 0px 20px 20px;
        }
    }

    .listContainer {
        background: #ffffff;
        padding: 10px;
        font-size: 16px;
        font-weight: 500;

        .orderContent {
            border: 1px solid #D9D9D9;
            padding: 20px;

            ul {
                margin: 0;
                margin-top: 5px;

                li {
                    line-height: 1.8;

                    span {
                        color: #EC7426;
                    }
                }
            }
        }
    }
}
