.mainContainer {
  .sectionHeading {
    font-weight: 600;
    margin-bottom: 16px;
  }

  .heading {
    background-color: #FBBD34;
    color: #FFFFFF;
    font-size: 1.25rem;
    font-weight: 600;
    padding: 6px 10px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
    border: 1px solid #D9D9D9;
  }
}
