.addProductContainer {
  background: #f2f2f2;
  padding: 20px;
  font-family: Inter;
  min-height: 100vh;

  .sectionHeading {
    font-weight: 600;
    margin-bottom: 10px;
  }

  p {
    margin: 0;
    line-height: unset;
  }

  .sectionTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fbbd34;
    color: #ffffff;
    padding: 6px 10px;
    border-radius: 10px 10px 0 0;

    .sectionSubHeading {
      font-weight: 600;
      margin: 0;
    }

    .saveButton {
      background: #ffffff !important;
      color: #1a2425 !important;
      border: 1px solid #1a2425;
      border-radius: 10px;
      padding: 5px 15px;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .productImgVideoSection {
    padding: 0;
    font-size: 15px;

    .productImgVideoRow {
      --bs-gutter-x: 0;

      .productImgVideoColumn {
        .headingContainer {
          background: #ffffff;
          border-radius: 10px;
          padding: 20px;
          margin-bottom: 10px;
          text-align: center;

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .helpSection {
            padding: 10px;
            margin-top: 5px;
            background: #ec7426;
            border-radius: 30px;

            .helptext {
              // background: #ec7426;
              color: #ffffff;
              padding: 10px 20px;
              border-radius: 30px;

              .helpImg {
                margin-right: 10px;
                margin-bottom: 2px;
              }
            }
          }
        }

        .productImgVideoContainer {
          background: #ffffff;
          border-radius: 10px;
          padding: 0;
          margin-bottom: 10px;

          .imgVideoWrapper {
            padding: 10px;

            .imgVideoRow {
              --bs-gutter-x: 0;
              padding: 20px;
              border: 1px solid #d9d9d9;

              .imgQualityColumn {
                .imageQuality {
                  color: #ec7426;
                  font-size: 14px;
                  padding: 15px;

                  p {
                    margin-bottom: 10px;

                    .pandaImg {
                      margin-right: 8px;
                    }

                    .checkImg {
                      margin: 0 0 4px 8px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .stepsSidebarColumn {
        padding-left: 10px;

        // @media (max-width: 992px) {
        //     padding-left: 0px;
        // }

        .stepsSidebar {
          padding: 0;

          .stepsHeader {
            background-color: #ec7426;
            color: #ffffff;
            padding: 10px;
            font-weight: 600;
            border-radius: 10px 10px 0 0;
          }

          .stepsWrapper {
            position: relative;

            .verticalLine {
              position: absolute;
              left: 19px;
              top: 0;
              bottom: 0;
              width: 4px;
              background-color: #d9d9d9;
              border-radius: 20px;
              margin: 10px 0;
              z-index: 1;
            }

            .stepItem {
              display: flex;
              align-items: center;
              padding: 6px 15px;
              border: none;
              color: #324647;
              position: relative;
              cursor: pointer;
              border-radius: 0;

              &:hover {
                background-color: #f0f0f0;
              }

              &.active {
                font-weight: 700;
                color: #1a2425;

                .stepIndicator {
                  background-color: #fbbd34;
                }
              }

              &:first-child {
                padding-top: 10px;
              }

              &:last-child {
                padding-bottom: 10px;
                border-radius: 0 0 10px 10px;
              }
            }

            .stepIndicator {
              width: 13px;
              height: 13px;
              background-color: transparent;
              border-radius: 50%;
              margin-right: 10px;
              z-index: 3;
              transition: background-color 0.3s;
            }
          }
        }
      }
    }
  }

  .guidelineColumn {
    padding-left: 10px;

    // @media (max-width: 992px) {
    //     padding-left: 0px;
    // }

    .guidelineContainer {
      padding: 0;
      border-radius: 10px;
      background: #ffffff;
      margin-bottom: 10px;

      .guidelineHeader {
        background-color: #41b8e4;
        color: #ffffff;
        padding: 10px;
        font-weight: 600;
        border-radius: 10px 10px 0 0;
      }

      .guidelineText {
        padding: 10px;
        text-align: justify;
      }
    }
  }

  .productInfoSection {
    padding: 0;
    font-size: 15px;

    .productInfoRow {
      --bs-gutter-x: 0;

      .productInfoColumn {
        .productInfoContainer {
          background: #ffffff;
          border-radius: 10px;
          padding: 0;
          margin-bottom: 10px;

          .infoFormWrapper {
            padding: 10px;

            .infoFormRow {
              --bs-gutter-x: 0;
              padding: 20px;
              border: 1px solid #d9d9d9;

              .formSection {
                .formGroup {
                  display: flex;
                  align-items: center;
                  gap: 0.5rem;

                  // @media (max-width: 992px) {
                  //   flex-direction: column;
                  // align-items: start;
                  // }

                  label {
                    font-size: 15px;
                    font-weight: 400;
                    color: #1a2425;
                    margin: 0;
                    // width: 11%;

                    // @media (max-width: 992px) {
                    //     width: 100%;

                    //   }
                  }

                  input,
                  select {
                    width: 50%;
                    border: 1px solid #1a2425;
                    border-radius: 5px;
                    // @media (max-width: 992px) {
                    //     width: 100%;

                    //   }
                    &::placeholder {
                      color: #d9d9d9;
                    }
                  }
                }

                .formGroupHalfColumn {
                  display: flex;
                  align-items: center;
                  margin: 0 !important;
                  gap: .5rem;

                  label {
                    font-size: 15px;
                    font-weight: 400;
                    color: #1a2425;
                    margin: 0;
                    // width: 22%;
                  }

                  .skuLabel {
                    // width: 14%;
                  }

                  input,
                  select {
                    width: 60%;
                    border: 1px solid #1a2425;
                    border-radius: 5px;

                    &::placeholder {
                      color: #d9d9d9;
                    }
                  }
                }

                .suggestionText {
                  font-size: 12px;
                  color: #ec7426;
                  font-weight: 400;
                  margin-left: 10px;

                  // @media (max-width: 992px) {
                  //     margin-left: 0px;
                  //     margin-top: 8px;
                  //   }

                  .pandaImg {
                    margin: 0 6px 1px 0;
                  }
                }

                .validationText {
                  font-size: 12px;
                  color: #ec7426;
                  font-weight: 400;
                  margin-left: 10px;

                  .checkImg {
                    margin: 0 0 2px 4px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .productDetailSection {
    padding: 0;
    font-size: 15px;

    .productDetailRow {
      --bs-gutter-x: 0;

      .productDetailColumn {
        .productDetailContainer {
          background: #ffffff;
          border-radius: 10px;
          padding: 0;
          margin-bottom: 10px;

          .detailFormWrapper {
            padding: 10px;

            .detailFormRow {
              --bs-gutter-x: 0;
              padding: 20px;
              border: 1px solid #d9d9d9;

              .codeEditorRow {
                --bs-gutter-x: 0;
                margin-bottom: 20px;

                .detailsColumn {
                  p {
                    font-weight: 600;
                  }
                }

                .codeEditorSection {
                  .codeEditor {
                    height: 100%;
                    padding-bottom: 50px;
                  }
                }
              }

              .aiHighlightsRow {
                --bs-gutter-x: 0;

                label {
                  font-size: 15px;
                  font-weight: 600;
                  color: #1a2425;
                  margin-bottom: 10px;
                }

                .aiHighlightInputRow {
                  --bs-gutter-x: 0;
                  border: 1px solid #1a2425;
                  border-radius: 5px;

                  .textareaInputColumn {
                    textarea {
                      border: none;
                      border-right: 1px solid #d9d9d9;
                      border-radius: 10px 0 0 10px;
                      min-height: 200px;
                      max-height: 200px;
                    }
                  }

                  .buttonsColumn {
                    .aiActionButtonsContainer {
                      padding: 10px;
                      display: flex;
                      flex-direction: column;
                      align-items: flex-end;

                      .generateBtn {
                        background: #41b8e4 !important;
                        border: 1px solid #41b8e4 !important;
                        color: #ffffff !important;
                        font-size: 16px;
                        font-weight: 400;
                        padding: 10px 20px;
                        margin-bottom: 6px;

                        &:hover {
                          box-shadow: 0 8px 25px -8px #41b8e4;
                        }
                      }

                      .otherBtn {
                        background: none !important;
                        border: none;
                        color: #1a2425 !important;
                        font-size: 14px;

                        &:hover {
                          box-shadow: none;
                          text-decoration: underline;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .productSpecsSection {
    padding: 0;
    font-size: 15px;

    .productSpecsRow {
      --bs-gutter-x: 0;

      .productSpecsColumn {
        .productSpecsContainer {
          background: #ffffff;
          border-radius: 10px;
          padding: 0;
          margin-bottom: 10px;

          .specsFormWrapper {
            padding: 10px;

            .specsFormRow {
              --bs-gutter-x: 0;
              padding: 20px;
              border: 1px solid #d9d9d9;

              .formGroup {
                display: flex;
                align-items: center;

                gap: 0.5rem;

                label {
                  font-size: 15px;
                  font-weight: 400;
                  color: #1a2425;
                  margin: 0;
                  // min-width: 10%;
                }

                .specificationLabel {
                  min-width: 15%;
                }

                input {
                  width: 30%;
                  border: 1px solid #1a2425;
                  border-radius: 5px;

                  // @media (max-width: 992px) {
                  //     width: 100%;
                  // }

                  &::placeholder {
                    color: #d9d9d9;
                  }
                }

                .dimensionInput {
                  width: 25%;
                  margin-right: 10px;
                }

                .sizeButton {
                  background: #41b8e4 !important;
                  border: 1px solid #41b8e4 !important;
                  color: #ffffff !important;
                  font-size: 14px;
                  font-weight: 400;
                  padding: 8px 20px;

                  &:hover {
                    box-shadow: 0 8px 25px -8px #41b8e4;
                  }
                }

                .suggestionText {
                  font-size: 12px;
                  color: #ec7426;
                  font-weight: 400;
                  margin-left: 10px;

                  @media (max-width: 992px) {
                    margin-left: 0px;
                    margin-top: 8px;
                  }

                  .pandaImg {
                    margin: 0 6px 1px 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .priceInventoryFormRow {
    --bs-gutter-x: 0;
    padding: 20px;
    border: 1px solid #d9d9d9;

    .formGroup {
      display: flex;
      align-items: center;

      @media (max-width: 992px) {
        flex-direction: column;
        align-items: start;
      }

      label {
        font-size: 15px;
        font-weight: 400;
        color: #1a2425;
        margin: 0;
        min-width: 13%;

        @media (max-width: 992px) {
          width: 100%;
        }
      }

      input {
        width: 30%;
        border: 1px solid #1a2425;
        border-radius: 5px;

        @media (max-width: 992px) {
          width: 100%;
        }

        &::placeholder {
          color: #d9d9d9;
        }
      }
    }

    .formGroupHalfColumn {
      display: flex;
      align-items: center;
      margin: 0 !important;

      label {
        font-size: 15px;
        font-weight: 400;
        color: #1a2425;
        margin: 0;
        width: 30%;
      }

      input {
        width: 60%;
        border: 1px solid #1a2425;
        border-radius: 5px;

        @media (max-width: 992px) {
          width: 100%;
        }

        &::placeholder {
          color: #d9d9d9;
        }
      }
    }

    .suggestionText {
      font-size: 12px;
      color: #ec7426;
      font-weight: 400;
      margin-left: 10px;
      @media (max-width: 992px) {
        margin-left: 0px;
        margin-top: 8px;
      }
      .pandaImg {
        margin: 0 6px 1px 0;
      }
    }
  }

  .shippingFormRow {
    --bs-gutter-x: 0;
    padding: 20px;
    border: 1px solid #d9d9d9;

    .formGroup {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      @media (max-width: 992px) {
        flex-direction: column;
        align-items: start;
      }

      label {
        font-size: 15px;
        font-weight: 400;
        color: #1a2425;
        margin: 0;
        // min-width: 20%;
        // max-width: 20%;
      }

      input,
      select {
        width: 30%;
        border: 1px solid #1a2425;
        border-radius: 5px;

        &::placeholder {
          color: #d9d9d9;
        }
      }

      select {
        color: #d9d9d9;

        option,
        &:focus {
          color: #1a2425;
        }
      }

      .dimensionInput {
        width: 25%;
        margin-right: 10px;

        @media (max-width: 992px) {
          width: 100%;
          &:not(:first-child) {
            margin-top: 6px;
          }
        }
      }

      .suggestionText {
        font-size: 12px;
        color: #ec7426;
        font-weight: 400;
        margin-left: 10px;

        @media (max-width: 992px) {
          margin-left: 0px;
          margin-top: 8px;
        }

        .pandaImg {
          margin: 0 6px 1px 0;
        }
      }
    }
  }

  .complianceFormWrapper {
    padding: 10px;

    .complianceFormRow {
      --bs-gutter-x: 0;
      padding: 20px;
      border: 1px solid #d9d9d9;

      .formGroup {
        display: flex;
        align-items: center;
        gap: .5rem;

        @media (max-width: 992px) {
          flex-direction: column;
          align-items: start;
        }

        label {
          font-size: 15px;
          font-weight: 400;
          color: #1a2425;
          margin: 0;

          // @media (max-width: 992px) {
          //   min-width: 35%;
          // }
        }

        .fileNameDisplay {
          margin-left: 10px;
          color: #1a2425;
        }

        input {
          width: 60%;
          border: 1px solid #1a2425;
          border-radius: 5px;

          @media (max-width: 992px) {
            width: 100%;
          }

          &::placeholder {
            color: #d9d9d9;
          }
        }

        .warrantyInput {
          width: 30%;
          margin-right: 10px;

          @media (max-width: 992px) {
            width: 100%;
          }
        }

        .addCertificateButton {
          background: #41b8e4 !important;
          border: 1px solid #41b8e4 !important;
          color: #ffffff !important;
          font-size: 14px;
          font-weight: 400;
          padding: 8px 20px;

          &:hover {
            box-shadow: 0 8px 25px -8px #41b8e4;
          }
        }

        .suggestionText {
          font-size: 12px;
          color: #ec7426;
          font-weight: 400;
          margin-left: 10px;

          @media (max-width: 992px) {
            margin-left: 0px;
            margin-top: 8px;
          }

          .pandaImg {
            margin: 0 6px 1px 0;
          }

          .checkImg {
            margin: 0 0 2px 4px;
          }
        }
      }
    }
  }

  .seoKeywordsFormWrapper {
    padding: 10px;

    .seoKeywordsFormRow {
      --bs-gutter-x: 0;
      padding: 20px;
      border: 1px solid #d9d9d9;

      .formGroup {
        display: flex;
        align-items: center;
        gap: .5rem;

        @media (max-width: 992px) {
          flex-direction: column;
          align-items: start;
        }

        label {
          font-size: 15px;
          font-weight: 400;
          color: #1a2425;
          margin: 0;
          // min-width: 30%;
        }

        input {
          width: 60%;
          border: 1px solid #1a2425;
          border-radius: 5px;

          @media (max-width: 992px) {
            width: 100%;
          }

          &::placeholder {
            color: #d9d9d9;
          }
        }

        .suggestionText {
          font-size: 12px;
          color: #ec7426;
          font-weight: 400;
          margin-left: 10px;

          @media (max-width: 992px) {
            margin-left: 0px;
            margin-top: 8px;
          }
          .pandaImg {
            margin: 0 6px 1px 0;
          }
        }
      }
    }
  }

  .additionalFormWrapper {
    padding: 10px;

    .additionalFormRow {
      --bs-gutter-x: 0;
      padding: 20px;
      border: 1px solid #d9d9d9;

      .formGroup {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        label {
          font-size: 15px;
          font-weight: 400;
          color: #1a2425;
          margin: 0;
          // min-width: 18%;
          line-height: normal;
        }

        .checkMarkGroup {
          display: flex;
          align-items: center;

          label {
            min-width: auto;
            margin-right: 10px;
            line-height: normal;
          }

          input {
            border: 1px solid #1a2425;
            border-radius: 5px;
            margin: 0;
            padding: 9px 12px;

            &::placeholder {
              color: #d9d9d9;
            }
          }
        }
      }
    }
  }

  .productBtnContainer {
    padding: 0;
    font-size: 15px;

    .addProductBtnContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;

      .productBtn {
        background: #41b8e4 !important;
        border: 1px solid #41b8e4 !important;
        color: #ffffff !important;
        font-size: 14px;
        font-weight: 400;
        padding: 10px 20px;

        &:hover {
          box-shadow: 0 8px 25px -8px #41b8e4;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .addProductContainer {
    .productImgVideoSection .productImgVideoRow .stepsSidebarColumn {
      padding-left: 0;
      margin-bottom: 1rem;
    }

    .guidelineColumn {
      padding-left: 0;
    }

    .productInfoSection .productInfoRow .productInfoColumn .productInfoContainer .infoFormWrapper .infoFormRow .formSection {
      .formGroup {
        flex-direction: column;
        align-items: start;

        label {
          width: 100%;
        }

        input,
        select {
          width: 100%;
        }
      }

      .suggestionText {
        margin-left: 0;
        margin-top: 8px;
      }
    }

    .productSpecsSection .productSpecsRow .productSpecsColumn .productSpecsContainer .specsFormWrapper .specsFormRow {
      .formGroup {
        flex-direction: column;
        align-items: start;

        input {
          width: 100%;
        }

        .dimensionInput {
          width: 100%;

          &:not(:first-child) {
            margin-top: 6px;
          }
        }
      }
    }

    .priceInventoryFormRow {
      .formGroup {
        flex-direction: column;
        align-items: start;

        label {
          width: 100%;
        }

        input {
          width: 100%;
        }
      }

      .formGroupHalfColumn input {
        width: 100%;
      }

      .suggestionText {
        margin-left: 0;
        margin-top: 8px;
      }
    }

    .shippingFormRow .formGroup {
      flex-direction: column;
      align-items: start;

      input,
      select {
        width: 100%;
      }

      .dimensionInput {
        width: 100%;

        &:not(:first-child) {
          margin-top: 6px;
        }
      }

      .suggestionText {
        margin-left: 0;
        margin-top: 8px;
      }
    }

    .complianceFormWrapper .complianceFormRow .formGroup {
      flex-direction: column;
      align-items: start;

      input,
      .warrantyInput {
        width: 100%;
      }

      .suggestionText {
        margin-left: 0;
        margin-top: 8px;
      }
    }

    .seoKeywordsFormWrapper .seoKeywordsFormRow .formGroup {
      flex-direction: column;
      align-items: start;

      input {
        width: 100%;
      }

      .suggestionText {
        margin-left: 0;
        margin-top: 8px;
      }
    }
  }
}
