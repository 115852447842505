.container {
  background-color: #EC7426;
  overflow-x: auto;
  white-space: nowrap;
}

.tab {
  color: white;
  cursor: pointer;
  transition: color 1s ease;
  font-size: 1.25rem;
  padding: 0.5rem 1rem;

  transition: background-color .3s ease-in;

  &.active {
    background-color: black;
  }
}

@media (max-width: 576px) {
  .tab {
    font-size: 1rem;
  }
}