.mainManageLoansContainer {
    .sectionHeading {
        font-weight: 600;
        margin-bottom: 10px;
    }

    .datatableContainer {
        background-color: #FFFFFF;
        padding: 20px;

        .actionButtonsContainer {
            display: flex;
            justify-content: space-between;
            gap: 30px;

            .button {
                text-decoration: none;
                color: #1A2425;
                padding: 0;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
