.designCardContainer {
    margin-bottom: 40px;

    .designCardRow {
        --bs-gutter-x: 0;
        margin-bottom: 10px;
        row-gap: 12px;

        .imgColumn {
            padding-right: 20px;

            .designImage {
                max-width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .contentColumn {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            gap: 5px;

            .textSection {
                background-color: #F2F2F2;
                padding: 22px 15px;
                border-radius: 5px;

                p {
                    font-size: 14px;
                    margin-bottom: 20px;
                }

                a {
                    color: #1A2425;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                .hashtags {
                    font-weight: 700;
                    margin: 10px 0;
                }
            }

            .copyBtn {
                text-decoration: none;
                padding: 0;
                border: none;
                color: #1A2425;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
