.heading {
  font-weight: 600;
  font-size: 2.25rem;
  background-color: #fbbd34;
  padding: 0.3rem 1rem;
  color: #fff;
}

.icon {
  color: #fbbd34;
  display: grid;
  place-items: center;

  .icon-sm {
    font-size: 52px;
  }
}

.info-wrapper {
  background-color: #f2f2f2;
  border-radius: 1.25rem;
  padding-block: 1rem;
  padding-inline: 1rem;

  &:not(:first-child) {
    margin-top: 1rem;
  }

  .info {
    font-size: 20px;
  }
}

.contact-form {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 1rem;

  .form-group {
    label {
      font-weight: 600;
    }
  }

  .form-group--full-width {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .address-info {
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 5;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 2rem;
    background-color: #f2f2f2;
    border-radius: 1.25rem;

    .address-info-image {
      aspect-ratio: 1/1;
      width: 48px;
    }
  }
}

@media (max-width: 1024px) {
  .heading {
    font-size: 1.25rem;
  }

  .contact-form {

    .form-group {
      label {
        font-weight: 600;
        font-size: 0.75rem;
      }
    }

    .address-info {
      .address-info-image {
        aspect-ratio: 1/1;
        width: 32px;
      }
    }
  }

  .info-wrapper {
    .info {
      font-size: 1rem;
    }
  }

  .info {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .heading {
    font-size: 1rem;
  }

  .info-wrapper {
    .info {
      font-size: 1rem;
    }
  }

  .info {
    font-size: 1rem;
  }

  .icon {
    .icon-sm {
      font-size: 32px;
    }
  }

  .contact-form {
    grid-template-columns: 1fr;

    .address-info {
      grid-column-start: 1;
      grid-row-start: auto;
      grid-row-end: auto;
      padding: 1rem;
      order: -1;

      .address-info-image {
        width: 48px;
        height: 48px;
      }
    }

    .form-group--full-width {
      grid-column-start: 1;
      grid-column-end: 2;
    }
  }
}

@media (max-width: 375px) {
  .heading {
    font-size: 0.75rem;
  }

  .info-wrapper {
    .info {
      font-size: 0.75rem;
    }
  }

  .info {
    font-size: 0.75rem;
  }

  .icon {
    .icon-sm {
      font-size: 24px;
    }
  }

  .contact-form {
    grid-template-columns: 1fr;

    .address-info {
      grid-column-start: 1;
      grid-row-start: auto;
      grid-row-end: auto;
      padding: 1rem;
      order: -1;

      .address-info-image {
        width: 32px;
        height: 32px;
      }
    }

    .form-group--full-width {
      grid-column-start: 1;
      grid-column-end: 2;
    }
  }
}
