
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 33px;
  height: 11px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #D9D9D9;
  -webkit-transition: .3s;
  transition: .3s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 20px;
  left: 0px;
  bottom: -5px;
  background-color: #FBBD34;
  border: 1px solid #fff;
  -webkit-transition: .3s;
  transition: .3s;
}

input:not(:checked) + .slider:before {
  background-color: #9B9B9B;
}

input:checked + .slider:before {
  -webkit-transform: translateX(70%);
  -ms-transform: translateX(70%);
  transform: translateX(70%);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}