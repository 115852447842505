.tab {
  color: black;
  cursor: pointer;
  transition: color 0.3s ease;
  font-size: 1.25rem;
  padding: 0.5rem 0rem;

  &:hover {
    color: #EC7426;
  }
}

.tab-active {
  color: #EC7426;
}
