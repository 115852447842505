.note-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 40px;

    .note-section {
        padding: 2rem;
        background-color: #ffffff;

        .heading {
            font-weight: 500;
            font-size: 1.25rem;
        }

        .buttons {
            display: flex;
            gap: .5rem;

            .done-btn {
                background-color: #FBBD34;
                padding: .3rem 2rem;
                color: white;
                border-radius: .5rem;
                cursor: pointer;
            }

            .cancel-btn {
                background-color: #F5F5F5;
                padding: .3rem 2rem;
                border-radius: .5rem;
                cursor: pointer;
            }
        }
    }
}
