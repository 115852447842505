.mainContainer {
    background: #F2F2F2;
    padding: 20px;
    font-family: Inter;
    min-height: 100vh;

    .sectionHeading {
        font-weight: 600;
        margin-bottom: 20px;
    }

    .subSectionHeading {
        font-weight: 600;
        margin-bottom: 10px;
    }

    .currentStatusRow {
        --bs-gutter-x: 0;
        margin-bottom: 20px;

        .statusColumn {
            background: #FFFFFF;
            border: 1px solid #1A2425;
            border-radius: 10px;
            padding: 20px 30px;
            font-size: 16px;
            font-weight: 500;
            line-height: 28px;

            .statusContentRow {
                --bs-gutter-x: 0;

                .valueColumn {
                    color: #EC7426;
                }
            }
        }
    }

    .filterTableContainer {
        background: #FFFFFF;
        padding: 20px;
        margin-bottom: 20px;

        .table {
            margin-top: 20px;

            .statusDelivered {
                color: #41B8E4;
            }
        }
    }

    .monthlySalesContainer {
        padding: 0;
        margin-bottom: 20px;
    }
}
