.modalHeader {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    line-height: unset;
}

.searchContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;

    .searchInput {
        width: 30%;
        border: 1px solid #D9D9D9;
        border-radius: 10px;
    }
}

.filterContainer {
    border-bottom: 1px solid #D9D9D9;
}

.productsContainer {
    margin-top: 20px;

    .totalProducts {
        padding: 10px 0;
        margin-bottom: 10px;

        span {
            background: #FBBD34;
            color: #FFFFFF;
            font-weight: 500;
            font-size: 16px;
            padding: 10px 20px;

        }
    }

    .productsTable {
        padding: 0;

        .productInfo {
            display: flex;
            align-items: center;
            gap: 10px;

            .productImage {
                width: 60px;
                height: auto;
                border-radius: 5px;
            }

            .productName {
                font-size: 16px;
                font-weight: 500;
            }

            .productSKU {
                font-size: 11px;
                font-weight: 500;

                span {
                    font-weight: 400;
                }
            }
        }
    }
}
