.variationContainer {
    position: relative;

    .formGroup {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        gap: 0.5rem;

        @media (max-width: 992px) {
            flex-direction: column;
            align-items: start;
        }

        label {
            font-size: 15px;
            font-weight: 400;
            color: #1A2425;
            margin: 0;
            // min-width: 20%;
        }

        .multiSelectDropdown {
            .multiSelectToggle {
                width: 340px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: #ffffff !important;
                color: #1A2425 !important;

                &:hover {
                    box-shadow: none !important;
                }
            }

            .multiSelectMenu {
                max-height: 300px;
                overflow-y: auto;
                width: 100%;

                .multiSelectMenuOption {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer;
                }
            }
        }
    }

    .variationRow {
        --bs-gutter-x: 0;
        display: flex;
        align-items: flex-start;
        margin-bottom: 16px;

        .noVariations {
            padding: 8px 0;
            font-size: 15px;
            color: #1A2425;
        }

        .removeButton {
            border-radius: 10px;
            padding: 6px 12px 8px;
            background-color: transparent !important;
            color: #e70000 !important;

            &:hover {
                background-color: #e70000 !important;
                color: #ffffff !important;
                box-shadow: 0 8px 25px -10px #e70000;
            }
        }
    }
}
