.existingCategoriesContainer {
    background: #ffffff;
    padding: 0;

    .sectionTitle {
        background: #fbbd34;
        color: #ffffff;
        padding: 6px 10px;

        .sectionSubHeading {
            font-weight: 600;
            margin: 0;
        }
    }

    .existingCategoriesWrapper {
        padding: 10px;

        .existingCategoriesRow {
            --bs-gutter-x: 0;
            padding: 20px;
            border: 1px solid #d9d9d9;

            .tableContainer {
                overflow-x: auto;

                .categoryTable {
                    background-color: #FFFFFF;
                    border: 1px solid #D9D9D9;
                    margin: 0;
                    margin-top: 20px;

                    tr {
                        vertical-align: middle;
                        white-space: nowrap;
                    }

                    th {
                        background-color: #FBBD34;
                        color: #FFFFFF;
                        font-size: 15px;
                        font-weight: 500;
                        padding: 10px;
                    }

                    td {
                        font-size: 15px;
                        font-weight: 400;
                        vertical-align: middle;
                        padding: 10px;
                    }

                    .parentCategoryCell {
                        vertical-align: top;
                    }
                }

                .activeStatus {
                    color: #41B8E4;
                }

                .inactiveStatus {
                    color: #EC7426;
                }

                .underReviewStatus {
                    color: #FBBD34;
                }

                .actionLinks {
                    cursor: pointer;
                    color: #1A2425;
                    text-decoration: none;
                    padding: 0 10px;
                    font-size: 15px;
                    font-weight: 400;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
