.mainIntroductionContainer {
  .header {
    background-color: #EC7426;
    color: #fff;
    font-weight: 500;
    padding: 1rem;
    border-radius: 1rem;
    margin-bottom: 1rem;
  }

  .sectionHeading {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .detailsContainer {
    background-color: #FFFFFF;
    padding: 10px;
    margin-bottom: 16px;

    .detailsWrapper {
      border: 1px solid #D9D9D9;
      padding: 16px;
    }
  }
}
