.table {
  border-collapse: collapse;
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;

  th {
    text-align: center;
  }

  td {
    padding: 8px;
  }

  .center {
    display: grid;
    place-items: center;
  }

  .bg-gray {
    background-color: #f2f2f2;
  }

  thead th {
    background-color: #f2f2f2;
    font-weight: bold;
  }

  tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  input[type="checkbox"] {
    margin-left: 5px;
  }

  input[type="number"] {
    margin: auto;
  }
}
