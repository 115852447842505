.filterTableWrapper {
  --bs-gutter-x: 0;
  background-color: #FFFFFF;
  box-shadow: 0 1px 15px 0px #0000001A;
  margin-bottom: 1.25rem;
  margin-top: 1rem;

  .filterDropdownWrapper {
      padding: 20px;
      border-bottom: 1px solid #D5D5D5;

      .dropdownButtonsRow {
          display: flex;
          gap: 20px;
          margin-top: 20px;
      }
  }

  .tableWrapper {
      padding: 20px;
  }
}