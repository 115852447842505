.mainContainer {
    font-family: Inter;
    padding: 0px 40px 40px;

    p {
        margin: 0;
        line-height: unset;
    }

    .mainHeading {
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .sectionHeading {
        font-size: 30px;
        font-weight: 600;
        margin: 20px 0;
    }

    .cartDetailsRow {
        margin-bottom: 30px;
        row-gap: 20px;

        .cartItemsWrapper {
            padding: 20px 10px 0 50px;
        }

        .totalAmountContainer {
            box-shadow: 0px 0px 4px #00000040;
            border-radius: 20px;
            padding: 30px;

            .promotionSection {
                background-color: #FCFFDA;
                padding: 12px;

                input {
                    border: 1px solid #D9D9D9;
                    border-radius: 5px;
                }
            }

            .totalContainer {
                margin-top: 20px;

                .amountWrapper {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 10px;
                    font-size: 20px;
                    font-weight: 500;
                }

                .total {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 16px;
                    font-size: 30px;
                    font-weight: 600;

                    .finalTotal {
                        font-weight: 500;
                        color: #EC7426;
                    }
                }

                .vatText {
                    font-size: 14px;
                    font-weight: 500;
                    text-align: end;
                }
            }
        }

        .btnContainer {
            display: grid;
            place-items: end;

            .checkoutBtn {
                background-color: #41B8E4 !important;
                border: 1px solid #41B8E4;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 18px;
                font-weight: 500;
                padding: 10px 20px;
                margin-top: 20px;

                &:hover {
                    box-shadow: 0 8px 25px -8px #41B8E4;
                }

                .arrowIcon {
                    margin-left: 6px;
                }
            }
        }
    }
}


// Cart Item Styling
.cartItemContainer {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .storeWrapper {
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 0 0 5px 5px;

        .storeIcon {
            margin-bottom: 3px;
        }

        .storeName {
            font-size: 18px;
            font-weight: 400;
        }

        .storeArrow {
            margin-bottom: 2px;
        }
    }

    .cartItemDetail {
        display: flex;
        gap: 20px;
        padding-right: 10px;

        img {
            width: 122px;
            height: 128px;
        }

        .cartItemProductSpec {
            display: flex;
            flex-direction: column;
            gap: 20px;
            overflow: hidden;

            .productName {
                font-size: 24px;
                margin-bottom: 5px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .productSpec {
                background-color: #F2F2F2;
                border-radius: 5px;
                padding: 5px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .productQuantity {
                display: flex;
                align-items: center;
                border: 1px solid #D9D9D9;
                border-radius: 5px;
                width: max-content;

                .quantityCheckSubtractBtn {
                    background-color: #F2F2F2;
                    border: none;
                    border-radius: 5px 0 0 5px;
                }

                .quantityCheckAddBtn {
                    background-color: #F2F2F2;
                    border: none;
                    border-radius: 0 5px 5px 0;
                }

                .quantityNumber {
                    margin: 0 10px;
                }
            }
        }
    }

    .productPrice {
        color: #EC7426;
        font-size: 32px;
        font-weight: 500;
        padding-left: 10px;
    }
}


// Media Queries for responsive behavior
@media (max-width: 991px) {
    .mainContainer {

        .mainHeading,
        .sectionHeading {
            font-size: 24px;
        }

        .cartDetailsRow {
            .cartItemsWrapper {
                padding: 0;
                padding-top: 10px;
            }
        }
    }

    .cartItemContainer {
        .cartItemDetail {
            .cartItemProductSpec {
                .productName {
                    font-size: 20px;
                }
            }
        }

        .productPrice {
            font-size: 26px;
        }
    }
}

@media (max-width: 575px) {
    .mainContainer {
        .cartDetailsRow {
            .totalAmountContainer {
                padding: 15px;

                .totalContainer {
                    .amountWrapper {
                        font-size: 16px;
                    }

                    .total {
                        font-size: 24px;
                    }

                    .vatText {
                        font-size: 12px;
                    }
                }
            }

            .btnContainer {
                .checkoutBtn {
                    width: 100%;
                    font-size: 16px;
                }
            }
        }
    }

    .cartItemContainer {
        .cartItemRow {
            align-items: flex-end;
        }

        .cartItemDetail {
            flex-direction: column;
            gap: 10px;

            img {
                width: 100px;
                height: 106px;
            }

            .cartItemProductSpec {
                .productName {
                    font-size: 18px;
                }
            }
        }

        .productPrice {
            font-size: 24px;
        }
    }
}
