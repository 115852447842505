.notificationContainer {
    background-color: #FFFFFF;
    padding: 30px;
    box-shadow: 0px 0px 17.9px 0px #00000017;

    .mainHeading {
        text-align: center;
        color: #EC7426;
        font-weight: 600;
        margin-bottom: 20px;
    }

    .sectionHeading {
        font-weight: 600;
        margin-bottom: 16px;
    }

    .section {
        margin-bottom: 30px;
    }

    .radioContainer {
        display: flex;
        align-items: center;

        .radioLabel {
            position: relative;
            display: flex;
            align-items: center;
            cursor: pointer;
            font-size: 16px;
            font-weight: 500;
            color: #5D5D5D;
            margin: 0;
        }

        input[type="radio"] {
            display: none;
        }

        .radioCustom {
            width: 18px;
            height: 18px;
            border-radius: 50%;
            border: 1px solid #9B9B9B;
            display: inline-block;
            position: relative;
            margin-right: 10px;
            background-color: #FFFFFF;
            transition: background-color 0.3s ease;

            &::after {
                content: "";
                width: 12px;
                height: 12px;
                background-color: transparent;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition: background-color 0.3s ease;
            }
        }

        input[type="radio"]:checked+.radioCustom {
            background-color: #FFFFFF;

            &::after {
                background-color: #FBBD34;
            }
        }
    }

    .previewHeading,
    .testLabel {
        font-size: 16px;
        font-weight: 500;
        color: #5D5D5D;
        margin-bottom: 8px;
    }

    .previewBox {
        width: 80%;
        height: 160px;
        background: #F2F2F2;
        border-radius: 10px;
    }

    .testNotificationBtnContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        gap: 40px;

        .testContainer {
            .testInput {
                height: 45px;
                border-radius: 8px;
                border: 1px solid #8F91A899;
                color: #5D5D5D;
                padding: 10px;
                font-size: 15px;
                font-weight: 500;
                transition: all 0.3s ease;

                &::placeholder {
                    color: #BCBDCB;
                }

                &:focus {
                    border-color: #007bff;
                    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
                    outline: none;
                }
            }

            .testBtn {
                background-color: #D9D9D9 !important;
                border: 1px solid #8F91A899;
                color: #1A2425 !important;
                font-size: 18px;
                font-weight: 400;
                padding: 6px 24px;

                &:hover {
                    box-shadow: 0 8px 15px -8px #82868b !important;
                }
            }
        }

        .btnContainer {
            text-align: end;
        }
    }
}
