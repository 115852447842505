.mainPaymentMethodContainer {
  .mainHeading {
    font-weight: 600;
  }

  p {
    margin: 0;
    line-height: unset;
  }

  .paymentTextBtnWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    .paymentOptionsBtnContainer {
      position: relative;

      .paymentBtn {
        color: #EC7426;
        text-decoration: none;
        font-size: 16px;
        font-weight: 500;
        padding: 0;

        &:hover {
          text-decoration: underline;
        }

        .arrowIcon {
          margin-left: 5px;
        }
      }
    }
  }

  .paymentMethods {
    background-color: #F2F2F2;
    font-weight: 600;
    --bs-gutter-x: 0;
    margin-top: 30px;
    padding: 20px;
    row-gap: 20px;

    .paymentMethodWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      max-width: calc(100% / 5);
      flex: 0 0 calc(100% / 5);

      .paymentMethod {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        .imgContainer {
          min-width: 80px;
          height: auto;
        }
      }

      .checkboxWrapper {
        display: flex;
        justify-content: center;
        padding-top: 10px;

        input {
          width: 40px;
          height: 30px;
          margin: 0;
        }
      }
    }
  }

  .paymentDetails {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

    div {
      display: flex;
      flex-direction: column;

      label {
        font-weight: bold;
        margin-bottom: 0.5rem;
      }
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  .finalizeBtnContainer {
    display: grid;
    place-items: end;

    .paymentBtn {
      background-color: #41B8E4 !important;
      border: 1px solid #41B8E4;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 500;
      padding: 10px 20px;
      margin-top: 20px;

      &:hover {
        box-shadow: 0 8px 25px -8px #41B8E4;
      }

      .arrowIcon {
        margin-left: 6px;
      }
    }
  }
}

// Media Queries for responsive behavior
@media (max-width: 1440px) {
  .mainPaymentMethodContainer {
    .paymentTextBtnWrapper {
      flex-direction: column;
      align-items: flex-start;
      .paymentOptionsBtnContainer {
        align-self: flex-end;
      }
    }
    .paymentMethods {
      .paymentMethodWrapper {
        max-width: 100%;
        flex: 0 0 auto;
      }
    }
  }
}

@media (max-width: 575px) {
  .mainPaymentMethodContainer {
    .paymentMethods {
      .paymentMethodWrapper {
        flex-direction: row;
        .checkboxWrapper {
          padding: 0;
          padding-left: 10px;
        }
      }
    }
  }
}
