.mainContainer {
    background: #f2f2f2;
    padding: 20px;
    font-family: "Inter";
    min-height: 100vh;

    .sectionHeading {
        font-weight: 600;
        margin-bottom: 10px;
    }

    p {
        margin: 0;
        line-height: unset;
    }

    .categoryManagementContainer {
        padding: 0;

        .filterContainer {
            border-bottom: 1px solid #D9D9D9;
            margin-bottom: 20px;
        }
    }
}
