.wrapper {
  border: 1px solid #D9D9D9;
  border-radius: .3rem;
  padding-inline: 2rem;
  padding-block: .3rem;
  font-size: 15px;
  text-align: center;

  .text-orange {
    font-weight: 600;
    color: #EC7426;
  }

  .label {
    font-weight: 500;
  }
}