.salescontainer {
    max-width: 500px;
    margin: 20px auto;
  }
  
  .table {
    border-collapse: collapse;
    thead {
      background-color: #f8f8f8; // Light gray background for the header
    }
    tbody tr {
      border-bottom: none !important; // Remove bottom border
    }
  }
  
  .progresscontainer {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-top: 20px;
  }
  
  .progress {
    flex: 1;
    height: 6px; // Reduced height
    border-radius: 5px;
  }
  
  .progressbar {
    height: 70%;
    border-radius: 5px;
  }
  
  .badge {
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 10px;
    border: 1px solid;
    background-color: transparent;
  }
  