.addProductContainer {
    background: #f2f2f2;
    padding: 20px;
    font-family: Inter;
    min-height: 100vh;

    .sectionHeading {
        font-weight: 600;
        margin-bottom: 10px;
    }

    p {
        margin: 0;
        line-height: unset;
    }

    .sectionTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fbbd34;
        color: #ffffff;
        padding: 6px 10px;
        border-radius: 10px 10px 0 0;

        .sectionSubHeading {
            font-weight: 600;
            margin: 0;
        }

        .saveButton {
            background: #ffffff !important;
            color: #1a2425 !important;
            border: 1px solid #1a2425;
            border-radius: 10px;
            padding: 5px 15px;
            font-size: 16px;
            font-weight: 500;
        }
    }

    .productImgVideoSection {
        padding: 0;
        font-size: 15px;

        .productImgVideoRow {
            --bs-gutter-x: 0;

            .productImgVideoColumn {
                .headingContainer {
                    background: #ffffff;
                    border-radius: 10px;
                    padding: 20px;
                    margin-bottom: 10px;
                    text-align: center;

                    .helpSection {
                        padding: 10px;
                        margin-top: 5px;

                        .helptext {
                            background: #ec7426;
                            color: #ffffff;
                            padding: 10px 20px;
                            border-radius: 30px;

                            .helpImg {
                                margin-right: 10px;
                                margin-bottom: 2px;
                            }
                        }
                    }
                }

                .productImgVideoContainer {
                    background: #ffffff;
                    border-radius: 10px;
                    padding: 0;
                    margin-bottom: 10px;

                    .imgVideoWrapper {
                        padding: 10px;

                        .imgVideoRow {
                            --bs-gutter-x: 0;
                            padding: 20px;
                            border: 1px solid #d9d9d9;

                            .imgQualityColumn {
                                .imageQuality {
                                    color: #ec7426;
                                    font-size: 14px;
                                    padding: 15px;

                                    p {
                                        margin-bottom: 10px;

                                        .pandaImg {
                                            margin-right: 8px;
                                        }

                                        .checkImg {
                                            margin: 0 0 4px 8px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .stepsSidebarColumn {
                padding-left: 10px;

                .stepsSidebar {
                    padding: 0;

                    .stepsHeader {
                        background-color: #ec7426;
                        color: #ffffff;
                        padding: 10px;
                        font-weight: 600;
                        border-radius: 10px 10px 0 0;
                    }

                    .stepsWrapper {
                        position: relative;

                        .verticalLine {
                            position: absolute;
                            left: 19px;
                            top: 0;
                            bottom: 0;
                            width: 4px;
                            background-color: #d9d9d9;
                            border-radius: 20px;
                            margin: 10px 0;
                            z-index: 1;
                        }

                        .stepItem {
                            display: flex;
                            align-items: center;
                            padding: 6px 15px;
                            border: none;
                            color: #324647;
                            position: relative;
                            cursor: pointer;
                            border-radius: 0;

                            &:hover {
                                background-color: #f0f0f0;
                            }

                            &.active {
                                font-weight: 700;
                                color: #1a2425;

                                .stepIndicator {
                                    background-color: #fbbd34;
                                }
                            }

                            &:first-child {
                                padding-top: 10px;
                            }

                            &:last-child {
                                padding-bottom: 10px;
                                border-radius: 0 0 10px 10px;
                            }
                        }

                        .stepIndicator {
                            width: 13px;
                            height: 13px;
                            background-color: transparent;
                            border-radius: 50%;
                            margin-right: 10px;
                            z-index: 3;
                            transition: background-color 0.3s;
                        }
                    }
                }
            }
        }
    }

    .guidelineColumn {
        padding-left: 10px;

        .guidelineContainer {
            padding: 0;
            border-radius: 10px;
            background: #FFFFFF;
            margin-bottom: 10px;

            .guidelineHeader {
                background-color: #41B8E4;
                color: #ffffff;
                padding: 10px;
                font-weight: 600;
                border-radius: 10px 10px 0 0;
            }

            .guidelineText {
                padding: 10px;
                text-align: justify;
            }
        }
    }

    .productInfoSection {
        padding: 0;
        font-size: 15px;

        .productInfoRow {
            --bs-gutter-x: 0;

            .productInfoColumn {
                .productInfoContainer {
                    background: #ffffff;
                    border-radius: 10px;
                    padding: 0;
                    margin-bottom: 10px;

                    .infoFormWrapper {
                        padding: 10px;

                        .infoFormRow {
                            --bs-gutter-x: 0;
                            padding: 20px;
                            border: 1px solid #d9d9d9;

                            .formSection {
                                .formGroup {
                                    --bs-gutter-x: 0;
                                    display: flex;
                                    align-items: center;
                                    row-gap: 5px;

                                    label {
                                        font-size: 15px;
                                        font-weight: 400;
                                        color: #1A2425;
                                        margin: 0;
                                    }

                                    input,
                                    select {
                                        border: 1px solid #1A2425;
                                        border-radius: 5px;

                                        &::placeholder {
                                            color: #D9D9D9;
                                        }
                                    }
                                }

                                .formGroupHalfColumn {
                                    --bs-gutter-x: 0;
                                    display: flex;
                                    align-items: center;
                                    margin: 0 !important;
                                    row-gap: 5px;

                                    label {
                                        font-size: 15px;
                                        font-weight: 400;
                                        color: #1A2425;
                                        margin: 0;
                                    }

                                    input,
                                    select {
                                        border: 1px solid #1A2425;
                                        border-radius: 5px;

                                        &::placeholder {
                                            color: #D9D9D9;
                                        }
                                    }
                                }

                                .suggestionText {
                                    font-size: 12px;
                                    color: #EC7426;
                                    font-weight: 400;

                                    .pandaImg {
                                        margin: 0 6px 1px 0;
                                    }
                                }

                                .validationText {
                                    font-size: 12px;
                                    color: #EC7426;
                                    font-weight: 400;

                                    .checkImg {
                                        margin: 0 0 2px 4px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .productDetailSection {
        padding: 0;
        font-size: 15px;

        .productDetailRow {
            --bs-gutter-x: 0;

            .productDetailColumn {
                .productDetailContainer {
                    background: #ffffff;
                    border-radius: 10px;
                    padding: 0;
                    margin-bottom: 10px;

                    .detailFormWrapper {
                        padding: 10px;

                        .detailFormRow {
                            --bs-gutter-x: 0;
                            padding: 20px;
                            border: 1px solid #d9d9d9;

                            .codeEditorRow {
                                --bs-gutter-x: 0;
                                margin-bottom: 20px;
                                row-gap: 6px;

                                .detailsColumn {
                                    p {
                                        font-weight: 600;
                                    }
                                }

                                .codeEditorSection {
                                    .codeEditor {
                                        height: 100%;
                                        padding-bottom: 50px;
                                    }
                                }
                            }

                            .aiHighlightsRow {
                                --bs-gutter-x: 0;

                                label {
                                    font-size: 15px;
                                    font-weight: 600;
                                    color: #1A2425;
                                    margin-bottom: 10px;
                                }

                                .aiHighlightInputRow {
                                    --bs-gutter-x: 0;
                                    border: 1px solid #1A2425;
                                    border-radius: 5px;

                                    .textareaInputColumn {
                                        textarea {
                                            border: none;
                                            border-right: 1px solid #D9D9D9;
                                            border-radius: 10px 0 0 10px;
                                            min-height: 200px;
                                            max-height: 200px;
                                        }
                                    }

                                    .buttonsColumn {
                                        .aiActionButtonsContainer {
                                            padding: 10px;
                                            display: flex;
                                            flex-direction: column;
                                            align-items: flex-end;

                                            .generateBtn {
                                                background: #41B8E4 !important;
                                                border: 1px solid #41B8E4 !important;
                                                color: #FFFFFF !important;
                                                font-size: 16px;
                                                font-weight: 400;
                                                padding: 10px 20px;
                                                margin-bottom: 6px;

                                                &:hover {
                                                    box-shadow: 0 8px 25px -8px #41B8E4;
                                                }
                                            }

                                            .otherBtn {
                                                background: none !important;
                                                border: none;
                                                color: #1A2425 !important;
                                                font-size: 14px;

                                                &:hover {
                                                    box-shadow: none;
                                                    text-decoration: underline;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .productSpecsSection {
        padding: 0;
        font-size: 15px;

        .productSpecsRow {
            --bs-gutter-x: 0;

            .productSpecsColumn {
                .productSpecsContainer {
                    background: #ffffff;
                    border-radius: 10px;
                    padding: 0;
                    margin-bottom: 10px;

                    .specsFormWrapper {
                        padding: 10px;

                        .specsFormRow {
                            --bs-gutter-x: 0;
                            padding: 20px;
                            border: 1px solid #d9d9d9;

                            .formGroup {
                                --bs-gutter-x: 0;
                                display: flex;
                                align-items: center;
                                row-gap: 5px;

                                label {
                                    font-size: 15px;
                                    font-weight: 400;
                                    color: #1A2425;
                                    margin: 0;
                                }

                                input {
                                    border: 1px solid #1A2425;
                                    border-radius: 5px;

                                    &::placeholder {
                                        color: #D9D9D9;
                                    }
                                }

                                .sizeButton {
                                    background: #41B8E4 !important;
                                    border: 1px solid #41B8E4 !important;
                                    color: #FFFFFF !important;
                                    font-size: 14px;
                                    font-weight: 400;
                                    padding: 8px 20px;

                                    &:hover {
                                        box-shadow: 0 8px 25px -8px #41B8E4;
                                    }
                                }

                                .suggestionText {
                                    font-size: 12px;
                                    color: #EC7426;
                                    font-weight: 400;
                                    margin-left: 10px;

                                    .pandaImg {
                                        margin: 0 6px 1px 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .priceInventoryFormRow {
        --bs-gutter-x: 0;
        padding: 20px;
        border: 1px solid #d9d9d9;

        .formGroup {
            --bs-gutter-x: 0;
            display: flex;
            align-items: center;
            row-gap: 5px;

            label {
                font-size: 15px;
                font-weight: 400;
                color: #1A2425;
                margin: 0;
            }

            input {
                border: 1px solid #1A2425;
                border-radius: 5px;

                &::placeholder {
                    color: #D9D9D9;
                }
            }
        }

        .formGroupHalfColumn {
            --bs-gutter-x: 0;
            display: flex;
            align-items: center;
            margin: 0 !important;
            row-gap: 5px;

            label {
                font-size: 15px;
                font-weight: 400;
                color: #1A2425;
                margin: 0;
            }

            input {
                border: 1px solid #1A2425;
                border-radius: 5px;

                &::placeholder {
                    color: #D9D9D9;
                }
            }
        }

        .suggestionText {
            font-size: 12px;
            color: #EC7426;
            font-weight: 400;
            margin-left: 10px;

            .pandaImg {
                margin: 0 6px 1px 0;
            }
        }

        .bulkPricingContainer {
            padding: 0;
            padding-top: 10px;

            .title {
                font-size: 15px;
                font-weight: 600;
                margin-bottom: 10px;
            }

            .subtitle {
                font-size: 15px;
                font-weight: 400;
                margin-bottom: 15px;
            }

            .tiersContainer {
                margin: 0;
                padding: 0 80px;

                .label {
                    font-size: 15px;
                    font-weight: 500;
                    color: #1A2425;
                    margin-bottom: 8px;
                }

                .inputCol {
                    margin-bottom: 12px;

                    .input {
                        border: 1px solid #1A2425;
                        border-radius: 5px;
                        font-size: 15px;

                        &::placeholder {
                            color: #D9D9D9;
                        }
                    }

                    .error {
                        color: red;
                        font-size: 12px;
                        margin-top: 5px;
                        margin-left: 2px;
                        font-weight: 400;
                    }
                }
            }
        }
    }

    .shippingFormRow {
        --bs-gutter-x: 0;
        padding: 20px;
        border: 1px solid #d9d9d9;

        .formGroup {
            --bs-gutter-x: 0;
            display: flex;
            align-items: center;
            row-gap: 5px;

            label {
                font-size: 15px;
                font-weight: 400;
                color: #1A2425;
                margin: 0;
            }

            input,
            select {
                border: 1px solid #1A2425;
                border-radius: 5px;

                &::placeholder {
                    color: #D9D9D9;
                }
            }

            .suggestionText {
                font-size: 12px;
                color: #EC7426;
                font-weight: 400;

                .pandaImg {
                    margin: 0 6px 1px 0;
                }
            }
        }
    }

    .complianceFormWrapper {
        padding: 10px;

        .complianceFormRow {
            --bs-gutter-x: 0;
            padding: 20px;
            border: 1px solid #d9d9d9;

            .formGroup {
                --bs-gutter-x: 0;
                display: flex;
                align-items: center;
                row-gap: 5px;

                label {
                    font-size: 15px;
                    font-weight: 400;
                    color: #1A2425;
                    margin: 0;
                }

                .fileNameDisplay {
                    margin-left: 10px;
                    color: #1A2425;
                }

                input {
                    border: 1px solid #1A2425;
                    border-radius: 5px;

                    &::placeholder {
                        color: #D9D9D9;
                    }
                }

                .addCertificateButton {
                    background: #41B8E4 !important;
                    border: 1px solid #41B8E4 !important;
                    color: #FFFFFF !important;
                    font-size: 14px;
                    font-weight: 400;
                    padding: 8px 20px;

                    &:hover {
                        box-shadow: 0 8px 25px -8px #41B8E4;
                    }
                }

                .suggestionText {
                    font-size: 12px;
                    color: #EC7426;
                    font-weight: 400;
                    margin-left: 10px;

                    .pandaImg {
                        margin: 0 6px 1px 0;
                    }

                    .checkImg {
                        margin: 0 0 2px 4px;
                    }
                }
            }
        }
    }

    .seoKeywordsFormWrapper {
        padding: 10px;

        .seoKeywordsFormRow {
            --bs-gutter-x: 0;
            padding: 20px;
            border: 1px solid #d9d9d9;

            .formGroup {
                --bs-gutter-x: 0;
                display: flex;
                align-items: center;
                row-gap: 5px;

                label {
                    font-size: 15px;
                    font-weight: 400;
                    color: #1A2425;
                    margin: 0;
                }

                input {
                    border: 1px solid #1A2425;
                    border-radius: 5px;

                    &::placeholder {
                        color: #D9D9D9;
                    }
                }

                .suggestionText {
                    font-size: 12px;
                    color: #EC7426;
                    font-weight: 400;

                    .pandaImg {
                        margin: 0 6px 1px 0;
                    }
                }
            }
        }
    }

    .additionalFormWrapper {
        padding: 10px;

        .additionalFormRow {
            --bs-gutter-x: 0;
            padding: 20px;
            border: 1px solid #d9d9d9;

            .formGroup {
                --bs-gutter-x: 0;
                display: flex;
                align-items: center;
                row-gap: 5px;

                label {
                    font-size: 15px;
                    font-weight: 400;
                    color: #1A2425;
                    margin: 0;
                    line-height: normal;
                }

                .checkMarkGroup {
                    display: flex;
                    align-items: center;

                    label {
                        min-width: auto;
                        margin-right: 10px;
                        line-height: normal;
                    }

                    input {
                        border: 1px solid #1A2425;
                        border-radius: 5px;
                        margin: 0;
                        padding: 9px 12px;

                        &::placeholder {
                            color: #D9D9D9;
                        }
                    }
                }
            }
        }
    }

    .productBtnContainer {
        padding: 0;
        font-size: 15px;

        .addProductBtnContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 10px;

            .productBtn {
                background: #41B8E4 !important;
                border: 1px solid #41B8E4 !important;
                color: #FFFFFF !important;
                font-size: 14px;
                font-weight: 400;
                padding: 10px 20px;

                &:hover {
                    box-shadow: 0 8px 25px -8px #41B8E4;
                }
            }
        }
    }
}

@media (max-width: 1399px) {
    .addProductContainer {
        .productImgVideoSection {
            .productImgVideoRow {
                flex-direction: column-reverse;

                .stepsSidebarColumn {
                    padding: 0;
                    margin-bottom: 10px;
                }
            }
        }

        .guidelineColumn {
            padding: 0;
        }
    }
}

@media (max-width: 1199px) {
    .addProductContainer {
        .productDetailSection {
            .productDetailRow {
                .productDetailColumn {
                    .productDetailContainer {
                        .detailFormWrapper {
                            .detailFormRow {
                                .aiHighlightsRow {
                                    .aiHighlightInputRow {
                                        .textareaInputColumn {
                                            textarea {
                                                border: none;
                                                border-bottom: 1px solid #D9D9D9;
                                                border-radius: 10px 10px 0 0;
                                            }
                                        }
                                        .buttonsColumn {
                                            .aiActionButtonsContainer {
                                                flex-direction: row-reverse;
                                                align-items: center;
                                                .generateBtn {
                                                    margin: 0;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .addProductContainer {
        .productImgVideoSection {
            .productImgVideoRow {
                .productImgVideoColumn {
                    .headingContainer {
                        font-size: 14px;
                        .helpSection {
                            .helptext {
                                padding: 10px 10px;
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
        .priceInventoryFormRow {
            .bulkPricingContainer {
                .tiersContainer {
                    padding: 0;
                }
            }
        }
    }
}

@media (max-width: 575px) {
    .addProductContainer {
        .productImgVideoSection {
            .productImgVideoRow {
                .productImgVideoColumn {
                    .productImgVideoContainer {
                        .imgVideoWrapper {
                            .imgVideoRow {
                                .imgQualityColumn {
                                    .imageQuality {
                                        padding: 30px 0 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
