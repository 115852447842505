.mainContainer {
    .sectionHeading {
        font-weight: 600;
        margin-bottom: 10px;
    }

    .sectionTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fbbd34;
        color: #ffffff;
        padding: 6px 10px;

        .sectionSubHeading {
            font-weight: 600;
            margin: 0;
        }
    }

    p {
        margin: 0;
        line-height: unset;
    }

    .suggestionSection {
        background: #ffffff;
        padding: 0;
        font-size: 16px;
        font-weight: 500;

        .suggestionDataWrapper {
            --bs-gutter-x: 0;
            padding: 10px;

            .suggestionDataRow {
                --bs-gutter-x: 0;
                padding: 20px;
                border: 1px solid #d9d9d9;

                p {
                    margin-bottom: 15px;
                }

                ol {
                    padding-left: 20px;
                    margin: 0;

                    .orderedList {
                        font-weight: 600;
                        margin-bottom: 15px;

                        ul {
                            list-style-type: disc;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}
