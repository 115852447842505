.img {
  width: 316px;
  height: 146px;
}

@media (max-width: 1024px) {
  .img {
    width: 100%;
  }
}
