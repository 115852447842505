.filterTableWrapper {
    --bs-gutter-x: 0;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 17.9px 0px #00000017;
    margin-bottom: 20px;

    .headingBtnWrapper {
        padding: 20px;

        .sectionHeading {
            font-weight: 600;
            margin: 0;
            color: #008CD6;
            padding-top: 20px;
        }
    }

    .tableWrapper {
        padding: 20px;
    }
}
