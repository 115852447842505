.mainContainer {
    background: #f2f2f2;
    padding-top: 10px;
    font-family: Inter;
    min-height: 100vh;

    .registeredContainer {
        padding: 0 20px;
        margin: 50px auto;

        .registeredRow {
            margin: 0;

            .checkIcon {
                margin-bottom: 20px;
            }

            .registeredMessage {
                margin: 0;
                margin-bottom: 5px;
            }

            p {
                margin: 0;
                line-height: unset;
            }
        }
    }

    .addEditBrandContainer {
        padding: 0 20px 20px;

        .sectionHeading {
            font-weight: 600;
            margin-bottom: 15px;
        }

        .sectionTitle {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #fbbd34;
            color: #ffffff;
            padding: 6px 10px;

            &:first-child {
                border-radius: 10px 10px 0 0;
            }

            .sectionSubHeading {
                font-weight: 600;
                margin: 0;
            }
        }

        p {
            margin: 0;
            line-height: unset;
        }

        .addEditBrandSection {
            background: #ffffff;
            border-radius: 10px;
            font-size: 15px;
            padding: 0;
            margin-bottom: 30px;

            .brandFormRow {
                --bs-gutter-x: 0;
                padding: 20px;
                border: 1px solid #d9d9d9;
                margin: 10px;

                .formSection {
                    .formGroupRow {
                        --bs-gutter-x: 0;
                        display: flex;
                        align-items: center;
                        margin-bottom: 10px;
                        row-gap: 5px;

                        label {
                            font-size: 15px;
                            font-weight: 400;
                            color: #1A2425;
                            margin: 0;
                        }

                        input,
                        select {
                            border: 1px solid #1A2425;
                            border-radius: 5px;

                            &::placeholder {
                                color: #D9D9D9;
                            }
                        }

                        textarea {
                            min-height: 80px;
                            border: 1px solid #1A2425;
                            border-radius: 5px;

                            &::placeholder {
                                color: #D9D9D9;
                            }
                        }

                        .uploadDocumentContainer {
                            display: flex;
                            align-items: center;

                            .uploadDocumentButton {
                                background: #41B8E4 !important;
                                border: 1px solid #41B8E4 !important;
                                color: #FFFFFF !important;
                                border-radius: 8px;
                                font-size: 14px;
                                font-weight: 400;
                                padding: 8px 20px;

                                &:hover {
                                    box-shadow: 0 8px 25px -8px #41B8E4;
                                }
                            }

                            .uploadedFileName {
                                font-size: 15px;
                                font-weight: 400;
                                margin-left: 15px;
                            }
                        }

                        .productGrid {
                            display: flex;
                            flex-wrap: wrap;
                            gap: 10px;

                            .placeholder {
                                width: 80px;
                                height: 80px;
                                background-color: #f0f0f0;
                                border-radius: 4px;
                            }

                            .imagePreview {
                                width: 80px;
                                height: 80px;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    border-radius: 4px;
                                }
                            }
                        }
                    }
                }
            }

            .relationshipFormRow {
                --bs-gutter-x: 0;
                padding: 20px;
                border: 1px solid #d9d9d9;
                margin: 10px;

                .formSection {
                    .formGroupRow {
                        --bs-gutter-x: 0;
                        display: flex;
                        align-items: center;
                        margin-bottom: 10px;
                        row-gap: 5px;

                        label {
                            font-size: 15px;
                            font-weight: 400;
                            color: #1A2425;
                            margin: 0;
                        }

                        .input {
                            border: 1px solid #1A2425;
                            border-radius: 5px;

                            &::placeholder {
                                color: #D9D9D9;
                            }
                        }

                        .checkboxGroup {
                            display: flex;
                            align-items: center;
                            flex-wrap: wrap;
                            column-gap: 30px;
                            row-gap: 10px;

                            .checkBox {
                                border: 1px solid #1A2425;
                                border-radius: 5px;
                                margin: 1px 0 0 8px;
                                padding: 9px 12px;
                            }
                        }

                        .otherInput {
                            border: 1px solid #1A2425;
                            border-radius: 5px;
                            margin-top: 5px;

                            &::placeholder {
                                color: #D9D9D9;
                            }
                        }

                        .uploadDocumentContainer {
                            display: flex;
                            align-items: center;

                            .uploadDocumentButton {
                                background: #41B8E4 !important;
                                border: 1px solid #41B8E4 !important;
                                color: #FFFFFF !important;
                                border-radius: 8px;
                                font-size: 14px;
                                font-weight: 400;
                                padding: 8px 20px;

                                &:hover {
                                    box-shadow: 0 8px 25px -8px #41B8E4;
                                }
                            }

                            .uploadedFileName {
                                font-size: 15px;
                                font-weight: 400;
                                margin-left: 15px;
                            }
                        }
                    }
                }
            }

            .infoFormWrapper {
                padding: 10px;

                .brandFormRow {
                    margin: 0;
                }
            }
        }

        .actionBtnContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;

            button {
                background: #41B8E4 !important;
                border: 1px solid #41B8E4 !important;
                color: #FFFFFF !important;
                font-size: 14px;
                font-weight: 400;
                padding: 8px 20px;

                &:hover {
                    box-shadow: 0 8px 25px -8px #41B8E4;
                }
            }
        }
    }
}
