.mainManageProductsContainer {
    background: #F2F2F2;
    padding: 20px;
    font-family: Inter;
    min-height: 100vh;

    .sectionHeading {
        font-weight: 600;
        margin-bottom: 10px;
    }

    .trackShipment {
        padding: 20px;
        background: #FFFFFF;

        .shipmentTable {
            margin-top: 20px;

            // Status colors
            .statusInTransit {
                color: #FBBD34;
            }

            .statusDelivered {
                color: #41B8E4;
            }
        }
    }
}
