$blue: #377dff;
$blue-hover: #2a62cc;

$sky-blue: #41b8e4;
$sky-blue-hover: #359fc2;

$orange: #ec7426;
$orange-hover: #c75d1f;

$gray: #9b9b9b;
$gray-hover: #7f7f7f;

$yellow: #FBBD34;
$yellow-hover: #d69e37;

$blue-gray: #718EBF;
$blue-gray-hover: #5f7599;

$purple: #5664F5;
$purple-hover: #3F4CD6;

$text-color-light: #fff;

@mixin button-hover($bg-color, $text-color: $text-color-light) {
  &:hover {
    background-color: $bg-color;
    color: $text-color;
  }
}

.primary-button {
  all: unset;
  border-radius: 0.5rem;
  padding-inline: 1.5rem;
  padding-block: 0.5rem;
  transition: all 0.3s ease-in;
  filter: drop-shadow(0 4px 4px #748ab38c);
  min-width: 85px;
  text-align: center;

  &.blue {
    background-color: $blue;
    color: $text-color-light;
    @include button-hover($blue-hover);
  }

  &.blue-outline {
    background-color: transparent;
    outline: 1px solid $blue;
    color: $blue;
    @include button-hover($blue);
  }

  &.sky-blue {
    background-color: $sky-blue;
    color: $text-color-light;
    @include button-hover($sky-blue-hover);
  }

  &.sky-blue-outline {
    background-color: transparent;
    outline: 1px solid $sky-blue;
    color: $sky-blue;
    @include button-hover($sky-blue);
  }

  &.orange {
    background-color: $orange;
    color: $text-color-light;
    @include button-hover($orange-hover);
  }

  &.orange-outline {
    background-color: transparent;
    outline: 1px solid $orange;
    color: $orange;
    @include button-hover($orange);
  }

  &.gray {
    background-color: $gray;
    color: $text-color-light;
    @include button-hover($gray-hover);
  }

  &.gray-outline {
    background-color: transparent;
    outline: 1px solid $gray;
    color: $gray;
    @include button-hover($gray);
  }

  &.yellow {
    background-color: $yellow;
    color: $text-color-light;
    @include button-hover($yellow-hover);
  }

  &.yellow-outline {
    background-color: transparent;
    outline: 1px solid $yellow;
    color: $yellow;
    @include button-hover($yellow);
  }

  &.blue-gray {
    background-color: $blue-gray;
    color: $text-color-light;
    @include button-hover($blue-gray-hover);
  }

  &.blue-gray-outline {
    background-color: transparent;
    outline: 1px solid $blue-gray;
    color: $blue-gray;
    @include button-hover($blue-gray);
  }

  &.purple {
    background-color: $purple;
    color: $text-color-light;
    @include button-hover($purple-hover);
  }

  &.purple-outline {
    background-color: transparent;
    outline: 1px solid $purple;
    color: $purple;
    @include button-hover($purple);
  }
}

@media (max-width: 576px) { // sm
  .primary-button {
    padding-inline: 1rem;
    padding-block: 0.25rem;
    font-size: 0.875rem;
    filter: drop-shadow(0 2px 2px #748ab38c);
  }
}

@media (min-width: 577px) and (max-width: 1024px) { // md
  .primary-button {
    padding-inline: 1.25rem;
    padding-block: 0.4rem;
    font-size: 1rem;
    filter: drop-shadow(0 3px 3px #748ab38c);
  }
}

@media (min-width: 1025px) { // lg
  .primary-button {
    padding-inline: 1.5rem;
    padding-block: 0.5rem;
    font-size: 1.125rem;
    filter: drop-shadow(0 4px 4px #748ab38c);
  }
}