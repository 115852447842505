.chartCard {
    background-color: #F5F5F5;
    border-radius: 20px;
    padding: 40px 30px;

    .chartTitle {
        font-weight: 600;
        margin-bottom: 10px;
    }
}
