.container {
  background-color: #FBBD34;
  display: flex;
  margin-top: 1rem;
  white-space: nowrap;
  overflow-x: auto;
}

.tab {
  font-weight: 600; 
  color: white;
  cursor: pointer;
  font-size: 1.25rem;
  padding: 0.5rem 1rem;
  border-bottom: 4px solid transparent;
  transition: border-bottom .3s ease-in;

  &.active {
    border-bottom: 4px solid black;
  }
}

@media (max-width: 576px) {
  .tab {
    font-size: 1rem;
  }
}