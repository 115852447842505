.outer-box {
  position: relative;
  display: inline-block;
  min-width: 23px;
  min-height: 18px;
  width: 23px;
  height: 18px;
}

.outer-box input {
  opacity: 0;
  width: 0;
  height: 0;
}

.tick {
  position: absolute;
  cursor: pointer;
  inset: 0;
  background-color: transparent;
  border-radius: .3rem;
  border: 1px solid black;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  display: grid;
  place-items: center;
}

input:checked + .tick:before {
  content: '';
  display: inline-block;
  width: 14px;  
  height: 14px;
  background-image: url('../../../assets/images/checkbox-tick.svg');
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0px;
}
