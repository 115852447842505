.promotionalMaterialsContainer {
    background-color: #FFFFFF;
    font-family: "Inter";
    padding: 30px 60px;
    color: #1A2425;

    p {
        margin: 0;
        line-height: unset;
    }

    .filterBarRow {
        --bs-gutter-x: 0;
        margin-bottom: 40px;
        border-bottom: 2px solid #D9D9D9;
    }
}

@media (max-width: 1392px) {
    .promotionalMaterialsContainer {
        padding: 30px;
    }
}
