.modalHeader {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    line-height: unset;
}

.modalInstructions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    font-size: 15px;
    padding: 0 20px;

    .nextButton {
        background: #41B8E4 !important;
        border: 1px solid #41B8E4 !important;
        color: #FFFFFF !important;
        font-size: 14px;
        font-weight: 500;
        padding: 6px 14px;
        border-radius: 8px;

        &:hover {
            box-shadow: 0 8px 25px -8px #41B8E4;
        }
    }
}

.categoriesRow {
    --bs-gutter-x: 0;
    padding-left: 20px;

    .categoryCol {
        margin-bottom: 20px;
        padding-right: 40px;

        .categoryCard {
            display: flex;
            align-items: center;

            .categoryCheckbox {
                margin: 0;
                margin-right: 15px;
                width: 20px;
                height: 20px;
                border: 1px solid #1A2425;
                border-radius: 5px;
                cursor: pointer;
            }

            .categoryLabel {
                position: relative;
                display: inline-block;
                width: 100%;
                text-align: center;
                cursor: pointer;

                .categoryImage {
                    width: 100%;
                    max-width: 100%;
                    height: auto;
                    object-fit: cover;
                }

                .categoryName {
                    font-size: 20px;
                    color: #ffffff;
                    font-weight: 600;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }
}


// Adjustments for Modal responsiveness
@media (max-width: 768px) {
    .modalInstructions {
        flex-direction: column;
        align-items: flex-start;

        .nextButton {
            margin-top: 10px;
            width: 100%;
        }
    }

    .categoryCard {
        flex-direction: column;
        align-items: flex-start;

        .categoryLabel {
            flex-direction: row-reverse;

            .categoryImage {
                margin-left: 0;
                margin-right: 0;
                margin-bottom: 10px;
            }

            .categoryName {
                text-align: center;
                width: 100%;
            }
        }
    }
}
