.recentOrderTable {
    padding: 0;
    overflow: auto;

    table {
        text-align: center;
        border: 1px solid #F2F2F2;
        margin: 0;

        th {
            background: #F2F2F2;
            font-weight: 600;
        }

        tbody {
            font-size: 14px;
            font-weight: 500;
        }

        th,
        td {
            align-content: center;
        }

        .cellWithImageAndText {
            .customerImage {
                margin-right: 10px;
            }
        }
    }

    .viewAllButton {
        margin-top: 10px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding: 0;
        background: none;
        border: none;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
    }

    // Status colors
    .statusPending {
        color: #EC7426;
    }

    .statusDelivered {
        color: #41B8E4;
    }

    .statusReady {
        color: #FBBD34;
    }
}
