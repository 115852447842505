.mainManageProductsContainer {
    background: #F2F2F2;
    padding: 20px;
    font-family: Inter;
    min-height: 100vh;

    .sectionHeading {
        font-weight: 600;
        margin-bottom: 10px;
    }

    .manageProducts {
        padding: 20px;
        background: #FFFFFF;
        border-radius: 10px;

        .totalProducts {
            --bs-gutter-x: 0;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            row-gap: 6px;

            .totalProductsContainer {
                padding: 10px 0;

                span {
                    background: #FBBD34;
                    color: #FFFFFF;
                    font-weight: 500;
                    font-size: 16px;
                    padding: 10px 20px;
                }
            }

            .btnsContainer {
                display: flex;
                justify-content: flex-end;
                gap: 10px;

                button {
                    font-size: 15px;
                    font-weight: 500;
                    background-color: transparent !important;
                    border: 1px solid #000000 !important;
                    color: #1A2425 !important;
                    padding: 6px 20px;
                }
            }
        }

        .productImg {
            width: auto;
            max-width: 100%;
            height: 40px;
        }
    }
}
