.mainFBMContainer {
    background: #f2f2f2;
    padding: 20px;
    font-family: Inter;
    min-height: 100vh;
    font-size: 15px;

    .mainHeading {
        font-weight: 600;
        margin-bottom: 20px;
    }

    p {
        margin: 0;
        line-height: unset;
    }

    .sectionHeading {
        font-weight: 600;
        margin-bottom: 10px;
    }

    .registeredContainer {
        padding: 0 20px;
        margin: 50px auto;

        .registeredRow {
            margin: 0;

            .checkIcon {
                margin-bottom: 20px;
            }

            .registeredMessage {
                margin: 0;
                margin-bottom: 30px;
            }

            p {
                font-size: 16px;
                width: 60%;
                margin: auto;
            }
        }
    }

    .fbmHeaderText {
        background: #EC7426;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 500;
        border-radius: 10px;
        padding: 10px 20px;
        margin-bottom: 20px;
    }

    .listContainer {
        background: #ffffff;
        padding: 10px;
        margin-bottom: 20px;

        ul,
        ol {
            border: 1px solid #D9D9D9;
            margin: 0;
            padding: 20px 40px;

            li {
                line-height: 1.8;

                span {
                    font-weight: 600;
                }
            }
        }
    }

    .mainTableContainer {
        background: #ffffff;
        padding: 10px;
        margin-bottom: 20px;

        .dataTableContainer {
            border: 1px solid #D9D9D9;
            padding: 0px 20px 20px;
        }
    }

    .signUpContainer {
        background: #ffffff;
        font-size: 15px;
        padding: 0;
        margin-bottom: 30px;

        .sectionTitle {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #fbbd34;
            color: #ffffff;
            padding: 10px;

            .sectionSubHeading {
                font-weight: 600;
                margin: 0;
            }
        }

        .registrationForm {
            padding: 20px;
            border: 1px solid #d9d9d9;
            margin: 10px;

            .formGroupRow {
                --bs-gutter-x: 0;
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                row-gap: 5px;

                label {
                    font-size: 15px;
                    font-weight: 400;
                    color: #1A2425;
                    margin: 0;
                }

                input,
                select {
                    border: 1px solid #1A2425;
                    border-radius: 5px;

                    &::placeholder {
                        color: #D9D9D9;
                    }
                }

                textarea {
                    min-height: 80px;
                    border: 1px solid #1A2425;
                    border-radius: 5px;

                    &::placeholder {
                        color: #D9D9D9;
                    }
                }
            }

            .checkBoxRow {
                --bs-gutter-x: 0;
                margin-top: 20px;
                column-gap: 20px;
                row-gap: 5px;

                .termsSection {
                    display: flex;
                    align-items: center;

                    label {
                        font-size: 13px;
                        font-weight: 500;
                        color: #1A2425;
                        margin: 0;
                    }

                    input[type="checkbox"] {
                        margin: 0;
                        margin-right: 10px;
                    }
                }

                .termsConditions {
                    color: #EC7426;
                    font-size: 13px;
                    font-weight: 500;

                    a {
                        color: #EC7426;
                    }
                }
            }
        }

        .registerBtnContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px;

            .registerBtn {
                background: #41B8E4 !important;
                border: 1px solid #41B8E4 !important;
                color: #FFFFFF !important;
                border-radius: 8px;
                font-size: 14px;
                font-weight: 500;
                padding: 10px 20px;

                &:hover {
                    box-shadow: 0 8px 25px -8px #41B8E4;
                }
            }
        }
    }
}
