.paginationContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-block: 2rem;
  background-color: #fff;
}

.paginationControls {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.navButton {
  padding: 0.5rem 1rem;
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  border: 1px solid #D9D9D9;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  gap: .5rem;

  &:disabled {
    background-color: #d2d2d2;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: #fabc42;
  }
}

.tab {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #D9D9D9;
  color: #000;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;

  &:hover {
    background-color: #cccccc;
  }
}

.active {
  background-color: #fabc42;
  color: #fff;
}

.ellipsis {
  font-size: 1rem;
  font-weight: bold;
  padding: 0 0.5rem;
}

.actionDropdown {
  .dropdownButton {
    background-color: #fff !important;
    color: #1a2425 !important;

    &:hover {
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15) !important;
    }
  }

  .dropDownMenu {
    border-radius: 10px;
    border: none;
    box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.0705882353);
    font-size: 14px;
    font-weight: 400;

    .dropdownItem {

      &:hover,
      &:active,
      &:focus {
        background-color: #fabc42;
        color: #FFFFFF;
      }
    }
  }
}

@media (max-width: 940px) {
  .paginationContainer {
    flex-direction: column;
    gap: 16px;
  }
}
