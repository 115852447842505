.formGroupContainer {
    .formLabel {
        font-size: 16px;
        font-weight: 500;
        color: #5D5D5D;
        margin-bottom: 8px;

        .required {
            color: red;
            margin-left: 5px;
        }
    }

    .formInput {
        height: 45px;
        border-radius: 8px;
        border: 2px solid #8F91A899;
        color: #5D5D5D;
        padding: 10px;
        font-size: 15px;
        font-weight: 500;
        width: 100%;
        transition: all 0.3s ease;

        &::placeholder {
            color: #BCBDCB;
        }

        &:focus {
            border-color: #007bff;
            box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
            outline: none;
        }
    }

    select {
        cursor: pointer;

        option {
            color: #1A2425;

            &:first-child {
                color: #BCBDCB;
            }
        }
    }

    .placeholderActive {
        color: #BCBDCB;
    }

    input[type="date"],
    input[type="time"] {
        text-indent: 0;
        transition: none;

        &::-webkit-calendar-picker-indicator {
            cursor: pointer;
        }
    }

    .inputWrapper {
        position: relative;

        .inputPlaceholder {
            color: transparent !important;
            text-indent: -9999px;
        }
    }

    .placeholderText {
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 15px;
        font-weight: 500;
        color: #BCBDCB;
        pointer-events: none;
    }

    .fileUploader {
        position: relative;
        cursor: pointer;

        .fileInput {
            opacity: 0;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            padding: 8px;
        }

        .fileLabel {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            color: #BCBDCB;
            width: 100%;

            .fileName {
                flex-grow: 1;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .icon {
                flex-shrink: 0;
                color: #9B9B9B;
                margin-right: 5px;
            }
        }

        .fileSelected {
            color: #5D5D5D;
        }
    }

    .fileError {
        margin: 0;
        margin-top: 4px;
        font-size: 14px;
        text-align: center;
        padding: 10px;
    }
}
