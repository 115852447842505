.right-side-wrapper {
  background-color: #F2F2F2;
  border-radius: 0.75rem;
  padding: 1rem;
  
  @media (max-width: 992px) {
    margin-top: 1rem;
  }
}

.text-orange {
  color: #EC7426;
}

.payment-time {
  background-color: #fff !important;
  padding: 1rem;
  border-radius: 1rem;
  border: 1px solid #D9D9D9;

  .form-check-input:focus {
    box-shadow: 0 0 0 .25rem rgba(251, 189, 52, 0.5);
  }

  .form-check-input:checked {
    background-color: #FBBD34;
    border-color: #FBBD34;
  }


  .save {
    background-color: #FBBD34;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    color: #fff;
    padding: 0.5rem;
  }
}