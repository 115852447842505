.modal {
  background: white;
  padding: 20px;
  border-radius: 10px;
  // width: 350px;
  text-align: center;
}

.title {
  font-size: 20px;
  font-weight: bold;
  color: #e2702f;
  margin-bottom: 15px;
}

.input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.leftSideRounded {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rightSideRounded {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}