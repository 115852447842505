.mainContainer {
  margin-bottom: 20px;

  .table {
    white-space: nowrap;
    margin: 0;

    th {
      background-color: #EC7426;
      color: #ffffff;
    }

    th,
    td {
      padding: 10px 40px;
    }
  }
}
