.card {
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
  cursor: pointer;

  .label {
    background-color: #fbbd34;
    color: #fff;
    text-align: center;
    font-weight: 600;
    font-size: 1.25rem;
    transition: background-color .3s ease-in;
    padding: 10px;

    &:hover {
      background-color: #ffcb5c;
    }
  }
}
