.card {
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #D9D9D9;

    h5 {
        font-weight: 600;
        margin-bottom: 5px;
    }

    p {
        margin: 0;
    }

    .controls {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        flex-direction: column;
    }

    .customSwitch {
        position: relative;
        width: 40px;
        height: 15px;
        background-color: #D9D9D9 !important;
        border-radius: 20px;
        transition: background-color 0.4s;

        &::before {
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #bdbcbc;
            top: -3px;
            left: 0px;
        }

        &:checked {
            &::before {
                transform: translateX(20px);
                background-color: #FBBD34;
            }
        }
    }

    .editBtn {
        font-size: 14px;
        font-weight: 500;
        text-decoration: none;
        border: none;
        background: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .icons {
        margin: 10px 8px 0 0;
    }
}
