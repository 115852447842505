.banner-container {
  display: grid;
  align-items: center;

  img {
    grid-area: 1/1;
  }

  .content {
    grid-area: 1/1;
    color: #fff;
    padding: 1rem 15rem;

    .banner-heading {
      font-size: 2.25rem;
      font-weight: 600;
      margin-bottom: 3rem;
    }

    .para {
      font-size: 2rem;
      font-weight: 500;
    }
  }
}

.scroll-container {
  scroll-snap-type: x mandatory;

  & > div {
    scroll-snap-align: start;
  }
}

.category-tab {
  border: 3px solid #d9d9d9;
  transition: 0.3s all ease-in-out;

  &:hover:not(.active-category) {
    border: 2px solid #ffd85e;
    transform: scale(1.005);
  }
}

.active-category {
  border: 3px solid #fbbd34;
}

.listItems {
  padding: 1rem;
  border-right: 2px solid #f2f2f2;
  border-bottom: 2px solid #f2f2f2;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s all ease-in-out;

  &:hover {
    transform: scale(1.005);
  }
}

.top-questions {
  border: 2px solid #f2f2f2;
}

.top-question {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  transition: 0.3s all ease-in-out;
  &:hover {
    transform: scale(1.005);
  }

  &:not(:last-child) {
    border-bottom: 2px solid #f2f2f2;
  }
}

.customer-service-button {
  all: unset;
  border: 1.41px solid #ec7426;
  border-radius: 0.44rem;
  color: #ec7426;
  font-weight: 500;
  font-size: 1.2325rem;
  padding: 0.3rem 1.5rem;
  transition: 0.3s color ease-in-out;

  &:hover {
    color: #fff;
    background-color: #ec7426;
  }

  &:focus {
    outline: 3px solid #ffd85e;
    outline-offset: 2px;
  }
}

@media (max-width: 1024px) {
  .banner-container {
    .content {
      padding: 1rem 1rem;
      
      .banner-heading {
        font-size: 1.5rem;
        font-weight: 500;
        margin-bottom: 3rem;
      }

      .para {
        font-size: 1.25rem;
        font-weight: 400;
      }
    }
  }
}

@media (max-width: 768px) {
  .banner-container {
    .content {
      padding: 1rem 1rem;
      
      .banner-heading {
        font-size: 1.5rem;
        font-weight: 500;
        margin-bottom: 1rem;
      }

      .para {
        font-size: 1.25rem;
        font-weight: 400;
      }
    }
  }
  .category-tab {
    font-size: 0.75rem;
  }
}

@media (max-width: 425px) {
  .banner-container {
    .content {
      padding: 1rem 1rem;
      
      .banner-heading {
        font-size: 1.25rem;
        font-weight: 500;
        margin-bottom: 0rem;
      }

      .para {
        font-size: 1rem;
        font-weight: 400;
      }
    }
  }
  .customer-service-button {
    all: unset;
    border: 1.41px solid #ec7426;
    border-radius: 0.44rem;
    color: #ec7426;
    font-weight: 500;
    font-size: 1rem;
    padding: 0.3rem 1.5rem;
    transition: 0.3s color ease-in-out;
  
    &:hover {
      color: #fff;
      background-color: #ec7426;
    }
  
    &:focus {
      outline: 3px solid #ffd85e;
      outline-offset: 2px;
    }
  }
}


@media (max-width: 320px) {
  .banner-container {
    .content {
      padding: 1rem;
      
      .banner-heading {
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 0rem;
      }

      .para {
        font-size: 0.75rem;
        font-weight: 400;
      }
    }
  }
}
