.mainContainer {
    background: #F2F2F2;
    padding: 20px;
    font-family: Inter;
    min-height: 100vh;

    .sectionHeading {
        font-weight: 600;
        margin-bottom: 10px;
    }

    .filterTableContainer {
        padding: 20px;
        background: #FFFFFF;

        .table {
            margin-top: 20px;
        }
    }
}
