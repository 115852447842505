.mainContainer {
    background: #f2f2f2;
    padding: 20px;
    font-family: Inter;
    min-height: 100vh;
    font-size: 15px;

    .mainHeading {
        font-weight: 600;
        margin-bottom: 20px;
    }

    p {
        margin: 0;
        line-height: unset;
    }

    .sectionHeading {
        font-weight: 600;
        margin-bottom: 10px;
    }

    .headerText {
        background: #EC7426;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 500;
        border-radius: 10px;
        padding: 10px 20px;
        margin-bottom: 20px;
    }

    .mainTableContainer {
        background: #ffffff;
        padding: 10px;
        margin-bottom: 20px;

        .dataTableContainer {
            border: 1px solid #D9D9D9;
            padding: 0px 20px 20px;
        }
    }

    .listContainer {
        background: #ffffff;
        padding: 10px;
        font-size: 16px;
        margin-bottom: 20px;

        .orderContent {
            border: 1px solid #D9D9D9;
            padding: 20px;
            line-height: 1.8;

            ul {
                margin: 0;
                margin-top: 5px;

                li {
                    span {
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .contactSupportContainer {
        background: #ffffff;
        padding: 10px;

        .contactSupportRow {
            --bs-gutter-x: 0;
            row-gap: 20px;
            border: 1px solid #D9D9D9;
            padding: 20px;

            .contactSupportColumn {
                padding-right: 20px;

                .contactDetailContainer {
                    font-size: 15px;
                    font-weight: 500;
                    height: 100%;

                    .contactTitle {
                        color: #EC7426;
                        font-size: 16px;
                    }

                    .contactDetails {
                        padding: 10px;

                        .contactContentRow {
                            --bs-gutter-x: 0;
                            margin-bottom: 5px;

                            .contactContentValue {
                                font-weight: 400;
                            }
                        }
                    }
                }
            }
        }
    }
}
