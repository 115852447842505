.label {
  background-color: #fbbd34;
  color: #fff;
  text-align: center;
  font-weight: 600;
  font-size: 1.25rem;
}

.card {
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
  cursor: pointer;
}
