.voucherDetailsContainer {
  background: #f2f2f2;
  min-height: 100dvh;
  padding: 10px;

  .voucherDetailsWrapper {
    padding: 0 20px 20px;

    .heading {
      font-weight: 600;
      padding: 10px 20px;
      background-color: #FBBD34;
      font-size: 1.25rem;
      color: #ffffff;
    }

    .text-orange {
      color: #EC7426;
    }
  }
}
