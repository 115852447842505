.mainContainer {
    font-family: "Inter";
    background-color: #f2f2f2;
    min-height: 100vh;
    padding: 20px;

    .mainHeading {
        font-weight: 600;
        margin-bottom: 20px;
        text-align: center;
    }

    p {
        margin: 0;
        line-height: unset;
    }

    .orderDetailWrapper {
        --bs-gutter-x: 0;
        background-color: #FFFFFF;
        box-shadow: 0px 0px 17.9px 0px #00000017;
        padding: 30px;

        .infoCardRow {
            --bs-gutter-x: 0;
            margin-bottom: 40px;
            display: flex;
            align-items: flex-start;

            .InfoCardsConatainer {
                display: flex;
                gap: 20px;
                flex-direction: column;
                padding: 0 60px;
            }

            .actionBtnsColumn {
                display: flex;
                justify-content: flex-end;
                padding: 20px 100px 0;

                .actionBtnsContainer {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    width: 220px;

                    .sectionHeading {
                        font-weight: 600;
                        margin-bottom: 20px;
                        color: #5D5D5D;
                    }
                }
            }
        }

        .dataTableRow {
            --bs-gutter-x: 0;
            background-color: #FFFFFF;
            box-shadow: 0px 2px 30px 0px #00000012;
            margin-bottom: 30px;
        }
    }
}

@media (max-width: 1399px) {
    .mainContainer {
        .orderDetailWrapper {
            .infoCardRow {
                .InfoCardsConatainer {
                    padding: 0 20px;
                }

                .actionBtnsColumn {
                    justify-content: flex-start;
                    padding: 50px 20px 0;
                }
            }
        }
    }
}
