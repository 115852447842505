.backButton {
    margin: 10px 20px 25px;

    button {
        font-size: 16px;
        color: #D9D9D9;
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        text-decoration: none;

        &:hover {
            color: #838383;
        }
        &:active {
            color: #838383 !important;
        }
        span {
            margin-left: 5px;
        }
    }
}
