.designGrid {
    --bs-gutter-x: 0;
    row-gap: 30px;

    .imageDesignCol {
        display: flex;
        justify-content: center;
        padding-right: 20px;

        .imgWrapper {
            height: 200px;
            max-width: 100%;
            overflow: hidden;
            margin-bottom: 10px;

            .designImage {
                height: 100%;
                max-height: 100%;
                object-fit: cover;
                max-width: 100%;
            }
        }
    }
}
