.color-div {
  width: 8px;
  height: 8px;
  background-color: #2D4445;
  border-radius: 100%;
}

.green-bg {
  background-color: #B2FF98E5;
  padding-inline: .5rem;
  padding-block: .1rem;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
}

.text-gray {
  color: #8E95A9;
  font-size: 14px;
}