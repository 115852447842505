.mainPerformanceContainer {
    font-family: Inter;
    margin: 10px 0;

    .mainHeading {
        font-weight: 600;
        margin-bottom: 10px;
    }

    .sectionHeading {
        font-weight: 600;
        margin-bottom: 10px;
    }

    .selectBrandInput {
        --bs-gutter-x: 0;
        display: flex;
        align-items: center;
        row-gap: 5px;

        label {
            font-size: 15px;
            font-weight: 400;
            color: #1A2425;
            margin: 0;
            padding-right: 30px;
        }

        select {
            border: 1px solid #1A2425;
            border-radius: 5px;
            background-color: transparent;

            &::placeholder {
                color: #D9D9D9;
            }
        }
    }

    .overviewReviewsRow {
        --bs-gutter-x: 0;
        margin: 30px 0;
        row-gap: 20px;

        .brandDetailColumn {
            padding-right: 20px;
            display: flex;
            flex-direction: column;

            .detailBox {
                background-color: #FFFFFF;
                border-radius: 10px;
                padding: 30px;
                height: 100%;

                .columnDataRow {
                    --bs-gutter-x: 0;
                    margin-bottom: 6px;
                    font-size: 16px;
                    font-weight: 400;
                    gap: 10px;

                    .columnName {
                        font-weight: 500;
                    }
                }
            }
        }

        .recentReviewsColumn {
            .reviewsWrapper {
                --bs-gutter-x: 0;
                display: flex;
                gap: 10px;

                .backArrowButton {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                }

                .arrowButton {
                    background-color: transparent !important;
                    border: none;
                    color: black !important;
                    padding: 0;
                    border-radius: 30px;

                    &:hover {
                        box-shadow: 0 8px 25px -8px #82868b;
                    }
                }

                .reviewContentColumn {
                    background-color: #ffffff;
                    border-radius: 10px;
                    padding: 15px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    p {
                        margin: 0;
                        line-height: unset;
                    }

                    .recentReviewHeader {
                        margin-bottom: 18px;

                        .reviewerName {
                            font-weight: 600;

                            span {
                                color: #D9D9D9;
                                font-size: 12px;
                                font-weight: 500;
                                margin-left: 10px;
                            }
                        }
                    }

                    .reviewText {
                        font-size: 14px;
                        font-weight: 400;
                        margin-bottom: 18px;
                    }

                    .reviewerRating {
                        display: flex;
                        align-items: center;

                        span {
                            font-size: 16px;
                            font-weight: 500;
                            margin-left: 5px;
                        }
                    }
                }

                .forwardArrowButton {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                }
            }
        }
    }

    .salesDataRow {
        --bs-gutter-x: 0;
        margin-top: 30px;

        .salesDataColumn {
            background-color: #ffffff;
            text-align: center;
            height: 300px;
            align-content: center;
            border-radius: 10px;

            .graphText {
                color: #EC7426;
                margin: 0;
            }
        }
    }
}

@media (max-width: 1399px) {
    .mainPerformanceContainer {
        .overviewReviewsRow {
            .brandDetailColumn {
                padding: 0;
            }
        }
    }
}
