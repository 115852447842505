$radius-value: 12px;

.light-blue-bg {
  border: 1px solid #41B8E4;
  background-color: #41B8E42E;
  border-radius: $radius-value;
}

.text-blue {
  color: #1E00FF;
}

.green-border {
  border: 1px solid #05D029;
  border-radius: $radius-value;

  .green-bg {
    background-color: #9CFFAEBD;
  }
}

.text-yellow {
  color: #FBBD34;
  font-weight: 500;
  font-size: 16px;
}

.text-blue-light {
  color: #41B8E4;
  font-weight: 500;
  font-size: 16px;
}

.text-orange {
  color: #EC7426;
  font-weight: 500;
}