.conversionRateContainer {
    background-color: #F5F5F5;
    border-radius: 20px;
    padding: 40px 20px;

    .conversionRateRow {
        margin: 0;
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        .circularProgressBarColumn {
            max-width: 250px;
        }
    }
}
