.mainShippingContainer {
    .sectionHeading {
        font-weight: 600;
        margin: 0;
    }

    p {
        margin: 0;
        line-height: unset;
    }

    .shippingHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        .btnConatiner {
            button {
                font-size: 18px;
                cursor: pointer;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }

            .addNewBtn {
                color: #41B8E4;
            }

            .changeBtn {
                color: #EC7426;
            }
        }
    }

    .shippingLocationContainer {
        background-color: #FFFFFF;
        display: flex;
        gap: 20px;
        padding: 16px;
        border: 1px solid #F2F2F2;
        filter: drop-shadow(0px 1px 30px #00000012);
        margin-bottom: 20px;

        .personName {
            font-size: 20px;
        }
    }

    .productsHeader {
        margin-bottom: 10px;
    }

    .productDetailContainer {
        background-color: #FFFFFF;
        filter: drop-shadow(0px 1px 30px #00000012);
        padding: 20px;

        .productDetailRow {
            row-gap: 30px;

            .cartProductRow {
                padding-right: 10px;

                .storeWrapper {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    padding: 0 0 5px 5px;

                    .storeIcon {
                        margin-bottom: 3px;
                    }

                    .storeName {
                        font-size: 18px;
                        font-weight: 400;
                    }

                    .storeArrow {
                        margin-bottom: 2px;
                    }
                }

                .cartItemDetail {
                    display: flex;
                    gap: 20px;
                    padding-right: 10px;

                    img {
                        width: 122px;
                        height: 128px;
                    }

                    .cartItemProductSpec {
                        display: flex;
                        flex-direction: column;
                        gap: 20px;

                        .productName {
                            font-size: 20px;
                            margin-bottom: 10px;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }

                        .productSpec {
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }

                        .productQuantity {
                            color: #9B9B9B;
                        }
                    }
                }

                .productPrice {
                    color: #FBBD34;
                    font-size: 30px;
                    font-weight: 500;
                    padding-left: 10px;
                }
            }

            .deliveryChargesContainer {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
                gap: 10px;
            }
        }
    }

    .paymentBtnContainer {
        display: grid;
        place-items: end;

        .paymentBtn {
            background-color: #41B8E4 !important;
            border: 1px solid #41B8E4;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;
            font-weight: 500;
            padding: 10px 20px;
            margin-top: 20px;

            &:hover {
                box-shadow: 0 8px 25px -8px #41B8E4;
            }

            .arrowIcon {
                margin-left: 6px;
            }
        }
    }
}

// Media Queries for responsive behavior
@media (max-width: 767px) {
    .mainShippingContainer {
        .shippingHeader {
            align-items: flex-start;
            flex-direction: column;
            .btnConatiner {
                align-self: flex-end;
            }
        }
        .productDetailContainer {
            .productDetailRow {
                .cartProductRow {
                    align-items: flex-end;
                    .cartItemDetail {
                        flex-direction: column;
                        img {
                            width: 100px;
                            height: 106px;
                        }
                        .cartItemProductSpec {
                            .productName {
                                font-size: 18px;
                            }
                        }
                    }
                    .productPrice {
                        font-size: 24px;
                    }
                }
            }
        }
    }
}
