.mainContainer {
    background: #f2f2f2;
    padding: 20px;
    font-family: Inter;
    min-height: 100vh;
    font-size: 15px;

    .mainHeading {
        font-weight: 600;
        margin-bottom: 20px;
    }

    p {
        margin: 0;
        line-height: unset;
    }

    .sectionHeading {
        font-weight: 600;
        margin-bottom: 10px;
    }

    .metricsRow {
        --bs-gutter-x: 0;
        background: #FFFFFF;
        padding: 20px;
        row-gap: 8px;
        margin-bottom: 20px;

        .metricsColumn {
            padding-right: 20px;
            display: grid;

            .infoCard {
                border: 1px solid #D9D9D9;
                padding: 10px;
                border-radius: 10px;
                text-align: center;
                font-size: 15px;
                font-weight: 500;

                span {
                    display: block;
                    font-weight: 600;
                    color: #EC7426;
                }
            }
        }
    }

    .salesReportRow {
        --bs-gutter-x: 0;
        margin-bottom: 20px;

        .graphContainer {
            background: #FFFFFF;
            padding: 20px 40px;
            text-align: center;
            height: 100%;

            h5 {
                font-weight: 600;
                margin-bottom: 20px;
            }

            .graphWrapper {
                background: #F2F2F2;
                padding: 120px 160px;
            }
        }

        .firstGraphColumn {
            padding-right: 10px;
        }

        .secondGraphColumn {
            padding-left: 10px;
        }
    }

    .scheduleContainer {
        background: #ffffff;
        font-size: 15px;
        padding: 10px;
        margin-bottom: 20px;

        .table {
            padding: 20px;
            border: 1px solid #d9d9d9;
        }
    }

    .stockMovementContainer {
        --bs-gutter-x: 0;
        margin-bottom: 20px;

        .graphContainer {
            background: #FFFFFF;
            padding: 20px 40px;

            h5 {
                font-weight: 600;
                margin-bottom: 10px;
            }

            .graphWrapper {
                padding: 120px 400px;
                text-align: center;
            }
        }
    }

    .fullGraphContainer {
        --bs-gutter-x: 0;
        margin-bottom: 20px;

        .graphContainer {
            background: #FFFFFF;
            padding: 20px 40px;

            h5 {
                font-weight: 600;
                margin-bottom: 10px;
            }

            .graphWrapper {
                background: #F2F2F2;
                padding: 120px 450px;
                text-align: center;
            }
        }
    }

    .reportBuilderContainer {
        background: #ffffff;
        font-size: 15px;
        padding: 0;

        .sectionTitle {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #D9D9D9;
            color: #1A2425;
            padding: 10px;

            .sectionSubHeading {
                font-weight: 600;
                margin: 0;
            }
        }

        .informationContainer {
            padding: 10px;

            .informationRow {
                --bs-gutter-x: 0;
                padding: 20px;
                border: 1px solid #d9d9d9;

                label {
                    font-size: 15px;
                    font-weight: 500;
                    color: #1A2425;
                    margin: 0;
                }

                .formGroupRow {
                    // --bs-gutter-x: 0;
                    display: flex;
                    align-items: center;

                    .secondColumn {
                        display: flex;
                        align-items: center;
                    }

                    .dateSeparator {
                        margin: 0 5px;
                        font-weight: bold;
                    }
                }

                .separateFormGroupRow {
                    --bs-gutter-x: 0;
                    display: flex;

                    .checkboxGroup {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        column-gap: 30px;
                        row-gap: 10px;
                        margin-top: 20px;

                        label {
                            font-weight: 400;
                        }

                        .checkBox {
                            border: 1px solid #1A2425;
                            border-radius: 5px;
                            margin: 1px 0 0 8px;
                            padding: 9px 12px;
                        }
                    }

                    .filtersContainer {
                        margin-top: 20px;
                    }

                    .formGroup {
                        margin-bottom: 10px !important;
                    }
                }

                .actionBtnContainer {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    padding: 0;

                    button {
                        background: #41B8E4 !important;
                        border: 1px solid #41B8E4 !important;
                        color: #FFFFFF !important;
                        border-radius: 8px;
                        font-size: 14px;
                        font-weight: 400;
                        padding: 8px 20px;

                        &:hover {
                            box-shadow: 0 8px 25px -8px #41B8E4;
                        }
                    }
                }

                .previewContainer {
                    padding: 0;
                    padding-left: 40px;

                    .previewBox {
                        padding: 100px;
                        text-align: center;
                        background-color: #F2F2F2;
                    }

                    .downloadDropdownContainer {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        padding: 0;
                        margin-top: 20px;

                        .downloadDropdown {
                            .downloadDropdownBtn {
                                background-color: transparent !important;
                                color: #324647 !important;
                                border: 1px solid #000000;
                                border-radius: 8px;
                                font-size: 15px;
                                font-weight: 500;

                            }

                            .downloadDropdownBtn::after {
                                margin-left: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}
