.filterContainer {
    background-color: #F2F2F2;
    padding: 12px 16px;

    .filterRow {
        --bs-gutter-x: 0;
        display: flex;
        align-items: center;
        row-gap: 10px;

        .filterLabel {
            font-weight: 600;
        }

        .filterFieldsRow {
            --bs-gutter-x: 0;
            row-gap: 10px;

            .filterInput {
                background-color: #FFFFFF;
                border: none;
                border-radius: 0;
                // min-width: 150px;
                width: 100%;

                &::placeholder {
                    color: #D9D9D9;
                }
            }

            select {
                background-position: right 8px center;
                cursor: pointer;
                padding: 6px 26px 6px 12px;

                option {
                    color: #1A2425;

                    &:first-child {
                        color: #D9D9D9;
                    }
                }
            }

            .placeholderActive {
                color: #D9D9D9;
            }

            .dateInputWrapper {
                position: relative;

                .datePlaceholder {
                    color: transparent !important;
                    text-indent: -9999px
                }
            }

            input[type="date"] {
                text-indent: 0;

                &::-webkit-calendar-picker-indicator {
                    cursor: pointer;
                }
            }

            .datePlaceholderText {
                position: absolute;
                left: 12px;
                top: 50%;
                transform: translateY(-50%);
                color: #D9D9D9;
                pointer-events: none;
            }
        }

        .buttonsColumn {
            display: flex;
            align-items: center;
            justify-content: end;
            gap: 10px;
            padding-right: 10px;

            .applyButton {
                background-color: #EC7426 !important;
                border: none;
                color: #FFFFFF !important;
                padding: 6px 18px;
                cursor: pointer;

                &:hover {
                    box-shadow: 0 8px 15px -8px #EC7426 !important;
                }
            }

            .clearButton {
                text-decoration: none;
                color: #1A2425;
                padding: 4px;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .noFilters {
        text-align: center;
        color: #666;
    }
}

@media (max-width: 1499px) {
    .filterContainer {
        .filterRow {
            .buttonsColumn {
                padding: 0;
            }
        }
    }
}

@media (max-width: 1399px) {
    .filterContainer {
        .filterRow {
            .buttonsColumn {
                padding-right: 0.5rem;
            }
        }
    }
}
