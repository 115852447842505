.multiSelectContainer {
    --bs-gutter-x: 0;
    display: flex;
    align-items: flex-start;
    row-gap: 5px;

    label {
        font-size: 15px;
        font-weight: 400;
        color: #1A2425;
        margin: 0;
        padding-top: 7px;
    }

    .multiSelectDropdown {
        .multiSelectToggle {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #ffffff !important;
            color: #1A2425 !important;

            &:hover {
                box-shadow: none !important;
            }
        }

        .multiSelectMenu {
            max-height: 300px;
            overflow-y: auto;
            width: 100%;
        }
    }

    .selectedOptions {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 2px 0 0 40px;

        .selectedBadge {
            display: flex;
            align-items: center;
            background: #41B8E4;
            color: #ffffff;
            margin-bottom: 5px;
            padding: 6px 10px;
            border-radius: 8px;

            .removeIcon {
                margin-left: 0.3rem;
                cursor: pointer;
                font-size: 1rem;
            }
        }
    }
}

@media (max-width: 991px) {
    .multiSelectContainer {
        label {
            padding: 0;
        }

        .selectedOptions {
            flex-direction: row;
            align-items: center;
            margin: 0;
            flex-wrap: wrap;
            row-gap: 5px;

            .selectedBadge {
                margin: 0;
                margin-right: 5px;
            }
        }
    }
}
