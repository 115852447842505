.mainContainer {
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    .productDetailsContainer {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid rgb(217, 217, 217);
        margin: 0;

        .detailsContainer {
            margin-top: 10px;

            .productInfo {
                padding: 20px;
            }
        }

        .categoriesLikedProducts {
            border-left: 1px solid #d9d9d9;
            padding-left: 16px;
        }
    }

    .sectionHeading {
        font-family: Inter;
        font-size: 24px;
        font-weight: 600;
        padding: 20px 10px;
    }
}

.customPopover {
    min-width: 450px;

    @media (max-width: 1199px) {
        min-width: 350px;
    }

    @media (max-width: 480px) {
        min-width: auto;
    }
}

@media (max-width: 1199px) {
    .mainContainer {
        .productDetailsContainer {
            .detailsContainer {
                margin-top: 20px;
            }
        }

        .sectionHeading {
            font-size: 20px;
        }
    }
}

@media (max-width: 991px) {
    .mainContainer {
        .productDetailsContainer {
            .categoriesLikedProducts {
                border: none;
                padding: 0;
            }
        }
    }
}

@media (max-width: 768px) {
    .mainContainer {
        .productDetailsContainer {
            .detailsContainer {
                .productInfo {
                    padding: 10px;
                }
            }
        }

        .sectionHeading {
            font-size: 18px;
        }
    }
}
