.cardMainSection {
    box-shadow: 3px 3px 6px #00000040;
    text-align: center;

    img {
        width: 400px;
        max-width: 100%;
        height: auto;
    }

    p {
        margin: 0;
        line-height: unset;
    }

    .cardContent {
        padding: 10px;
        font-weight: 600;
    }
}
