.mainContainer {
    .sectionHeading {
        font-weight: 600;
        margin-bottom: 10px;
    }

    .scheduleContainer {
        background: #ffffff;
        font-size: 15px;
        padding: 10px;
        margin-bottom: 30px;

        .scheduleRow {
            --bs-gutter-x: 0;
            padding: 20px;
            border: 1px solid #d9d9d9;

            .table {
                margin-top: 20px;
            }
        }
    }
}
