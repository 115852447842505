.wrapper {
  background-color: #f2f2f2;
  min-height: 100vh;
  padding: 1.25rem;

  .stats {
    border: 1px solid black;
    border-radius: 10px;
    background-color: #fff;
    padding: 1rem;
    height: 100%;
  }

  .text-yellow {
    color: #EC7426;
    padding-left: 10px;
  }

  .outer-wrapper {
    background-color: #ffffff;
    padding: 1rem;
  }
}
