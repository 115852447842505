:global {
  label {
    font-weight: 400;
  }
}

.heading {
  font-weight: 600;
  padding: 1rem;
  background-color: #FBBD34;
  font-size: 1.25rem;
  color: #ffffff;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  background-color: #ffffff;
  padding: 1rem;
  border: 1px solid #D9D9D9;
}

.no-profile-pic {
  position: relative;
  width: fit-content;

  .profile {
    width: 90px;
    height: 90px;
    max-width: 90px;
    max-height: 90px;
    border-radius: 100%;
  }

  .plus {
    position: absolute;
    bottom: 0;
    right: 0;

    cursor: pointer;
  }

  .delete {
    position: absolute;
    bottom: 0;
    right: 0;
    color: red;

    cursor: pointer;
  }
}

.label {
  color: #9B9B9B;
  font-weight: 600;
  font-style: 12px;
}