.container {
  .total {
    padding: 10px 0;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: start;
      gap: 10px;
      margin-bottom: 16px;
    }

    .heading {
      background: #fbbd34;
      color: #ffffff;
      font-weight: 500;
      font-size: 16px;
      padding: 10px 20px;
    }
  }

  .dropdown {
    padding: 0.1rem;
    border: 1px solid #1a2425;
  }

  .productImg {
    width: auto;
    max-width: 100%;
    height: 40px;
  }

  .priceContainer {
    font-size: 14px;
    padding: 10px 0;

    .priceShippingRow {
      padding: 6px 0;
      margin-bottom: 5px;

      .price {
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        padding: 5px;
        margin-right: 6px;
      }
    }

    .priceDetails {
      font-size: 12px;
    }
  }

  .actionDropdown {
    .dropdownButton {
      background-color: transparent !important;
      color: #1a2425 !important;
      border: none;
      padding: 0;
      font-size: 13px;

      &:hover {
        box-shadow: none !important;
      }
    }
  }
}

.ready-to-ship {
  cursor: pointer;

  transition: background-color 300ms ease-in-out;

  &:hover {
    background-color: #d9d9d9;
  }
}
