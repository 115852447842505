.tab {
  background-color: #ffffff;
  padding-block: 0.8rem;
  padding-inline: 1rem;
  font-weight: 500;
  font-size: 1.25rem;
  cursor: pointer;
  transition: padding-block 0.3s ease;
  border-block: none;
}

.border-wrapper {
  padding-block: 4px;
  background-color: transparent;

  transition: background-color .3s ease-in;

  &:hover:not(.active) {
    background-color: #fbbd34;
  }
}

.active {
  background-color: #fbbd34;
}
