.heading {
  font-weight: 600;
  padding: 1rem;
  background-color: #fbbd34;
  font-size: 1.25rem;
  color: #ffffff;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: #ffffff;
  padding: 1rem;
  border: 1px solid #d9d9d9;
}

.text-orange {
  color: #ec7426;
}

.BtnContainer {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  .btn {
    padding: 0.5rem 1rem;
    border: 1px solid black;
    border-radius: 0.5rem;
    background-color: #ffffff;
    cursor: pointer;
  }

  .Btn {
    background: #41b8e4 !important;
    border: 1px solid #41b8e4 !important;
    color: #ffffff !important;
    font-size: 14px;
    font-weight: 400;
    padding: 10px 20px;

    &:hover {
      box-shadow: 0 8px 25px -8px #41b8e4;
    }
  }
}

.codeEditor {
  height: 100%;
  width: 50%;
  border-radius: 0.625rem;
  border: 1px solid black;
  display: flex;
  flex-direction: column-reverse;

  >:first-child {
    border: none;
    border-top: 1px solid black;
  }

  >:last-child {
    border: none;
  }
}

@media (max-width: 1199px) {
  .codeEditor {
    width: 100%;
  }
}
