.fileDisplay {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 12px;
  background-color: #f5f5f5;
  border: 1px solid #d1d1d1;
  border-radius: 6px;
  cursor: pointer;
  min-height: 38px;

  span {
    color: #333;
    font-size: 16px;
  }

  .icon {
    color: #666;
    font-size: 20px;
  }

  &:hover {
    background-color: #e9e9e9;
  }
}
