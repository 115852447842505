.mainContainer {
    font-family: "Inter";
    background-color: #f2f2f2;
    min-height: 100vh;
    padding: 20px;

    p {
        margin: 0;
        line-height: unset;
    }

    .squareFilterBarWrapper {
        background-color: #FFFFFF;
        box-shadow: 0px 0px 17.9px 0px #00000017;
        margin-bottom: 20px;
    }
}
