/*

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

// .category-section {
//   display: flex;
//   flex-direction: column;  // Stack items vertically
//   justify-content: space-between; // Distribute space evenly
//   height: auto; // Make sure the section takes full height if necessary
// }


.col-style{
  display:flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.row-style{
  display:flex;
  flex-direction: row;
  // flex-wrap: wrap;
  align-items: center;
}

.category-list {
  list-style: none; /* Removes bullet points */
  padding-left: 0;  /* Removes left padding */
  margin: 0;        /* Removes default margin */
}

.category-item {
  padding: 0;  /* Removes any internal padding */
  margin: 0;   /* Removes any margin between items */
}



/* Styles for Range Slider Component */
.range-slider {
  width: 100%;
  text-align: center;
  position: relative;
}

.rangeValues {
  display: block;
}

/* General styles for range input */
input[type=range] {
  -webkit-appearance: none; /* Remove default styling */
  width: 100%; /* Full width */
  position: absolute; /* Positioning */
  left: 0; /* Align left */
}

/* WebKit browsers (Chrome, Safari) track styling */
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px; /* Height of the track */
  background-color: #FBBD34; /* Default track color */
  border: none; /* No border */
  border-radius: 3px; /* Rounded corners */
}

/* WebKit browsers (Chrome, Safari) thumb styling */
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Remove default styling */
  border: none; /* No border */
  height: 16px; /* Thumb height */
  width: 16px; /* Thumb width */
  border-radius: 50%; /* Rounded thumb */
  background-color: #FBBD34; /* Thumb color */
  margin-top: -4px; /* Adjust position */
  cursor: pointer; /* Pointer cursor */
  position: relative; /* Positioning */
  z-index: 1; /* Above the track */
}

/* Focus state for input */
input[type=range]:focus {
  outline: none; /* Remove outline */
}

/* Focus state for WebKit track */
input[type=range]:focus::-webkit-slider-runnable-track {
  background-color: #FBBD34; /* Track color on focus */
}

/* Firefox track styling */
input[type=range]::-moz-range-track {
  width: 100%;
  height: 2px; /* Height of the track */
  background-color: #FBBD34; /* Default track color */
  border: none; /* No border */
  border-radius: 3px; /* Rounded corners */
}

/* Firefox thumb styling */
input[type=range]::-moz-range-thumb {
  border: none; /* No border */
  height: 16px; /* Thumb height */
  width: 16px; /* Thumb width */
  border-radius: 50%; /* Rounded thumb */
  background-color: #FBBD34; /* Thumb color */
}

/* Hide outline behind the border for Firefox */
input[type=range]:-moz-focusring {
  outline: 1px solid white;
  outline-offset: -1px; /* Offset for focus outline */
}

/* IE track styling */
input[type=range]::-ms-track {
  width: 100%;
  height: 5px; /* Height of the track */
  background-color: transparent; /* Transparent background */
  border-color: transparent; /* No border */
  border-width: 6px 0; /* Leave room for thumb */
  color: transparent; /* Remove tick marks */
  z-index: -4; /* Below other elements */
}

/* IE lower fill color */
input[type=range]::-ms-fill-lower {
  background-color: #FBBD34; /* Lower fill color */
  border-radius: 10px; /* Rounded corners */
}

/* IE upper fill color */
input[type=range]::-ms-fill-upper {
  background-color: #FBBD34; /* Upper fill color */
  border-radius: 10px; /* Rounded corners */
}

/* IE thumb styling */
input[type=range]::-ms-thumb {
  border: none; /* No border */
  height: 16px; /* Thumb height */
  width: 16px; /* Thumb width */
  border-radius: 50%; /* Rounded thumb */
  background-color: #FBBD34; /* Thumb color */
}

/* Focus state for IE lower fill */
input[type=range]:focus::-ms-fill-lower {
  background-color: #FBBD34; /* Fill color on focus */
}

/* Focus state for IE upper fill */
input[type=range]:focus::-ms-fill-upper {
  background-color: #FBBD34; /* Fill color on focus */
}

.products-scroll::-webkit-scrollbar {
  height: 8px;
}

.products-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.products-scroll::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.products-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 999; /* Ensure it appears above other elements */
}

.sidebar-content {
  position: absolute;
  top: 0;
  width: 250px; /* Adjust width as needed */
  height: 100%;
  background: white; /* Background color for sidebar */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease;
}

.sidebar-button {
  position: fixed;
  top: 20px; /* Adjust as needed */
  left: 20px; /* Adjust as needed */
  z-index: 1000; /* Ensure it appears above other elements */
}

.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}


// PaymentOptionsInfo.scss
.custom-tab {
 
  padding: 1rem;
  background-color: #F2F2F2;
  border-radius: 10px;
  margin: 0.5rem;
  // margin-right: 1rem;
  // width: 100%;
  min-height: 102px;
  height: 100%;
  

  &.active {
    border: 1px solid #FBBD34; // Change this to your desired active color
    // color: white; // Text color for the active tab
  }

  &:hover {
    // background-color: #e0e0e0; // Hover effect color
    cursor:pointer;
  }
}

.custom-credit-cards{
  padding: 1rem;
  background-color: #F2F2F2;
  border-radius: 10px;
  margin: 0.5rem;
  // margin-right: 1rem;
  // width: 100%;
  min-height: 102px;
  height: 100%;
  
  width: 232px;
  display: flex;
  align-items: center;

  &.active {
    border: 1px solid #FBBD34; // Change this to your desired active color
    // color: white; // Text color for the active tab
  }

  &:hover {
    // background-color: #e0e0e0; // Hover effect color
    cursor:pointer;
  }
}


.custom-card{
  width: 232px;
  display: flex;
  align-items: center;
  flex-direction: column;
}


.role-card {
  // width: 200px;
  margin: 10px;
  padding: 10px;
  // background-color: #f8f8f8;
  // border: 1px solid #ccc;
  // border-radius: 8px;
  transition: transform 0.3s ease;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
  border-radius: 15px; 
}

.role-card-image {
  width: 100%;
  height: 230px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
  border-radius: 15px;
  transition: transform 0.3s ease;
  
}

.role-card:hover  {
  transform: scale(1.1);
}

.role-card h3 {
  margin: 0;
  padding: 0;
  font-size: 1.2em;
}

.role-card p {
  margin: 0;
  padding: 10px 0 0;
  font-size: 1em;
}
.role-app {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 63.8vh;
  height:100%;
  background-color: #333333;
}
.role-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}


// switch

.dropdown-item.no-background-change {
  background-color: transparent !important; /* Prevent background color change */
}

.dropdown-item.no-background-change:hover,
.dropdown-item.no-background-change:focus {
  background-color: transparent !important; /* Maintain transparent background on hover/focus */
}

.form-check {
  margin-left: auto; /* Aligns the switch to the right */
}

.form-check-input:checked {
  background-color: #FBBD34;
  border-color: #FBBD34;
}

.StepButtonContent-d1-0-2-7.completed {
  display: none;
}
.StepButtonContent-d1-0-2-7.active {
  display: none;
}
.StepButtonContent-d1-0-2-7 {
  display: none;
}


.StepButtonContent-d3-0-2-13.completed {
  display: none;
}
.StepButtonContent-d3-0-2-13.acitve {
  display: none;
}
.StepButtonContent-d3-0-2-13 {
  display: none;
}


.StepButtonContent-d5-0-2-21.completed {
  display: none;
}
.StepButtonContent-d5-0-2-21.active {
  display: none;
}
.StepButtonContent-d5-0-2-21 {
  display: none;
}


.StepButtonContent-d7-0-2-27.completed {
  display: none;
}
.StepButtonContent-d7-0-2-27.active {
  display: none;
}
.StepButtonContent-d7-0-2-27 {
  display: none;
}


.StepButtonContent-d9-0-2-33 {
  display: none;
}
.StepButtonContent-d9-0-2-33.active {
  display: none;
}
.StepButtonContent-d9-0-2-33.completed {
  display: none;
}


