.mainFBMDashboardContainer {
    background: #f2f2f2;
    padding: 20px;
    font-family: Inter;
    min-height: 100vh;
    font-size: 15px;

    .mainHeading {
        font-weight: 600;
        margin-bottom: 30px;

        @media (max-width: 992px) {
            margin: 0;
        }
    }

    p {
        margin: 0;
        line-height: unset;
    }

    .sectionHeading {
        font-weight: 600;
        margin-bottom: 10px;
        margin-top: 1rem;
    }

    .tablesDataRow {
        --bs-gutter-x: 0;
        margin-bottom: 20px;

        .tableDataFirstColumn {
            padding-right: 10px;

            @media (max-width: 992px) {
                padding: 0;
            }

            .mainTableContainer {
                background: #ffffff;
                padding: 10px;

                .dataTableContainer {
                    border: 1px solid #D9D9D9;
                    padding: 10px;
                }
            }

            &:nth-child(2) {
                padding: 0;
                padding-left: 10px;

                @media (max-width: 992px) {
                    padding: 0;
                }
            }
        }

        .tableDataSecondColumn {
            display: flex;
            flex-direction: column;
            padding-left: 10px;

            @media (max-width: 992px) {
                padding: 0;
            }

            .secondDataContainer {
                background: #ffffff;
                padding: 10px;
                height: 100%;

                p {
                    border: 1px solid #D9D9D9;
                    height: 100%;
                    padding: 30px;
                    text-align: center;
                }
            }
        }
    }
}
