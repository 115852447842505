.mainContainer {
    font-family: "Inter";
    margin-bottom: 20px;

    .mainHeading {
        font-weight: 600;
        margin-bottom: 30px;
        text-align: center;
        padding: 10px;

        span {
            background-color: #9B9B9B;
            color: #FFFFFF;
            padding: 8px 30px;
            border-radius: 40px;
        }
    }

    p {
        margin: 0;
        line-height: unset;
    }

    .orderDetailWrapper {
        --bs-gutter-x: 0;
        background-color: #FFFFFF;
        box-shadow: 0px 0px 17.9px 0px #00000017;
        padding: 30px;

        .submitButton {
            text-align: center;
            margin: 60px 0 30px;
        }
    }
}
