.brandsInfoContainer {
    .sectionHeading {
        font-weight: 600;
        margin-bottom: 16px;
    }

    .brandStats {
        --bs-gutter-x: 0;
        margin-bottom: 30px;

        .statsBox {
            background: #FFFFFF;
            padding: 16px 24px;
            border-radius: 10px;
            font-size: 18px;
            font-weight: 600;

            .statsContent {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 5px;

                span {
                    color: #EC7426;
                }
            }

        }
    }

    .tableContainer {
        background: #FFFFFF;
        padding: 20px;
        border-radius: 10px;

        .brandActionButton {
            padding: 0;
            color: #1A2425;
            text-decoration: none;
            font-size: 13px;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
