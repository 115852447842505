.feeTable {
  width: 100%;
  border-collapse: collapse;
  margin: 0;

  th,
  td {
    padding: 8px 16px;
    text-align: left;
    white-space: nowrap;
  }
}

.headOrange {
  font-weight: 600;
  font-size: 18px;

  th {
    background-color: #EC7426;
    color: #FFFFFF;
  }
}

.dataRow {
  background-color: #FFFFFF;
  font-weight: 500;

  &:not(:last-child) {
    border-bottom: 1px solid #D9D9D9;
  }

  .column {
    border-right: 1px solid #D9D9D9;

    &:last-child {
      border-right: none;
    }
  }
}

@media (max-width: 768px) {

  .feeTable th,
  .feeTable td {
    font-size: 16px;
  }
}
