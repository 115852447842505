.mainContainer {
    font-family: "Inter";
    background-color: #f2f2f2;
    min-height: 100vh;
    padding: 20px;

    .mainHeading {
        font-weight: 600;
        margin-bottom: 20px;
        text-align: center;
    }

    p {
        margin: 0;
        line-height: unset;
    }

    .orderDetailWrapper {
        --bs-gutter-x: 0;
        background-color: #FFFFFF;
        box-shadow: 0px 0px 17.9px 0px #00000017;
        padding: 30px;

        .infoCardRow {
            --bs-gutter-x: 0;
            gap: 20px;
            margin-bottom: 20px;
        }

        .dataTableRow {
            --bs-gutter-x: 0;
            background-color: #FFFFFF;
            box-shadow: 0px 2px 30px 0px #00000012;
            margin-bottom: 30px;
        }
    }
}

@media (max-width: 1399px) {
    .mainContainer {
        .orderDetailWrapper {
            .infoCardRow {
                flex-direction: column;
            }
        }
    }
}
