.dropdown-container {
  position: relative;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.3rem;
  cursor: pointer;
}

.icon-wrapper {
  display: flex;
  align-items: center;
}

.dropdown-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 120%;
  right: -3px;
  background-color: #fff;
  border: 1px solid black;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 200px;
  z-index: 99999;
  font-size: 0.75rem;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  height: auto;
  max-height: 119px;
  overflow-y: auto;
}

.dropdown-item {
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #f2f2f2;
  border-radius: 0.25rem;
  
  &:hover {
    background-color: #f2f2f2;
  }
}

.dropdown-item-no-options {
  padding: 0.25rem 0.5rem;
  background-color: #fff;
  border-bottom: 1px solid #f2f2f2;
  cursor: default;
  color: #888;
  border-radius: 0.25rem;
}
