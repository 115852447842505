.actionDropdown {
  .dropdownButton {
    background-color: transparent !important;
    color: #1a2425 !important;
    border: none;
    padding: 0;
    font-size: 12px;

    &:hover {
      box-shadow: none !important;
    }
  }

  .drop-down-menu {
    
    border: 1px solid black;
    border-radius: 5px;

    .dropdown-item {
      font-size: 0.75rem;
      padding-inline: 0.5rem;

      &:active {
        background-color: #fabc42;
      }
    }
  }
}