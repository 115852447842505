.statusBadge {
    padding: 5px 10px;
    border-radius: 5px;
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 700;
    width: auto;
    min-width: 80px;
    text-align: center;

    &-shipped {
        background-color: #ccf0eb;
        color: #00B69B;
    }

    &-revenue {
        background-color: #BFFFA8;
        color: #000000;
        font-weight: 500;
    }

    &-completed {
        background-color: #ccf0eb;
        color: #00B69B;
    }

    &-in-transit {
        background-color: #f0d4fe;
        color: #BA29FF;
    }

    &-processing {
        background-color: #e0d4fb;
        color: #6226EF;
    }

    &-pending {
        background-color: #dacef6;
        color: #6226EF;
    }

    &-paid {
        background-color: #f6ddff;
        color: #D456FD;
    }

    &-unpaid {
        background-color: #d3ebf4;
        color: #41B8E4;
    }

    &-deactivate,
    &-registered,
    &-expired {
        background-color: #fbd7d4;
        color: #EF3826;
    }

    &-active {
        background-color: #d7e9f3;
        color: #4DAFE2;
    }

    &-inactive {
        background-color: #fbd7d4;
        color: #EF3826;
    }

    &-low {
        background-color: #fcd7d4;
        color: #EF3826;
        border-radius: 15px;
        font-weight: 600;
    }

    &-ample {
        background-color: #d6e9f4;
        color: #4DAFE2;
        border-radius: 15px;
        font-weight: 600;
    }

    &-approved {
        background-color: #fed5d5;
        color: #FF0000;
        border-radius: 15px;
        font-weight: 600;
    }

    &-in-stock {
        background-color: #c1ffb7;
        color: #244517;
        border-radius: 15px;
        font-weight: 600;
    }

    &-assign {
        background-color: #d4ebf4;
        color: #41B8E4;
    }

    &-delivered {
        background-color: #cdf0eb;
        color: #00B69B;
    }

    &-on-hold {
        background-color: #f9e8d8;
        color: #FFA756;
    }

    &-approved {
        background-color: #ccf0eb;
        color: #00B69B;
    }

    &-upcoming {
        background-color: #d9f1fa;
        color: #41B8E4;
    }

    &-active {
        background-color: #c6ebe5;
        color: #00B69B;
    }

    &-inactive {
        background-color: #f9e5d8;
        color: #FD9A56;
    }

    &-scheduled {
        background-color: #d9f1fa;
        color: #41B8E4;
    }

    &-on-going {
        background-color: #c6ebe5;
        color: #00B69B;
    }
}
