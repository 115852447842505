.label {
  font-weight: 600;
  font-size: 2.25rem;
  margin-inline-start: 1rem;
}

.wrapper {
  border-radius: 0.625rem;
  padding-top: 1rem;
  max-width: 425px;
}

.btn {
  color: #fff;
  font-size: 1.25rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border-radius: 2.125rem;
}

.btn-global {
  background-color: #ec7426;
}

.btn-seller {
  background-color: #afe835;
}

.btn-hire-model {
  background-color: #c15cff;
}

.tag {
  font-weight: 600;
  font-size: 1.5rem;
  color: #fff;
  border-top-left-radius: 3.125rem;
  border-bottom-left-radius: 3.125rem;
  padding: 1rem;
}

.tag-fbm {
  background-image: linear-gradient(to right, #afe835, #bffd39);
}

.tag-global {
  background-image: linear-gradient(to right, #ec7426, #ff934d);
}

.tag-hire-model {
  background-image: linear-gradient(to right, #c15cff, #cf80ff);
}
