.mainOrderPlacedContainer {
    font-family: "Inter";
    font-weight: 500;
    padding: 40px;

    p,
    h4 {
        margin: 0;
        line-height: unset;
    }

    .sectionHeading {
        font-weight: 600;
        margin-bottom: 10px;
    }

    .orderConfirmWrapper {
        display: grid;
        place-items: center;
        gap: 10px;
        text-align: center;
        margin-bottom: 40px;

        .orderPlacedText {
            font-size: 18px;
        }

        .btnConatiner {
            display: flex;
            flex-direction: column;
            margin-top: 30px;
            gap: 10px;

            button {
                font-size: 20px;
                cursor: pointer;
                text-decoration: none;
            }

            .receiptBtn {
                color: #1A2425;
                border: 1px solid #9B9B9B;
                border-radius: 10px;
                font-weight: 400;
                padding: 6px 14px;

                &:hover {
                    box-shadow: 0 8px 25px -8px #10182833;
                }
            }

            .shoppingBtn {
                color: #FBBD34;
                font-weight: 500;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .orderedProductContainer {
        width: 900px;
        padding: 0;
        margin-bottom: 40px;

        .orderedProductRow {
            --bs-gutter-x: 0;
            margin-top: 30px;

            .orderedProductColumn {
                display: flex;
                justify-content: space-between;
                padding: 20px 0;
                border-bottom: 1px solid #D9D9D9;

                &:first-child {
                    border-top: 1px solid #D9D9D9;
                }

                .productDetailWrapper {
                    display: flex;

                    .imgContainer {
                        width: 130px;
                        height: auto;
                        margin-right: 20px;

                        img {
                            max-width: 100%;
                            height: auto;
                            width: 100%;
                            border-radius: 20px;
                        }
                    }

                    .productTitle {
                        font-size: 20px;
                        font-weight: 600;
                        padding-top: 20px;
                    }
                }

                .productPrice {
                    color: #EC7426;
                    font-size: 28px;
                    padding-top: 14px;
                }
            }
        }
    }

    .reviewedSection {
        margin-top: 30px;
    }
}
