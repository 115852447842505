.sellerHeader {
  .navbar {
    background-color: #1a2425;
    color: #ffffff;
    padding: 10px 0;
    border-bottom: 1px solid #f2f2f2;
    z-index: 1100;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    flex-wrap: nowrap;

    .logoSearchHeaderContainer {
      display: flex;
      justify-content: flex-start;
      min-width: 50%;
      align-items: center;

      .logoIcons {
        display: flex;
        gap: 10px;
        padding: 0;
        margin-right: 150px;

        img {
          max-height: 50px;
        }
      }

      .inputGrp {
        width: 100%;
        margin-right: 15px;

        .searchField {
          border: none;
          border-radius: 0;
        }

        .searchBtn {
          background-color: #fbbd34 !important;
          border: none;
          border-radius: 0;

          &:hover {
            box-shadow: 0 8px 25px -8px #fbbd34;
          }
        }
      }
    }

    .iconsDropdownNav {
      display: flex;
      align-items: center;

      .iconLink {
        color: #ffffff;

        &:hover {
          color: #fbbd34;
        }
      }

      .userAvatar {
        border-radius: 50%;
        width: 30px;
        height: auto;
      }
    }

    .dropdownToggleBtn {
      display: flex;
      align-items: center;
      gap: 5px;
      background-color: transparent !important;
      color: #ffffff !important;
      border: 1px solid #ffffff;
      border-radius: 10px;
      padding: 6px 14px;

      &:hover {
        box-shadow: none;
      }
    }

    .dropdown-item {
      &:active {
        background-color: #fabc42;
      }
    }
  }
}

.searchContainer {
  position: relative;
  width: 100%;
}

.resultsList {
  position: absolute; 
  top: 100%; // Positions it right below the search bar
  left: 0;
  width: 100%;
  max-height: 300px; // Prevents it from becoming too long
  overflow-y: auto; // Allows scrolling if results exceed the height
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 8px;
  list-style: none;
}

.resultItem {
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  transition: background 0.2s ease-in-out;

  &:last-child {
    border-bottom: none; // Removes border from last item
  }

  &:hover {
    background: #f8f9fa; // Light gray on hover
  }

  h4 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
  }

  mark {
    background: yellow; // Highlights matched text
    font-weight: bold;
  }
  :not(mark) {
    color: gray;
  }
}

.noResults {
  padding: 10px;
  color: #888;
  text-align: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
    margin-top: 70px;
}


// Responsive Behavior - Media Queries
@media (max-width: 991px) {
  .sellerHeader {
    .navbar {
      .logoSearchHeaderContainer {
        .logoIcons {
          margin-right: 50px;
        }
      }

      .iconsDropdownNav {
        .iconLink {
          padding: 0.3rem 0.6rem;
        }
      }

      .dropdownToggleBtn {
        padding: 6px 10px;
      }
    }
  }
}

@media (max-width: 768px) {
  .maelano-text {
    display: none;
  }

  .sellerHeader {
    .navbar {
      .logoSearchHeaderContainer {
        min-width: auto;

        .inputGrp {
          display: none;
        }

        .logoIcons {
          margin-right: 0;
        }
      }
    }
  }
}
