.mainContainer {
    background-color: #FFFFFF;
    font-family: "Inter";
    padding: 30px;

    .mainHeading {
        font-weight: 700;
        margin-bottom: 20px;
    }

    .sectionHeading {
        font-weight: 700;
        margin-bottom: 10px;
    }

    .statsCardsRow {
        --bs-gutter-x: 0;
        margin-bottom: 24px;
        gap: 24px;
    }

    .balanceContainer {
        background-color: #F5F5F5;
        border-radius: 20px;
        padding: 20px;

        .balanceValue {
            color: #EC7426;
            font-weight: 600;
            margin-bottom: 10px;
        }

        .balanceText {
            font-weight: 500;
            margin: 10px 0;
        }
    }
}

@media (max-width: 992px) {
    .mainContainer {
        .statsCardsRow {
            flex-direction: column;
        }
    }
}
