.filterTabs {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding: 10px 0;
    border-bottom: 1px solid #D9D9D9;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;

    .navItem {
        margin-right: 10px;

        .navLink {
            text-decoration: none;
            font-size: 18px;
            font-weight: 600;

            &:hover {
                color: #EC7426;
            }
        }

        .activeTab {
            color: #EC7426;
        }
    }
}
