.text-orange {
  color: #EC7426;
}

.flex-grow-2 {
  flex-grow: 2 !important;
}

.right-section-wrapper {
  background-color: #fff;
  padding: 1rem;
  margin-bottom: 1rem;
}

.dropdown {
  position: absolute;
  font-size: 15px;
  width: 125%;
  background-color: #fff;

  border: 1px solid #D9D9D9;
  border-radius: 5px;
  padding: .3rem;
}
