.mainContainer {
    font-family: "Inter";
    background-color: #f2f2f2;
    min-height: 100vh;
    padding: 20px;

    .mainHeading {
        font-weight: 600;
        margin-bottom: 20px;
        text-align: center;
    }

    p {
        margin: 0;
        line-height: unset;
    }

    .shippingCarrierWrapper {
        --bs-gutter-x: 0;
        background-color: #FFFFFF;
        box-shadow: 0px 0px 17.9px 0px #00000017;
        padding: 30px;

        .graphContainer {
            display: flex;
            flex-direction: column;
            gap: 20px;
            background-color: #FFFFFF;
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
            padding: 20px;
        }
    }
}
