.header {
  background-color: #fcffda;
  color: #fbbd34;
  padding: 1rem;
  font-size: 1.25rem;
}

.text-sm {
  font-size: 0.75rem;
}

.button {
  all: unset;
  border: 1px solid black;
  border-radius: 1.375rem;
  padding: .3rem .6rem;
}

.input[type="radio"] {
  appearance: none;
  width: 1rem;
  height: 1rem;
  border: 1px solid #D9D9D9;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  position: relative;

  &:checked::before {
    content: "";
    display: block;
    width: 80%;
    height: 80%;
    background-color: #FBBD34;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba(251, 189, 52, 0.5);
  }
}

.wrapper {
  display: grid;
  gap: 1rem;
  grid-template-areas:
    "notifications communication-channels manage"
    "notifications communication-channels communication-channels-updates" 
    "notifications subscription communication-channels-updates"
    "notifications subscription submit"
    "notifications subscription submit";

  margin-top: 1rem;
}

.notifications {
  grid-area: notifications;
}

.communication-channels {
  grid-area: communication-channels;
}

.manage {
  grid-area: manage;
}

.communication-channels-updates {
  grid-area: communication-channels-updates;
}

.subscription {
  grid-area: subscription;
}

.submit {
  grid-area: submit;
}

@media (max-width: 1024px) {
  .wrapper {
    grid-template-areas:
      "notifications"
      "communication-channels"
      "manage"
      "communication-channels-updates"
      "subscription"
      "submit";
  }
}