.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

@media (max-width: 1440px) {
  .wrapper {
    grid-template-columns: 1fr 1fr;
  }  
}
@media (max-width: 1024px) {
  .wrapper {
    grid-template-columns: 1fr;
  }  
}
