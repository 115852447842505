.heading {
  font-weight: 600;
  padding: 10px 20px;
  background-color: #FBBD34;
  font-size: 1.25rem;
  color: #ffffff;
}

.text-orange {
  color: #EC7426;
}
