.btn {
  font-size: 1rem;
  font-weight: 500;
  padding: 10px 20px;
  white-space: nowrap;

  &-blue {
    background: #41b8e4 !important;
    border: 1px solid #41b8e4 !important;
    color: #ffffff !important;

    &:hover {
      box-shadow: 0 8px 25px -8px #41b8e4 !important;
    }

    &:focus {
      background: #36a1cb !important;
    }
  }

  &-black {
    background: #1a2425 !important;
    border: 1px solid #1a2425 !important;
    color: #ffffff !important;

    &:hover {
      box-shadow: 0 8px 25px -8px #1a2425 !important;
    }

    &:focus {
      background: #151d1e !important;
    }
  }

  &-yellow {
    background: #fbbd34 !important;
    border: 1px solid #fbbd34 !important;
    color: #ffffff !important;

    &:hover {
      box-shadow: 0 8px 25px -8px #fbbd34 !important;
    }

    &:focus {
      background: #e0a82d !important;
    }
  }

  &-outline {
    background: transparent !important;
    border: 1px solid #1a2425 !important;
    color: #1a2425 !important;

    &:hover {
      box-shadow: 0 8px 25px -8px #1a2425 !important;
    }

    &:focus {
      background: rgba(26, 36, 37, 0.1) !important;
    }
  }
}
