.filterBarContainer {
    .tabRow {
        --bs-gutter-x: 0;
        flex-wrap: nowrap;
        overflow-x: auto;

        &-default,
        &-yellowwhite {
            text-align: center;
        }

        &-white {
            text-align: center;
            white-space: nowrap;
            overflow-x: auto;
            scroll-snap-type: x mandatory;
        }

        .tab {
            transition: background-color .3s ease-in, color .3s ease-in;
            cursor: pointer;
            align-content: center;

            &-default {
                padding: 15px;
                background-color: #9B9B9B;
                color: #FFFFFF;
                font-weight: 600;
                font-size: 18px;

                &.activeTab {
                    background-color: #FBBD34;
                }
            }

            &-yellowwhite {
                padding: 15px;
                background-color: #FFFFFF;
                color: #5D5D5D;
                font-weight: 600;
                font-size: 18px;

                &.activeTab {
                    background-color: #FBBD34;
                    color: #FFFFFF;
                }
            }

            &-white {
                padding: 15px;
                background-color: #fff;
                color: #FFFFFF;
                font-weight: 600;
                font-size: 18px;
                color: #000;
                scroll-snap-align: start;

                &.activeTab {
                    background-color: #FBBD34;
                    color: #fff;
                }
            }
        }
    }
}

@media (max-width: 769px) {
    .filterBarContainer {
        .tabRow {
            white-space: nowrap;

            .tab {
                width: 100%;
                text-align: center;
                padding: 12px;
                font-weight: 500;
                font-size: 16px;
            }
        }
    }
}
