.tabsContainer {
  display: flex;
  justify-content: center;

  .tabsWrapper {
    width: 25%;
    white-space: nowrap;
    position: relative;
    justify-content: center;
    background-color: #f0f0f0;
    margin: 0.625rem;
    border-radius: 6.25rem;
    margin-bottom: 1rem;
    display: flex;
    gap: 0.5rem;

    @media (max-width: 576px) {
      width: 100%;
    }
  }

  .tab {
    flex: 1;
    padding: 0.625rem 1.25rem;
    border-radius: 6.25rem;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: relative;
    z-index: 1;

    &.activeTab {
      background-color: black !important;
      color: white !important;
    }

    &:not(.activeTab) {
      background-color: #f0f0f0;
      color: #000;
    }
  }

  .indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 0;
    background-color: black;
    border-radius: 6.25rem;
    transition:
      transform 0.3s ease,
      width 0.3s ease;
  }
}
