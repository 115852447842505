.card {
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: #f2f2f2;
}

.previewImage {
  height: 214px;
  max-height: 214px;
  width: 100%;
  border-radius: 0.5rem;
}

.previewBtn {
  all: unset;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid #6c757d;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-align: center;
  display: block;
  width: fit-content;
  margin-top: 0.5rem;
  position: absolute;
  left: 20px;
  bottom: 20px;
  background-color: white;

  &:hover {
    background-color: #e9ecef;
  }
}

.price {
  font-weight: 500;
}

.free {
  color: #41B8E4;
}

.paid {
  color: #EC7426;
}
