.advertising-options {
  display: grid;
  gap: .5rem;
  grid-template-columns: 1fr 1fr;
}

@media (max-width: 1024px) {
  .advertising-options {
    grid-template-columns: 1fr;
  }
}