.checkoutMainContainer {
    font-family: "Inter";
    padding: 0 40px 30px;

    .bannerContainer {
        position: relative;

        img {
            max-width: 100%;
            height: auto;
            width: 100%;
            border-radius: 20px;
        }

        .checkoutHeading {
            position: absolute;
            bottom: 40px;
            left: 50px;
            font-size: 40px;
            font-weight: 700;
            color: #FFFFFF;
        }
    }

    .checkoutMainRow {
        margin-top: 40px;
        row-gap: 20px;

        .filterContainer {
            padding-right: 20px;

            .filterTabs {
                overflow-x: auto;
                white-space: nowrap;
                border-bottom: 1px solid #1A2425;
                margin-bottom: 30px;
                font-size: 26px;
                font-weight: 500;
                padding: 0px 30px;

                .fiterTabsNavBar {
                    display: flex;
                    flex-wrap: nowrap;
                    justify-content: space-between;

                    .navItem {
                        .navLink {
                            text-decoration: none;

                            &:hover {
                                color: #EC7426;
                            }
                        }

                        .activeTab {
                            color: #EC7426;
                            border-bottom: 3px solid #EC7426;
                        }
                    }
                }
            }
        }

        .cartSummaryContainer {
            padding-left: 20px;

            .cartSummaryHeader {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 9px 0 10px;
                border-bottom: 2px solid #D9D9D9;
                margin-bottom: 20px;

                .sectionHeading {
                    font-size: 26px;
                    font-weight: 600;
                    margin: 0;
                }

                .btnConatiner {
                    .editBtn {
                        font-size: 16px;
                        cursor: pointer;
                        text-decoration: none;
                        color: #D9D9D9;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}

// Media Queries for responsive behavior
@media (max-width: 1199px) {
    .checkoutMainContainer {
        .bannerContainer {
            .checkoutHeading {
                bottom: 30px;
                left: 40px;
                font-size: 30px;
            }
        }
        .checkoutMainRow {
            .filterContainer {
                .filterTabs {
                    font-size: 22px;
                }
            }
            .cartSummaryContainer {
                .cartSummaryHeader {
                    .sectionHeading {
                        font-size: 22px;
                    }
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .checkoutMainContainer {
        .checkoutMainRow {
            .filterContainer {
                padding: 0;
            }
            .cartSummaryContainer {
                padding: 0;
            }
        }
    }
}

@media (max-width: 767px) {
    .checkoutMainContainer {
        padding: 0px 20px 30px;
        .bannerContainer {
            img {
                border-radius: 10px;
            }
            .checkoutHeading {
                bottom: 20px;
                left: 30px;
                font-size: 24px;
            }
        }
        .checkoutMainRow {
            .filterContainer {
                .filterTabs {
                    font-size: 18px;
                    padding: 0;
                }
            }
        }
    }
}

@media (max-width: 540px) {
    .checkoutMainContainer {
        .bannerContainer {
            .checkoutHeading {
                bottom: auto;
                top: 50%;
                left: 50%;
                font-size: 28px;
                transform: translate(-50%, -50%);
            }
        }
    }
}
