.headOrange {
  background-color: #ec7426;
  color: #fff;
  font-weight: 500;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  padding: 0.5rem 0.3rem;
}

.headGray {
  background-color: #d9d9d9;
  color: #000;
  font-weight: 600;
  font-size: 15px;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  padding: 0.5rem 0.3rem;
}

.row {
  background-color: #fff;
  padding-inline: 0.3rem;

  .column {
    padding-block: 0.3rem;
  }

  .column:not(:last-child) {
    border-right: 1px solid #d9d9d9;
  }
}

.recentTransactionsDataRow {
  background-color: #fff;

  padding-inline: 0.3rem;

  .recentTransactionsDataColumn {
    padding-block: 0.3rem;
  }
}

.recentTransactionsDataRow:not(:last-child) {
  border-bottom: 1px solid #d9d9d9;
}

.row:last-child {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.recentTransactionsDataRow:last-child {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
