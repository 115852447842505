.lineGraphContainer {
    position: relative;
    text-align: center;

    .percentageLabel {
        position: absolute;
        top: 20%;
        left: 40%;
        transform: translate(-50%, -50%);
        color: #41B8E4;
        font-size: 16px;
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 5px;

        .arrowIcon {
            margin-top: 4px;
        }
    }

    .glowingLine {
        filter: drop-shadow(0px 0px 8px rgba(13, 180, 255, 0.7)); // Blue glow effect
    }
}
