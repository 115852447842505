.filterBar {
    display: flex;
    align-items: center;

    .selectedCategoriesContainer {
        display: flex;
        overflow-x: hidden;
        scroll-behavior: smooth;
        padding: 10px 0;
        gap: 10px;

        .selectedCategory {
            padding: 6px 12px;
            white-space: nowrap;
            font-size: 14px;
            text-decoration: none;
            font-size: 16px;
            font-weight: 500;
        }
    }

    .filterTabs {
        overflow-x: hidden;
        white-space: nowrap;

        .fiterTabsNavBar {
            display: flex;
            flex-wrap: nowrap;

            .navItem {
                .navLink {
                    text-decoration: none;
                    font-size: 16px;
                    font-weight: 500;

                    &:hover {
                        color: #EC7426;
                    }
                }

                .activeTab {
                    color: #EC7426;
                }
            }
        }
    }

    .scrollButton {
        background: none;
        border: none;
        font-size: 20px;
        cursor: pointer;
        padding-bottom: 6px;
    }
}
