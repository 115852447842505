.content {
  background-color: #fff;
  border-top-right-radius: 0.625rem;
  border-top-left-radius: 0.625rem;
  margin-top: 1rem;
}

.btn {
  all: unset;
  padding: 0;
}