.eReceiptMainContainer {
    font-family: "Inter";
    max-width: 800px;
    margin: auto;
    padding: 0;
    margin-bottom: 60px;

    .mainHeading {
        color: #41B8E4;
        font-weight: 500;
        text-align: center;
        margin-bottom: 30px;
    }

    .sectionHeading {
        font-weight: 400;
        margin-bottom: 10px;
    }

    p {
        margin: 0;
        line-height: unset;
    }

    .eReceipt {
        background: #fff;
        padding: 60px 40px;
        filter: drop-shadow(0 4px 10px rgba(0, 0, 0, 0.1));

        .header {
            text-align: center;

            .orderId {
                font-weight: 500;
                margin: 0;
                margin-top: 6px;
            }
        }

        .orderedProducts {
            margin-top: 50px;

            .productCard {
                border: none;
                border-radius: 0;
                box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
                margin-bottom: 10px;

                .cardBody {
                    padding: 15px;

                    .productCardRow {
                        row-gap: 20px;

                        .imgContainer {
                            width: 100%;
                            height: auto;
                            padding-right: 20px;

                            .productImage {
                                max-width: 100%;
                                height: auto;
                                width: 160px;
                            }
                        }

                        .productDetails {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            gap: 6px;
                            height: 100%;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;

                            .productTitle {
                                font-weight: 400;
                                margin: 0;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }

                            .productVariation,
                            .productQuantity {
                                color: #9B9B9B;
                                font-size: 18px;
                                font-weight: 400;
                            }

                            .productPriceAndStore {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                font-size: 24px;
                                font-weight: 400;
                                gap: 5px;

                                .price {
                                    color: #EC7426;
                                    font-size: 28px;
                                    font-weight: 500;
                                }

                                .store {
                                    display: flex;
                                    align-items: center;
                                    gap: 6px;
                                }
                            }
                        }
                    }


                    .delivery {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border: 2px solid #F2F2F2;
                        border-radius: 10px;
                        font-size: 18px;
                        padding: 6px 50px 6px 20px;
                        margin-top: 10px;
                        gap: 5px;

                        .deliveryTime {
                            font-size: 16px;
                            color: #9B9B9B;
                        }
                    }
                }
            }
        }

        .total {
            margin-top: 30px;

            .totalDetails {
                background: #ffffff;
                box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
                padding: 20px 30px;
                font-size: 20px;

                .amountDiscountRow {
                    margin-bottom: 4px;

                    &:nth-child(4) {
                        margin-bottom: 10px;
                    }
                }

                .finalAmountRow {
                    margin-bottom: 4px;

                    .finalValues {
                        color: #EC7426;
                    }
                }

                .orderDate {
                    font-size: 14px;
                    color: #007bff;
                }
            }
        }
    }

    .placeOrderBtnContainer {
        display: flex;
        justify-content: center;
        padding: 0;
        margin-top: 30px;

        .orderPlaceBtn {
            background-color: #41B8E4 !important;
            border: 1px solid #41B8E4;
            font-size: 18px;
            font-weight: 500;
            padding: 10px 20px;

            &:hover {
                box-shadow: 0 8px 25px -8px #41B8E4;
            }
        }
    }
}

@media (max-width: 991px) {
    .eReceiptMainContainer {
        max-width: 540px;
        padding: 0 20px;

        .eReceipt {
            padding: 30px;

            .total {
                .totalDetails {
                    padding: 20px;

                    .amountDiscountRow,
                    .finalAmountRow {
                        margin-bottom: 15px;
                    }

                    .amountDiscountRow:nth-child(4) {
                        margin-bottom: 20px
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .eReceiptMainContainer {
        .eReceipt {
            .header {
                .orderId {
                    font-size: 20px;
                }
            }

            .orderedProducts {
                .productCard {
                    .cardBody {
                        .productCardRow {
                            .imgContainer {
                                .productImage {
                                    width: 130px;
                                }
                            }
                        }

                        .delivery {
                            flex-direction: column;
                            text-align: center;
                            font-size: 16px;
                            padding: 6px 10px;

                            .deliveryTime {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 575px) {
    .eReceiptMainContainer {
        .mainHeading {
            margin-bottom: 20px;
            font-size: 20px;
        }

        .sectionHeading {
            font-size: 18px;
        }

        .eReceipt {
            padding: 20px;

            .header {
                .orderId {
                    font-size: 18px;
                }
            }

            .orderedProducts {
                .productCard {
                    .cardBody {
                        .productCardRow {
                            .imgContainer {
                                .productImage {
                                    width: 110px;
                                }
                            }

                            .productDetails {
                                .productTitle {
                                    font-size: 18px;
                                }

                                .productVariation,
                                .productQuantity {
                                    font-size: 16px;
                                }

                                .productPriceAndStore {
                                    flex-direction: column;
                                    align-items: flex-start;
                                    font-size: 20px;

                                    .price {
                                        font-size: 24px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .total {
                .totalDetails {
                    font-size: 16px;
                }
            }
        }

        .placeOrderBtnContainer {
            margin-top: 20px;

            .orderPlaceBtn {
                font-size: 16px;
            }
        }
    }
}
