.mainContainer {
    .sectionHeading {
        font-weight: 600;
        margin-bottom: 10px;
    }

    .scheduleContainer {
        background: #ffffff;
        font-size: 15px;
        padding: 10px;
        margin-bottom: 30px;

        .scheduleRow {
            --bs-gutter-x: 0;
            padding: 20px;
            border: 1px solid #d9d9d9;

            .formSection {
                label {
                    font-size: 15px;
                    font-weight: 400;
                    color: #1A2425;
                    margin: 0;
                }

                .formGroupRow {
                    --bs-gutter-x: 0;
                    display: flex;
                    align-items: center;
                    row-gap: 4px;

                    input,
                    select {
                        border: 1px solid #1A2425;
                        border-radius: 5px;

                        &::placeholder {
                            color: #D9D9D9;
                        }
                    }
                }

                .shipmentDetailFields {
                    padding: 10px 30px 0;
                }

            }

            .table {
                margin-top: 20px;

                .actionBtns {
                    button {
                        background: none;
                        color: #1A2425;
                        text-decoration: none;
                    }

                    .rescheduleButton {
                        border: 1px solid #1A2425;
                        border-radius: 8px;
                        padding: 6px 16px;

                        &:hover {
                            background: #f8f9fa;
                        }
                    }

                    .cancelButton {
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}
